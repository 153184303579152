<!--------------------Celebrity--------------------->
<div class="celebrity-sec">
	<div class="container">
	  <div class="row heading">
	  <div class="col-md-12">
	   <h2>Influencer</h2>
	   </div>
	   </div>
	  <div class="row">	
	<owl-carousel-o [options]="customOptionscel">
		<ng-template carouselSlide *ngFor="let celebrity of celebrityData">
			<div class="item">					
					<div class="divinner">
						<img src="{{imageSrc}}{{celebrity.profile_image}}"  alt="{{celebrity.profile_image}}">
						<div class="textinner">
						<div class="name">{{celebrity.name}}</div>
						<div class="villaname">{{celebrity.villa}}</div>
					<a  class="more-details" routerLink="property/location/villas-in-{{celebrity.location}}">{{celebrity.location}}</a>
						</div>
				</div>			  
			</div>
 
		</ng-template>			   
	</owl-carousel-o>			            				 				 
	  </div>
	</div>
  </div>
   <!--------------------Celebrity--------------------->
   <!--------------------Testimonials--------------------->
  