<section class="page-wrapper">
		<section class="page-banner">
		<h1>Original</h1>
</section>
</section>
<section class="gold-page">
	<div class="container">
		<div class="section-intro">
			<div class="row tpd">
			 <div class="col-md-12">
				<h2 class="maint" >INTRODUCING OUR <span style="color: #e7b315;">Original CATEGORY VILLAS</span></h2>
				<img src="assets/img/heding-icon.png"  alt="">
			 </div>
			</div>	
			<div class="row">
				<div class="col-md-6">
					<div class="gold-image"><img src="assets/img/img-about.jpg" alt=""></div>
				</div>
				<div class="col-md-6">
					<div class="journey-villas">
					<h3>What is a Original category property?</h3>
					<p>EKO Original is our cherry-picked compilation of some of the best villas and homestays across India. A step towards expanding our luxury footprint while giving you elite treatment & services, is what we strive to offer!</p>
					<ul>
						<li><img src="assets/img/Premium .png" class="" alt="..."> Tasteful Interiors</li>
						<li><img src="assets/img/elegant.png" class="" alt="..."> Aesthetic Furnishing </li>
						<li><img src="assets/img/furnishing.png" class="" alt="..."> Chic Outdoor Spaces</li>
						
					</ul>
					<p><b>It's right what they say, 'Every EKOSTAY Home Has A Personality'.</b></p>
					</div>
				</div>
			</div>	
		</div>
	</div>
</section>	 
	 
 <section class="about-page page-section">
        <div class="container">
		<div class="row">
	   <div class="col-md-4">
	   
	              <div class="filter-wrapper">
				<h2>Filters</h2> 
				 <div class="row"> 
					 <div class="col-md-12"> 
						 <div class="form-group"><input type="text" placeholder="Locations" class="form-control" [(ngModel)]="propertyName" placeholder="Property Name"></div> 
					 </div> 
					 <div class="col-md-12"> 
						 <div class="form-group"> 
							 <input type="text" class="form-control" placeholder="Location" [(ngModel)]="location" list="staticUserIds" /> 
						 <datalist  id="staticUserIds" > 

								  <option *ngFor="let location of locationDatas">{{location.name}}</option>  
							 </datalist> 
						 </div> 
					 </div> 
					 <div class="col-md-12"> 
						 <div class="form-group">  
							<select class="form-control" [(ngModel)]="guest"> 
							 <option value="">Guest</option> 
								 <option>1</option>  
								 <option>2</option>  
								 <option>3</option> 
								 <option>4</option>  
								 <option>5</option>  
								 <option>6</option> 
								 <option>7</option>  
								 <option>8</option>  
								 <option>9</option>  
								 <option>10</option>  
								 <option>11</option> 
								 <option>12</option> 
								 <option>13</option> 
								 <option>14</option> 
								 <option>15</option> 
								 <option>16</option> 
								 <option>17</option> 
								 <option>18</option> 
								 <option>19</option> 
								 <option>20</option>
				  				 <option>21</option>
								 <option>22</option>
								 <option>23</option>
								 <option>24</option>
								 <option>25</option>
								 <option>26</option>
								 <option>27</option>
								 <option>28</option>
								 <option>29</option>
								 <option>30</option>
							 </select>  
						 </div>  
				  </div>  
				 <div class="col-md-12">  
					 <div class="form-group"><input type="text" placeholder="Check In" onfocus="(this.type='date')" [(ngModel)]="checkin" onblur="(this.type='text')" class="form-control" ></div> 

					  
				 </div> 
					  <div class="col-md-12"> 
						  <div class="form-group"><input type="text" [(ngModel)]="checkout" class="form-control" onblur="(this.type='text')" onfocus="(this.type='date')" placeholder="Check Out"></div>  
					  </div> 
					
				 </div>  
				 <div class="row">
				
					<div class="col-md-12">
						<button class="btn btn-default" (click)="filterProperties()" >Search</button>
					</div>
				</div>
			</div>  
	   </div>	   	
			<div class="col-md-8">
	   			<div class="Properties property-list goldDetail">
				<div class="row">
					<div class="col-md-12 bsp_padding-0" *ngFor="let property of aboutData">
					   <div class="bsp_bbb_item"> 
					   <div class="row">
					   <div class="col-md-6">
						   <div class="image-box">
								<owl-carousel-o [options]="customOptions" *ngIf="property.property_media !=null">
									 <ng-container *ngFor="let pmedia of property.property_media | SortBy:'sort'">
									      <ng-template carouselSlide [id]="pmedia.id">
		      	    	   				   		<a routerLink="property/location/villas-in-{{property.locname}}/{{property.permalink}}" class="bsp_a">
											       <img src="{{imagePropertySrc}}/{{pmedia.media}}"  alt="">
											   	</a>
									      </ng-template>
									</ng-container>
							  	</owl-carousel-o>
    	   				   		<a routerLink="property/location/villas-in-{{property.locname}}/{{property.permalink}}" class="bsp_a" *ngIf="property.property_media.length ==0">
									<img src="{{imageSrc}}/{{property.image}}"  alt="" >
								</a>
						   </div>
						</div>
				   		<a routerLink="property/location/villas-in-{{property.locname}}/{{property.permalink}}" class="bsp_a">
						<div class="col-md-6">
						   <div class="text-left">
							   <p class="bsp_card-text"><a routerLink="property/location/villas-in-{{property.locname}}/{{property.permalink}}" > {{property.property_name}}</a></p>
							   <p class="loct"> 
								<a href="#" class="more-details"><i class="fa fa-map-marker" aria-hidden="true"></i> 
								{{property.locname}}</a></p>
							  <h5 class="bsp_card-title"><span>₹ {{property.inputname_monday}}</span> / <span> Night </span></h5>
							   
								<ul class="roominn">
									<li><i aria-hidden="true" class="fa fa-bed"></i> {{property.bed}} BHK</li>
									<li><i class="fa fa-bath" aria-hidden="true"></i>  {{property.bathrooms}} Bathrooms</li>
									<li><i aria-hidden="true" class="fa fa-users"></i> Max {{property.member}} Guest</li>
								</ul>
						   </div>
						   </div>
						  </a>
						   </div>
					   </div>
				   </div>
			   
				</div>
				
			</div>
	   </div>
			
			
			
			
        </div>
    </div>
  
    </section>