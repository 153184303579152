<mat-icon class="dialog_close" (click)="close()">close</mat-icon>
<div class="dialog_wrapper" *ngIf="this.number === 1">
<div class="avtar">
    <img src="../../../assets/img/Husain.jpeg">
</div>

<div class="details">
   <div class="name">Husain Khatumdi</div> 
   <div class="position">Managing Director & Co-Founder</div>
   <p class="describe">
    An Opportunist and an Avid Traveller. A graduate from Cass Business School, London with an entrepreneurship diploma
    from Queensland University of Technology, Australia. Passionate about designing, number crunching, devising strategy
    and implementation. Husain’s expertise lies in identifying, assessing and achieving chief KPIs across all verticals in
    his organisation.
   </p>
</div>
</div>
<div class="dialog_wrapper" *ngIf="this.number === 2">
    <div class="avtar">
        <img src="../../../assets/img/Varun.jpg">
    </div>
    
    <div class="details">
       <div class="name">Varun Arora</div> 
       <div class="position">Chief Executive Officer & Co-Founder</div>
       <p class="describe">
        A multipotentialite and an avid traveller, Varun Arora thrives on exploring new avenues that can help elevate his organisation to the next level. A graduate in Mass Media from Mumbai University, Varun started his career in the marketing field, where he learnt the skills of strategizing and implementing online & offline creative marketing strategies.
                    Mantra - Life is happening, all you gotta do is show up!
       </p>
    </div>
    </div>
    <div class="dialog_wrapper" *ngIf="this.number === 3">
        <div class="avtar">
            <img src="../../../assets/img/Sohail.jpg">
        </div>
        
        <div class="details">
           <div class="name">Sohail Mirchandani</div> 
           <div class="position">Chief Operating Officer & Co-Founder</div>
           <p class="describe">
            A hustler and go-getter with a zest for Excellence!
                    Having graduated with an Accounting & Finance Degree From HR College, Mumbai and a diploma in business administration coupled with the professional experience, he defines and manages the vision of the brand making sure the company sticks to its core of having a great backend strategy and team, mastering the art of budgeting & analytical competence and acing interpersonal relations to ensure every client at Ekostay gets a personal touch and has the best homestay experience possible.
           </p>
        </div>
        </div>
        <div class="dialog_wrapper" *ngIf="this.number === 4">
            <div class="avtar">
                <img src="../../../assets/img/Zeeshan.jpg">
            </div>
            
            <div class="details">
               <div class="name">Zishan Khan</div> 
               <div class="position">Chief Acquisition Officer & Co-Founder</div>
               <p class="describe">
                A Car & Lifestyle Enthusiast by choice!
                     A graduate in pharmacy with an immense passion of redesigning and transforming dishevelled properties. Zishan draws a keen interest in exploring new regions, scouting and acquiring vacation homes ,staying true to the brand’s vision.
               </p>
            </div>
            </div>
