import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { CanonicalService } from "./services/canonical.service";
import { UrlRedirectionService } from "./services/url-redirection.service";
import { LoaderService } from "./components/loader/loader.service";
import { map, shareReplay } from "rxjs/operators";
import { Observable } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { aboutservice } from "./services/aboutservice";
import { isPlatformBrowser } from "@angular/common";
import { BrowserRequiredFiles } from "./constants/browser-required-files";
declare let gtag: Function;
declare let fbq: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit, AfterViewInit {
  showFooter = true;
  isDarkTheme: boolean = false;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  title = "angpro";
  dataUrl: any;
  isBrowser: boolean;
  constructor(
    public router: Router,
    private urlRedirect: UrlRedirectionService,
    private breakpointObserver: BreakpointObserver,
    public loaderService: LoaderService,
    private aboutService: aboutservice,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser) {
      this.dataUrl = localStorage.getItem("dataUrl");
      this.dataUrl = this.dataUrl?.replace("property", "");
      this.aboutService.footerBehaviour.subscribe((res: any) => {
        this.showFooter = res;
      });
      this.router.events.subscribe((event: any) => {
        if (event && event instanceof NavigationStart && event["url"]) {
          const url = event["url"];
          this.urlRedirect?.redirectUrl(url, router);
        }
        if (event instanceof NavigationEnd) {
          if (this.isBrowser) {
            gtag("config", "UA-118643405-3", {
              page_path: event.urlAfterRedirects,
            });
            fbq("track", "PageView");
          }
        }
        if (router.url.indexOf("featured-property") > -1) {
          this.showFooter = false;
        }
        if (router.url.endsWith(this.dataUrl)) {
          this.showFooter = false;
        }
      });
    }
  }
  ngAfterViewInit(): void {
    if (this.isBrowser) {
      BrowserRequiredFiles.forEach((metaData) => {
        const element = document.createElement(metaData.tag);
        if (metaData.content) element.innerHTML = metaData.content;
        // if (metaData.src) (element as any).src = metaData.src;
        if(metaData.tag === "script"){
          (element as HTMLScriptElement).defer = true;
          (element as HTMLScriptElement).async = true;
        }
        document.body.appendChild(element);
      });
    }
  }
  ngOnInit(): void {
    if (this.isBrowser)
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        if (this.isBrowser) window.scrollTo(0, 0);
      });
  }

  currentPath: any;
  check(url: any) {
    const currentRoute = this.router.routerState.snapshot.url;
    this.currentPath = currentRoute;
  }
}
