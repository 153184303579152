import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgModel, Validators,UntypedFormControl } from '@angular/forms';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { Routes, ActivatedRoute, Router, Params } from '@angular/router';
import { Title,Meta, MetaDefinition } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { bookingservice } from '../../services/bookingservice';
import * as AOS  from 'aos';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactForm: UntypedFormGroup;
  public showError: boolean = false;
  public showStatus: boolean = false;
  iflogin: boolean = false;
  ifnotlogin: boolean = true;
  isBrowser:boolean;

  constructor(private metaService: Meta,private title: Title,private  router: Router,private fb: UntypedFormBuilder,private bookingservice: bookingservice,  @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.title.setTitle('Ekostay - Contact');
    this.metaService.addTag({ name: 'description', content: 'Ekostay - Contact' });

    this.contactForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      phone: new UntypedFormControl(),
      email: new UntypedFormControl('', Validators.required),
      message : new UntypedFormControl(),     
    });
   }

  ngOnInit(): void {
if(this.isBrowser){

  AOS.init();
  window.addEventListener('load', AOS.refresh)
}

  }

  sendContact(){
    var me  =  this;    
    if(this.contactForm.valid) {
      me.showError = false; 
      this.bookingservice.contactForm(this.contactForm.value).subscribe((data) => {
        me.showStatus = true;
      });
    }else{
      me.showError = true;
    }
  }

}


