<section class="page-wrapper">
	<section class="page-banner">
			<h1>Press And Media </h1>
	</section>
</section>
<section class="blog-header">
	<div class="container">
		<h2>Welcome to the <span class="highlight">EKOSTAY</span> Media Team.</h2>
		<p>We at EKOSTAY work with media and journalists to share useful insights of the alternative accommodation space in India. If you are from the media or press, kindly reach out to us via phone or email on <a href="mailto:marketing@ekostay.com">marketing@ekostay.com</a> for compelling and insightful stories.</p>
	</div>
</section>

<section class="blog-page page-section">
	<div class="container">
		<div class="row">
			<div class="col-md-4" *ngFor="let blog of blogData">
				<div class="blog-item">
                        <div class="blog-img">
                            <a href="{{blog.link}}" target="_blank"><img src="{{imageSrc}}//{{blog.image}}" alt=""></a>
                        </div>
                        <div class="blog-brief">
                            <div class="blog-meta">
                                <ul>
                                    <li class="blog-author">
                                        <a routerLink="#"><i class="fa fa-user"></i>by: {{blog.author}} </a>
                                    </li>
                                </ul>
                            </div>
                            <h3 class="blog-title"><a href="{{blog.link}}" target="_blank">{{blog.name}} </a></h3>
                            <div class="blog-meta-btn">
                                <div class="blog-meta">
                                  
                                </div>
                                <div class="blog-btn">
                                    <a href="{{blog.link}}" target="_blank">Read more</a>
                                </div>
                            </div>
                        </div>
                    </div>
			</div>
			
			
		</div>
	</div>
</section>