import { Injectable } from '@angular/core';

import {Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { Subject } from 'rxjs';

const cacheBuster$ = new Subject<void>();

@Injectable()
export class propertyservice {
  isSessionVaild = true;
  constructor(private http: HttpClient, public router: Router) {  }

 

  getProperties() {
    return this.http.get('https://'+environment.domain+'/public/api/propertiesweb',{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }

 



  getPropertys() {
    return this.http.get('https://'+environment.domain+'/public/api/ordered-property',{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }

  getDynamicCircle() {
    return this.http.get('https://'+environment.domain+'/public/api/dynamic-circle-sorting',{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }

  getDynamicCircleProperty(category:any){
    return this.http.get('https://'+environment.domain+'/public/api/get-property-dynamic-cycle?subcat='+category,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }
  
  getPropertysGold() {
    return this.http.get('https://'+environment.domain+'/public/api/propertiesgold',{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }




 


  getPropertysGoa() {
    return this.http.get('https://'+environment.domain+'/public/api/propertiesgoa',{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }

 

  getPropertysNorthindia() {
    return this.http.get('https://'+environment.domain+'/public/api/propertiesnorthindia',{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }
}
