import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { aboutservice } from '../../services/aboutservice';
import { propertyservice } from '../../services/propertyservice';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { SiteDataHandlerService } from 'src/app/services/site-data-handler.service';
import { Observable, Subject } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-propertylist',
  templateUrl: './propertylist.component.html',
  styleUrls: ['./propertylist.component.css']
})
export class PropertylistComponent implements OnInit, OnDestroy {


	contentLoaded = false;  
	destroy$: Subject<any> = new Subject();
	@Input() min: any;
  tomorrow = new Date();

	villa: any;
  slides = 'https://husainkhatumdi.com/admin/public/';  
  locationImage = 'https://husainkhatumdi.com/admin/public/uploads/location/';
  locationData: any = [];
  propertiesData: any = [];
  propertyDatas: any = [];
  propertyDataLux: any = [];
  aboutDataNewly: any = [];
  autocomplete: Observable<Location[]>;
  loctionImage = [];
  property: any;
  propertiesDataApires: any[];
  newly_villas = '';
  selectedProperty: string = '';
  location = '';
  checkin: any;
  checkout: any;
  check = 'Add Date';
  location_property: any = [];
  guest = '';
  checkValue: any;
  party_friendly = '';
  typepip: any;
  imageSrc = 'https://'+environment.domain+'/public/uploads/images/';
  propertyData: any = [];
  propertyDataGold: any = [];
  propertyNewlyVillas: any = [];
  propertyDataGoa: any = [];
  propertyDataNorth: any = [];
  loadnorth: boolean = true;
  loadgoa: boolean = true;
  aboutData: any = [];

  intervalId: number | null = null;

  constructor(public aboutservice: aboutservice,public propertyservice: propertyservice,private activatedRoute: ActivatedRoute,public propertyservicegold: propertyservice,private router: Router,private siteService: SiteDataHandlerService) {

    this.tomorrow.setDate(this.tomorrow.getDate());

   	}

	
	
  	customOptions: OwlOptions = {
		loop: true,
		mouseDrag: false,
		touchDrag: true,
		pullDrag: false,		
		dots: false,
		navSpeed: 700,
		navText: ['&#8249', '&#8250'],
		responsive: {
			
			0:{
				items:1,
			},
			600:{
				items:3
			},
			1000:{
				items:3
			}
		},
		nav: true
	}

	getDetails() {
		this.location = this.location_property;
		if (this.checkin != null && this.checkin != '')
		  this.siteService.updateCheckIn(this.checkin);
		if (this.checkout != null && this.checkin != '')
		  this.siteService.updateCheckOut(this.checkout);
		if (this.villa == null) {
		  this.router.navigate(['/property/location/' + this.location], {
			queryParams: {
			  checkin: this.checkin,
			  checkout: this.checkout,
			  guest: this.guest,
			  property: this.selectedProperty,
			},
		  });
		  this.router.navigate(
			['/property/' + this.typepip + '/' + this.location],
			{
			  queryParams: {				
				guest: this.guest,
				checkin: this.checkin,
				checkout: this.checkout				
			  },
			}
		  );
		} else {
		  this.router.navigate(['/property/' + this.typepip + '/' + this.location + this.villa],
		  {
			queryParams: {				
			  guest: this.guest,
			  checkin: this.checkin,
			  checkout: this.checkout				
			},
		  });;
		}
	  }

  	ngOnInit(): void {	

    this.propertyservice.getPropertys().subscribe((response:any) =>
      {       
       this.propertyData = response['data']['properties'];

      });

  	}

	  ngOnDestroy() {
		if (this.intervalId) {
		  clearInterval(this.intervalId);
		}
	  }

	  loadnorthindia() {
		this.propertyservice.getPropertysNorthindia().subscribe((response:any) =>
		{       
		this.propertyDataNorth = response['data']['properties'];

		this.loadnorth = false;

		});
	  }

	  loadGoa() {
		this.propertyservice.getPropertysGoa().subscribe((response:any) =>
		{       
		this.propertyDataGoa = response['data']['properties'];

		this.loadgoa = false;
		
		});
	  }

}
