import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from "@angular/forms";
import { loginservice } from "../../services/loginservice";
import { AuthService } from "../../services/auth.service";
import {
  Event,
  ActivatedRoute,
  Router,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from "@angular/router";
import { fromEvent, interval, Observable, Subscription } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  bookForm: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  thirdFormGroup: UntypedFormGroup;
  accountExit: any = [];
  if1sthide: boolean = false;
  availform: boolean = false;
  loginhide: boolean = true;
  showErrorModal: any;
  unsubscriber: Observable<any>;
  isEditable = false;
  checkin: any;
  checkout: any;
  guest: any = [];
  property_id_data: any = [];
  price: any = [];
  mainimage: any = [];
  property_name: any = [];
  locations: any = [];
  minnight: any = [];
  hrefs: any = [];
  if2ndhide: boolean = true;
  showError: boolean = false;
  showSuccess: boolean = false;
  errorMSG: any = [];
  phone: any = [];
  titleMSG: any = "Welcome to Ekostay";
  Status: any = [];
  Details: any = [];
  returnUrl: string;
  disableTextbox = false;
  iflogin: boolean = false;
  maxOTP: boolean = false;
  otplimit: boolean = false;
  ifnotlogin: boolean = true;
  resendOTPAttempts: number = 0;
  maxResendOTPAttempts: number = 3;
  private history: string[] = [];
  isBrowser: boolean;
  maxDate: any;

  timer: number = 60; // Initial timer value in seconds
  countdown: string = "01:00"; // Initial countdown display
  timerSubscription: Subscription;

  constructor(
    private loginservice: loginservice,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private SpinnerService: NgxSpinnerService,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.maxDate = new Date();

    this.isBrowser = isPlatformBrowser(platformId);

    this.bookForm = new UntypedFormGroup({
      phone: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
      ]),
    });

    this.secondFormGroup = new UntypedFormGroup({
      otp: new UntypedFormControl("", Validators.required),
    });

    this.thirdFormGroup = new UntypedFormGroup({
      fname: new UntypedFormControl("", Validators.required),
      dateofbirth: new UntypedFormControl("", Validators.required),
      phone: new UntypedFormControl("", Validators.required),
      email: new UntypedFormControl("", Validators.required),
    });

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (this.isBrowser) {
          if (localStorage.getItem("token")) {
            this.ifnotlogin = false;
            this.iflogin = true;
          }
        }
      }

      if (event instanceof NavigationEnd) {
        if (this.isBrowser) {
          this.history.push(event.urlAfterRedirects);
        }
      }

      if (event instanceof NavigationError) {
      }
    });
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  showPhoneError: boolean = false;
  register() {
    this.SpinnerService.hide();
    this.thirdFormGroup.controls.phone.setValue(
      "91" + this.bookForm.value.phone
    );
    if (this.thirdFormGroup.valid) {
      const dateOfBirth = new Date(this.thirdFormGroup.value.dateofbirth);
      const formattedDateOfBirth = `${dateOfBirth.getFullYear()}-${(
        dateOfBirth.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${dateOfBirth
        .getDate()
        .toString()
        .padStart(2, "0")}`;
      this.thirdFormGroup.value.dateofbirth = formattedDateOfBirth;
      this.loginservice.createlogin(this.thirdFormGroup.value).subscribe(
        (data: any) => {
          if (this.isBrowser) {
            localStorage.setItem("isLoggedIn", "true");

            localStorage.setItem("token", this.bookForm.value.phone);
          }
          var me = this;
          this.loginhide = true;

          this.SpinnerService.show();
          const checkin = this.checkin;

          if (this.checkin == null || this.checkout == null) {
            this._router.navigate(["/account"]);
          } else if (this.checkin != null || this.checkout != null) {
            let href = atob(this.activatedRoute.snapshot.queryParams.href);
            var state = {
              url: btoa(href),
              property_id: btoa(this.property_id_data),
              checkin: btoa(this.checkin),
              checkout: btoa(this.checkout),
              guest: btoa(this.guest),
              property_name: btoa(this.property_name),
              price: btoa(this.price),
              minnights: btoa(this.minnight),
            };
            this._router.navigate([href + "/booking"], { queryParams: state });
          } else {
            this._router.navigate(["/account"]);
          }
        },
        (error: any) => {
          this.showPhoneError = true;
        }
      );
    }
  }

  login() {
    this.SpinnerService.hide();
    if (this.isBrowser) {
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("token", this.bookForm.value.phone);
    }

    if (!this.checkin) {
      this._router.navigate(["/account"]);
    } else if (this.checkin || this.checkout) {
      // const href = atob(this.activatedRoute.snapshot.queryParams.href);
      const href = this.activatedRoute.snapshot.queryParams.href
        ? atob(this.activatedRoute.snapshot.queryParams.href)
        : "";

      const state = {
        url: btoa(href),
        property_id: btoa(this.property_id_data),
        checkin: btoa(this.checkin),
        checkout: btoa(this.checkout),
        guest: btoa(this.guest),
        property_name: btoa(this.property_name),
        price: btoa(this.price),
        minnights: btoa(this.minnight),
      };
      this._router.navigate([`${href}/booking`], { queryParams: state });
    } else {
      this._router.navigate(["/account"]);
    }
  }

  orderId: any;
  createNewOTP() {
    this.SpinnerService.show();
    this.startTimer();
    this.loginservice
      .createOTP("91" + this.bookForm.value.phone)
      .subscribe((response: any) => {
        this.orderId = response["orderId"];
        this.loginservice.getlogin("91" + this.bookForm.value.phone).subscribe(
          (response: any) => {
            if (response.success === true) {
              this.loginhide = false;
            }
            if (response.success === false) {
              this.loginhide = true;
            }
          },
          (error) => {
            this.loginhide = true;
          }
        );
      });
  }

  resendOTP() {
    this.startTimer();
    this.loginservice.resendOTP(this.orderId).subscribe((response: any) => {
      this.orderId = response["orderId"];
      if (this.orderId) {
      }
    });
  }

  isOTPVerified: any;
  resendOtp: boolean = false;

  verifyNewOTP() {
    this.SpinnerService.show();
    const otp = (document.getElementById("otp") as HTMLInputElement).value;

    if (otp) {
      this.loginservice
        .verifyOTP(this.orderId, otp, "91" + this.bookForm.value.phone)
        .subscribe(
          (response: any) => {
            this.isOTPVerified = response.isOTPVerified;
            if (this.isOTPVerified === true) {
              // this.loginhide = false;
              this.onsubmit();
            } else {
              // OTP verification failed
              this.resendOtp = true;
              this.secondFormGroup.get("otp")?.reset("");
            }
          },
          (error) => {
            console.error("Error during OTP verification:", error);
          }
        );
    } else {
      this.showError = true;
    }
  }

  onsubmit() {
    this.SpinnerService.show();
    this.loginservice.getlogin("91" + this.bookForm.value.phone).subscribe(
      (response: any) => {
        if (response.success === true) {
          if (response.data) this.loginservice.IsLoginData.next(response.data);
          this.login();
        } else {
          this.register();
        }
      },
      (error: any) => {
        this.register();
      }
    );
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      history.pushState(null, "");

      this.activatedRoute.queryParams
        .pipe(filter((param) => !!Object.keys(param).length))
        .subscribe((queryParams) => {
          this.checkin = atob(this.activatedRoute.snapshot.queryParams.checkin);
          this.checkout = atob(
            this.activatedRoute.snapshot.queryParams.checkout
          );
          this.guest = atob(this.activatedRoute.snapshot.queryParams.guest);
          this.property_id_data = atob(
            this.activatedRoute.snapshot.queryParams.property_id
          );
          this.price = atob(this.activatedRoute.snapshot.queryParams.price);
          this.mainimage = atob(
            this.activatedRoute.snapshot.queryParams.mainimage
          );
          this.property_name = atob(
            this.activatedRoute.snapshot.queryParams.property_name
          );
          this.locations = atob(
            this.activatedRoute.snapshot.params._value?.locationname
          );
          this.minnight = atob(
            this.activatedRoute.snapshot.queryParams.minnights
          );
        });

      fromEvent(window, "popstate")
        .pipe(takeUntil(this.unsubscriber))
        .subscribe((_) => {
          history.pushState(null, "");

          this.showErrorModal(
            `You can't make changes or go back at this time.`
          );
        });

      this.authService.logout();
    }
  }

  startTimer() {
    this.timer = 60;
    this.timerSubscription = interval(1000).subscribe(() => {
      this.timer--;
      if (this.timer === 0) {
        this.stopTimer();
      }
      this.countdown = this.formatTimer(this.timer);
    });
  }

  stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      // this.timer = 60; // Reset timer
      // this.countdown = this.formatTimer(this.timer); // Reset countdown display
    }
  }

  formatTimer(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const displayMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const displaySeconds =
      remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
    return `${displayMinutes}:${displaySeconds}`;
  }

  get f() {
    return this.bookForm.controls;
  }
}
