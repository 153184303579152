<h1 mat-dialog-title>You Have to verify Phone Number </h1>
<mat-stepper linear #stepper aria-disabled="true">
  <mat-step >
      <form >
          <ng-template matStepLabel>Fill out your Details</ng-template>
          <div class="row">
                  
        
              <!-- <mat-form-field appearance="fill">
                <mat-label>Enter Your Phone Number</mat-label>
                <input matInput class="form-control" id="phoneno"
                formControlName="phoneno" placeholder="Phone (e.g. 99000XX706)"
                required>
              </mat-form-field> -->

              <div class="form-group">
                <input type="phone" class="form-control phn-n" id="phone"
                formControlName="phone" type="number" placeholder="Exist Phone No."
                ng-required="true">
            </div>
              
          </div>
          <div class="bttn-boooking"> 
          <button class="btn btn-primary" mat-button matStepperNext >Verify</button>
          </div>
      </form>
  </mat-step>
  <mat-step  >
      <form >
          <ng-template matStepLabel>Enter OTP</ng-template> 
        
          
          <div class="feilds-input">
              <input type="number" class="form-control" id="otp" formControlName="otp"
                              placeholder="Enter OTP" ng-required="true">	
          </div>
          <div class="bttn-boooking">									 
              <button class="btn btn-primary" mat-button matStepperNext >Verify Otp</button>
          </div>										
      </form>
  </mat-step>								
</mat-stepper>