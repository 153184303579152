import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  Output,
  PLATFORM_ID,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject } from "rxjs";
import { aboutservice } from "src/app/services/aboutservice";

@Component({
  selector: "app-about-dialog",
  template: ` <div class="row d-flex close_btn">
      <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="filter_btn_wrapper">
      <div class="btn_item" *ngFor="let button of buttonName">
        <button
          class="diolog-bttn"
          [class.diolog-bttn1]="button.name === 'Price'"
          (click)="onClick(button.name)"
          [class.filter_btn_active]="viewMode == button.name"
        >
          <ng-container *ngIf="button.name == 'Price'">
            <div
              [hidden]="selectedMinMaxPrice === 0"
              matBadge="{{ selectedMinMaxPrice }}"
              matBadgePosition="after"
              matBadgeColor="accent"
            >
              {{ button.name }}
            </div>
            <div *ngIf="selectedMinMaxPrice === 0">
              {{ button.name }}
            </div>
          </ng-container>
          <ng-container *ngIf="button.name == 'Eko Category'">
            <div
              [hidden]="selectedEkoCategoryCheckboxCount === 0"
              matBadge="{{ selectedEkoCategoryCheckboxCount }}"
              matBadgePosition="after"
              matBadgeColor="accent"
            >
              {{ button.name }}
            </div>
            <div *ngIf="selectedEkoCategoryCheckboxCount === 0">
              {{ button.name }}
            </div>
          </ng-container>
          <ng-container *ngIf="button.name == 'Top Filters'">
            <div
              [hidden]="selectedTopFilterCount === 0"
              matBadge="{{ selectedTopFilterCount }}"
              matBadgePosition="after"
              matBadgeColor="accent"
            >
              {{ button.name }}
            </div>
            <div *ngIf="selectedTopFilterCount === 0">
              {{ button.name }}
            </div>
          </ng-container>
        </button>
      </div>
    </div>

    <div [ngSwitch]="viewMode">
      <div *ngSwitchCase="'Price'">
        <div class="price_range">
          <div class="price_item">
            <div class="price_tag">₹</div>
            <input
              type="text"
              [(ngModel)]="minPrice"
              placeholder="Min Price For Stay"
            />
          </div>

          <div class="price_item">
            <div class="price_tag">₹</div>
            <input
              type="text"
              [(ngModel)]="maxPrice"
              placeholder="Max Price For Stay"
            />
          </div>

          <div class="apply_ctr">
            <button (click)="onApplyFilter(viewMode)">Apply</button>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="'Eko Category'">
        <div class="top-filter custom_TF"></div>

        <div class="eko_category">
          <fieldset>
            <div
              class="category_item"
              [ngClass]="{ '.eko_category': ekoOriginal === true }"
            >
              <input
                type="checkbox"
                id="eko-original"
                [(ngModel)]="ekoOriginal"
                (change)="updateCheckboxCount()"
              />

              <label for="eko-original">Eko original </label>
            </div>

            <div
              class="category_item"
              [ngClass]="{ '.eko_category': ekoGold === true }"
            >
              <input
                type="checkbox"
                id="eko-gold"
                [(ngModel)]="ekoGold"
                (change)="updateCheckboxCount()"
              />
              <label for="eko-gold">Eko Gold</label>
            </div>

            <div
              class="category_item"
              [ngClass]="{ '.eko_category': ekoLuxe === true }"
            >
              <input
                type="checkbox"
                id="eko-luxe"
                [(ngModel)]="ekoLuxe"
                (change)="updateCheckboxCount()"
              />
              <label for="eko-luxe">Eko Luxe</label>
            </div>

            <div class="apply_ctr">
              <button (click)="onApplyFilter(viewMode)">Apply</button>
            </div>
          </fieldset>
        </div>
      </div>

      <div *ngSwitchCase="'Top Filters'">
        <div class="row" style="padding:20px" *ngIf="isShow == true">
          <div class="top-filter">
            <p>No. of Bedrooms</p>
            <div class="counter">
              <button class="counter-bttn" (click)="incrementCounter()">
                +
              </button>
              <div class="counter-value">{{ counterValue }}</div>
              <button class="counter-bttn" (click)="decrementCounter()">
                -
              </button>
            </div>
          </div>
          <div class="top-filter">
            <p>No. of Bathrooms</p>
            <div class="counter">
              <button
                class="counter-bttn"
                (click)="incrementCounterBathrooms()"
              >
                +
              </button>
              <div class="counter-value">{{ bathroomsCounterValue }}</div>
              <button
                class="counter-bttn"
                (click)="decrementCounterBathrooms()"
              >
                -
              </button>
            </div>
          </div>
          <div>
            <ul class="filter_featured_list">
              <li *ngFor="let data of topFilterData">
                <label class="material-checkbox">
                  <input
                    type="checkbox"
                    [(ngModel)]="data.selected"
                    (change)="(selectedTopFilterCount)"
                  />
                  <span class="checkmark"></span>
                  {{ data.name }}
                </label>
              </li>
            </ul>

            <div class="apply_ctr">
              <button (click)="onApplyFilter(viewMode)">Apply</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clr-btn">
      <button (click)="clearFilters(viewMode)">Clear Filter</button>
    </div>
    <div></div>`,
  styleUrls: ["./about.component.css"],
})
export class AboutDialogComponent {
  @Output() selectedCountChange = new EventEmitter<number>();
  isShow: boolean = false;
  btnName: any;
  viewMode: any = "Price";
  counterValue = 0;
  bathroomsCounterValue = 0;
  filterForm: FormGroup;
  minPrice: 0;
  maxPrice: 0;
  ekoOriginal: boolean = false;
  ekoGold: boolean = false;
  ekoLuxe: boolean = false;
  filterData: any;
  isBrowser: boolean;

  // for slected count number
  selectedEkoCategoryCheckboxCount: number = 0;

  constructor(
    public dialogRef: MatDialogRef<AboutDialogComponent>,
    private aboutService: aboutservice,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  buttonName = [
    {
      name: "Price",
    },
    {
      name: "Eko Category",
    },
    {
      name: "Top Filters",
    },
  ];

  topFilterData: any[] = [
    {
      name: "Chef Service",
      selected: false, // Add a selected property to track the checkbox state
    },
    {
      name: "Pet Friendly",
      selected: false,
    },
    // {
    //   name: "Best Rated",
    //   selected: false,
    // },
    {
      name: "Offbeat",
      selected: false,
    },
    {
      name: "Newly Launched",
      selected: false,
    },
    {
      name: "Family Friendly",
      selected: false,
    },
    {
      name: "Budget Friendly",
      selected: false,
    },
  ];

  data: any;
  ngOnInit() {
    if (this.isBrowser) {
      const dataFromLocalStorage = localStorage.getItem("filterCriteria");

      if (dataFromLocalStorage) {
        this.data = JSON.parse(dataFromLocalStorage);

        this.ekoGold = this.data.ekoGold;
        this.ekoOriginal = this.data.ekoOriginal;
        this.ekoLuxe = this.data.ekoLuxe;
        this.minPrice = this.data.minPrice;
        this.maxPrice = this.data.maxPrice;
        this.counterValue = this.data.bedroom || 0;
        this.bathroomsCounterValue = this.data.bathroom || 0;

        const storedCount = localStorage.getItem(
          "selectedEkoCategoryCheckboxCount"
        );
        this.selectedEkoCategoryCheckboxCount = storedCount
          ? parseFloat(storedCount)
          : 0;

        // Patch the values for checkboxes
        this.topFilterData.forEach((filter) => {
          filter.selected =
            this.data.topFilterData?.find(
              (data: any) => data.name === filter.name
            )?.selected || false;
        });
        this.updateCheckboxCount()
      }
    }
  }

  updateCheckboxCount() {
    this.selectedEkoCategoryCheckboxCount = [
      this.ekoOriginal,
      this.ekoGold,
      this.ekoLuxe,
    ].filter(Boolean).length;
  }

  increment = 0;
  increment1 = 0;
  get selectedTopFilterCount(): number {
    if (this.counterValue > 0) {
      this.increment = 1;
    }
    if (this.bathroomsCounterValue > 0) {
      this.increment1 = 1;
    }
    return (
      this.topFilterData.filter((item) => item.selected).length +
      this.increment +
      this.increment1
    );
  }

  get selectedMinMaxPrice(): number {
    if (this.minPrice > 0 && this.maxPrice > 0) {
        return 2; // Both minPrice and maxPrice are available
    } else if (this.minPrice > 0 || this.maxPrice > 0) {
        return 1; // Either minPrice or maxPrice is available
    } else {
        return 0; // Neither minPrice nor maxPrice is available
    }
}


  get totalSelectedCount(): number {
    return (
      this.selectedEkoCategoryCheckboxCount +
      this.selectedTopFilterCount +
      this.selectedMinMaxPrice
    );
  }

  updateTotalCount(isClear?:boolean) {
    const count = isClear?this.totalSelectedCount - this.selectedTopFilterCount:this.totalSelectedCount;
    this.selectedCountChange.emit(count);
    localStorage.setItem("totalSelectedCount", JSON.stringify(count))
  }
  onClick(name: any) {
    this.isShow = true;
    this.btnName = name;
    this.viewMode = name;
  }

  incrementCounter() {
    this.counterValue++;
  }

  decrementCounter() {
    if (this.counterValue > 0) {
      this.counterValue--;
    }
  }

  incrementCounterBathrooms() {
    this.bathroomsCounterValue++;
  }

  decrementCounterBathrooms() {
    if (this.bathroomsCounterValue > 0) {
      this.bathroomsCounterValue--;
    }
  }

  onApplyFilter(name: string,isClear?:boolean): void {
    const selectedTopFilters = this.topFilterData.filter(
      (item) => item.selected
    );

    // Initialize a general filterCriteria object
    let filterCriteria: any = this.filterData;

    if (this.selectedMinMaxPrice)
      filterCriteria = {
        ...filterCriteria,
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
      };
    if (this.selectedEkoCategoryCheckboxCount)
      filterCriteria = {
        ...filterCriteria,
        ekoOriginal: this.ekoOriginal,
        ekoGold: this.ekoGold,
        ekoLuxe: this.ekoLuxe,
      };
    if (this.selectedTopFilterCount)
      filterCriteria = {
        ...filterCriteria,
        bedroom: this.counterValue,
        bathroom: this.bathroomsCounterValue,
        topFilterData: isClear?[]:selectedTopFilters,
      };
    // Assign the filterCriteria to this.filterData
    this.filterData = filterCriteria;
    if (name === "Price") {
      this.dialogRef.close(this.filterData);
      this.viewMode = "Eko Category";
    } else if (name === "Eko Category") {
      this.dialogRef.close(this.filterData);
      this.viewMode = "Top Filters";
      this.isShow = true;
    } else if (name === "Top Filters") {
      this.dialogRef.close(this.filterData);
    }

    // Additional code if needed based on the filterData

    this.aboutService.filterDataBehaviour.next(this.filterData);
    if (this.isBrowser) {
      localStorage.setItem("filterCriteria", JSON.stringify(this.filterData));
      localStorage.setItem("totalSelectedCount", JSON.stringify(this.totalSelectedCount))
    }

    this.updateTotalCount(isClear);
  }

  clearFilters(viewMode:string) {
    if(viewMode === "Price"){

      this.minPrice = 0;
      this.maxPrice = 0;
    }
    if(viewMode === "Eko Category"){

      this.ekoOriginal = false;
      this.ekoGold = false;
      this.ekoLuxe = false;
      this.selectedEkoCategoryCheckboxCount = 0;
    }
    if(viewMode === "Top Filters"){

      this.counterValue = 0;
      this.bathroomsCounterValue = 0;
      this.bathroomsCounterValue = 0;
      let isSelected = false;
      this.aboutService.filterDataBehaviour.next(isSelected);
      this.selectedCountChange.emit(0);
    }
    this.onApplyFilter(viewMode,true)
    this.dialogRef.close(this.filterData);
  }
}
