import { Injectable } from '@angular/core';

import {Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable()

export class aboutservice {

  public filterDataBehaviour:BehaviorSubject<any> = new BehaviorSubject({});
  public goldLuxeDataBehaviour:BehaviorSubject<any> = new BehaviorSubject({});
  public copyDataBehaviour:BehaviorSubject<any> = new BehaviorSubject({});
  public expiryBehaviour:BehaviorSubject<any> = new BehaviorSubject({});
  public footerBehaviour:BehaviorSubject<any> = new BehaviorSubject({});
  public ekoBehaviour:BehaviorSubject<any> = new BehaviorSubject({});
  
  isSessionVaild = true;
  constructor(private http: HttpClient, public router: Router) {  }
  


getAbouts(state:any,location:any,propertyName:any,guest:any,checkin:any,checkout:any,selectedPet:any,childrenUnderSix:any,type:any,party_friendly:any,budget_friendly:any,newly_villas:any,group_villas:any,offbeat_villas:any,order_by:any) {    
  let catType = '';
  let orderset = ''
   if(type=='original'){
     catType = '&original=on';
   }else if(type=='gold'){
     catType = '&gold=on';
   }else if(type=='luxe'){
     catType = '&luxe=on';
   }

   if(order_by != ''){
     if(order_by == 'rating')
     {
       orderset ='rating&order_by_type=desc'
     }
     else if(order_by == 'high'){
        orderset = 'inputname_monday&order_by_type=desc'
     }
     else if(order_by == 'low'){
       orderset = 'inputname_monday&order_by_type=asc'
     }
   }
   if(location){
     location = location;
   }else{
     location = '';
   }
   var sedreq = 'https://server.ekostay.com/public/api/propertiesnewtest?state='+state;
   if(location){
     sedreq += '&location='+location;
   }
   sedreq +='&name='+propertyName
   +'&guest='+guest
   +'&checkin='+checkin
   +'&checkout='+checkout+catType
   +'&pet='+selectedPet
   +'&under_6='+childrenUnderSix
 
   +'&party_friendly='+party_friendly
   +'&budget_friendly='+budget_friendly
   +'&newly_villas='+newly_villas
   +'&group_villas='+group_villas
   +'&offbeat_villas='+offbeat_villas
   +'&order_by_column='+orderset;

   
   return this.http.get(sedreq,{      
     headers : {
        'Content-Type' : 'application/json'   
     }      
        
   });
 }


 getAboutsCat(state:any,location:any,propertyName:any) {    
  
   if(location){
     location = location;
   }else{
     location = '';
   }
   var sedreq = 'https://'+environment.domain+'/public/api/propertiesnewcat?state='+state;
   if(location){
     sedreq += '&location='+location;
   }
   sedreq +='&name='+propertyName


   
   return this.http.get(sedreq,{      
     headers : {
        'Content-Type' : 'application/json'       
     }      
        
   });
 }

 getAboutss(ekoname:any, guest:any,checkin:any,checkout:any) {    
  

   return this.http.get('https://'+environment.domain+'/public/api/propertiesnew?'+ ekoname + '=on',{      
     headers : {
        'Content-Type' : 'application/json'       
     }      
        
   });
 }


  getGold(location:any) {    
    return this.http.get('https://'+environment.domain+'/public/api/propertiesweb?gold=on'+'&location='+location,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }





  getParty(location:any) {    
    return this.http.get('https://'+environment.domain+'/public/api/propertiesweb?party_friendly=on'+'&location='+location,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }



  getBudget(location:any) {    
    return this.http.get('https://'+environment.domain+'/public/api/propertiesweb?budget_friendly=on'+'&location='+location,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }


  getNewly(location:any) {    
    return this.http.get('https://'+environment.domain+'/public/api/propertiesweb?newly_villas=on'+'&location='+location,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }



  getGroup(location:any) {    
    return this.http.get('https://'+environment.domain+'/public/api/propertiesweb?group_villas=on'+'&location='+location,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }



  getOffbeat(location:any) {    
    return this.http.get('https://'+environment.domain+'/public/api/propertiesweb?offbeat_villas=on'+'&location='+location,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }



  getOriginal(location:any,checkin:any,checkout:any,guest:any) {    
    return this.http.get('https://'+environment.domain+'/public/api/propertiesweb?gold=on'+'&location='+location+'&checkin='+checkin
    +'&checkout='+checkout+'&guest='+guest,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }



  getLuxe(location:any,checkin:any,checkout:any,selectedGuest: any) {    
    return this.http.get('https://'+environment.domain+'/public/api/propertiesweb?luxe=on'+'&location='+location+'&checkin='+checkin
    +'&checkout='+checkout+'&guest='+selectedGuest,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }


  getCategories() {
    return this.http.get('https://'+environment.domain+'/public/api/categorys',{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }


  getLocationsNearby(location:any,checkin:any,checkout:any) {
    return this.http.get('https://'+environment.domain+'/public/api/propertieslocation?location='+location +'&checkin='+checkin
    +'&checkout='+checkout,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }




  getbanners() {
    return this.http.get('https://'+environment.domain+'/public/api/banners',{
      headers : {
          'Content-Type' : 'application/json'
      }      
    });
  }


  getDeals() {
    return this.http.get('https://'+environment.domain+'/public/api/couponwise-properties',{
      headers : {
          'Content-Type' : 'application/json'
      }
    });

  }



  getPropertyName() {
    return this.http.get('https://'+environment.domain+'/public/api/searchproperties',{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }

  getOurTeamDetails(){
    return this.http.get('https://'+environment.domain+'/public/api/our-team-list')
  }

  ngOnDestroy():void{
    this.copyDataBehaviour.unsubscribe();
  }
}
