import { Injectable } from '@angular/core';

import {Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';


@Injectable()
export class blogservice {
  isSessionVaild = true;
  constructor(private http: HttpClient, public router: Router) {  }
  

  getBlogs() {
    return this.http.get('https://'+environment.domain+'/public/api/blogs',{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }
}
