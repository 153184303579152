import { Component, OnInit } from '@angular/core';
import { MatDialog} from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, NavigationStart, Route, Router } from '@angular/router';
import { DataLayerService } from 'src/app/services/data-layer.service';
import { detailservice } from 'src/app/services/detailservice';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  show = true;
  iflogin: boolean = false;
  ifnotlogin: boolean = true;

  newyear = (new Date()).getFullYear();

  close() {
    this.show = false;    
  }

  constructor(public dialog: MatDialog, private dataLayerService: DataLayerService, private detailService:detailservice, private router:Router) {
  }
  url:any;
  paramCount:any
  contactNo :any;
  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // This event is triggered when navigation starts
        // console.log('Navigation started to', event.url);
      }
      if (event instanceof NavigationEnd) {
        // This event is triggered when navigation ends successfully
        // console.log('Navigation ended at', event.url);
        this.url = event.url;
        const paramCount = this.url ? this.countParameters(this.url) : 0;
        this.paramCount = paramCount;
        
        // Perform logic dependent on paramCount and url
        if (this.paramCount === 4 || (this.url && this.url.includes('checkout')) || (this.url && this.url.includes('thanks'))) {
          this.detailService.hostContacrNoBehaviour.subscribe(res=>{
            if(res)
            this.contactNo = res
          })
          // this.contactNo = localStorage.getItem('contactNo');
        } else {
          this.contactNo = '9004844487';
        }
      }
    });
  }

  countParameters(url: string): number {
    const segments = url.split('/');
    return segments.length - 1; // Subtract 1 to exclude the empty string at the beginning
  }

  onWhatsapp(){
    
    const canonicalUrl = document.location.origin + document.location.pathname;
    const data = {
      event: 'whatsapp_click',
      canonicalUrl: canonicalUrl,
      whatsappClick:"true",
    };
    
    // Push the data to the dataLayer
    this.dataLayerService.pushToDataLayer(data);
  }

}