import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { aboutservice } from '../../services/aboutservice';
import { locationservice } from '../../services/locationservice';

import { propertyservice } from '../../services/propertyservice';
import { environment } from '../../../environments/environment';
import {Router, ActivatedRoute} from '@angular/router';
import { Title,Meta } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SiteDataHandlerService } from 'src/app/services/site-data-handler.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { Observable } from 'rxjs';
import * as moment from 'moment';
@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

  imageSrc = 'https://'+environment.domain+'/public/uploads/images/';
  imagePropertySrc = 'https://'+environment.domain+'/public/property_images/';
  aboutData: any = [];
  about: any = [];
  locationData: any = [];
  categoryData: any = [];
  locationDatas: any = [];
  locationsNearbyData: any = [];
  propertyName = '';
  state = 'Maharashtra';
  checkin:any;
  checkout:any;
  location = '';
  type = '';
  guest = '';
  party_friendly = '';
  budget_friendly = '';
  newly_villas = '';
  group_villas = '';
  offbeat_villas = '';
  numberOfNightss:any=[];
  sorting:any=[];
  iflogin: boolean = false;
  ifnotlogin: boolean = true;
  
  minDate = new Date();

  @ViewChild('fromInput', {
  read: MatInput
  }) fromInput: MatInput;

  @ViewChild('toInput', {
    read: MatInput
  }) toInput: MatInput;
  name: string;
  headerData: any;
  location_property: any = [];
  eventData: any = [];
  checkValue: any;
  isLocationorProperty: boolean = true;
 
  selectedProperty: any;
  villa: any;
  getnightsforkullu: any = [];
  channeldata: any = [];
  hides:  boolean = true;
  locImages: any = [];
  dataMesc: any = [];
  stateArray: any = [];
  propertiesArray:any = [];

  resetForm() {
    this.fromInput.value = '';
    this.toInput.value = '';
  }


 
  detailData: any = [];
  finalPrice: any = [];

  sortBy :any = [{'name' : 'Low to High', 'id' : 'low', 'arrange':'ase'},{'name' : 'High to Low', 'id' : 'high'},{'name' : 'Popularity', 'id' : 'rating'}];
  price : any = [];
  sort ='';
 
  id:any =[];

  meta_desc:any=[];
  meta_title:any=[];
  locationDatapis:any=[];
  getStatesRpi:any=[];
  typepip:any=[];
  locationpip:any=[];
  isReadMore: boolean = true;
  data:any;
  propertiesData: any = [];
  propertiesDataApires: any[];
  locationArray: any = [];
  
  public div1:boolean=false;
  dateFilter = (d: Date | null): boolean => {
    const time = (d || new Date()).getTime();
    const checkInTime = (new Date(this.checkin) || new Date()).getTime();    
    return checkInTime < time;
  };   
  inputname_monday:any=[];
  inputname_mondayy:any=[];
  finalPriceGuest:any=[];
  calPrices: number;

  startDate: Date;

  show = 6;

  optionss: string[] = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30'];
  

  myControl = new UntypedFormControl('');
  options: any = [];
  filteredOptions: Observable<string[]>;

  aboutForm: UntypedFormGroup;

  isRead = false;
  uid = 223;
  
  

  
  increaseShow() {
    this.show += 3; 
  }

  increaseShows() {
    this.show += 3; 
  }

  @HostListener("window:scroll", [])
  onScroll(): void {
    if (this.bottomReached()) {

      this.increaseShow();
      
    }
  }

  bottomReached(): boolean {
    return (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
  }
 

  

  constructor(private SpinnerService: NgxSpinnerService,public propertyservice: propertyservice, public aboutservice: aboutservice,public locationservice: locationservice,private activatedRoute: ActivatedRoute, private router: Router,private metaService: Meta,private title: Title, private siteService:SiteDataHandlerService) {
    this.SpinnerService.show();
    this.hides = true;  
    let state = this.activatedRoute.snapshot.params.state;

    this.activatedRoute.queryParams.subscribe(
      params => {
        this.state = params['state'] || '';
        var location = params['location'] || '';
        this.propertyName = params['propertyName'] || '';
        this.guest = params['guest'] || '';
        this.checkin = params['checkin'] || '';
        this.checkout = params['checkout'] || '';
        this.location = params['location'] || '';
        this.type = params['type'] || '';
        this.sorting = params['sortby'] || '';
        this.party_friendly = params['party_friendly'] || '';
        this.budget_friendly = params['budget_friendly'] || '';
        this.newly_villas = params['newly_villas'] || '';
        this.group_villas = params['group_villas'] || '';
        this.offbeat_villas = params['offbeat_villas'] || '';

        this.state = "Maharashtra";
       

        aboutservice.getLocationsNearby(this.location,this.checkin,this.checkout).subscribe((response:any) =>
        {
          this.locationsNearbyData = response['data']['properties'];
        });
      });


      
    
      this.title.setTitle('Ekostay - Party Friendly Category Villas');
      this.metaService.addTag({ name: 'description', content: 'Gold properties are a new offering by EKOSTAY designed for a luxurious and premium staycation' });

      locationservice.getLocations().subscribe((response:any) =>
      {
        this.locationData = response['data'];
      });

      locationservice.getLocationsd().subscribe((response:any) =>
      {
        this.locationDatas = response['data'];
      });

      aboutservice.getCategories().subscribe((response:any) =>
      {
        this.categoryData = response['data'];
      });

      var dateN = new Date(this.checkin);
      var dateM = new Date(this.checkout);   
      
  
      if (this.checkin != '' && this.checkout != '') {
      var timeDiff = dateM.getTime() - dateN.getTime();
      var numberOfNights = timeDiff / (1000 * 3600 * 24);
      }else {
        var numberOfNights = 0;
      }
  
      this.numberOfNightss = numberOfNights;
       

      this.hides = true;
   	}

  customOptions: OwlOptions = {
  loop: false,
  mouseDrag: false,
  touchDrag: true,
  pullDrag: true,
  dots: false,
  navSpeed: 700,
  navText: ['<', '>'],
  responsive: {
    0:{
      items:1
    }
  },
  nav: true
}

  ngOnInit(): void {
    this.siteService.checkIn.subscribe(res=>{
      if(res == undefined || res == null || res == ''){
        this.checkin = sessionStorage.getItem("check-in");
      }
      else{this.checkin= res;}
    })
    this.siteService.checkOut.subscribe(res=>{if(res == undefined || res == null || res == ''){
      this.checkout = sessionStorage.getItem("check-out");
    }
    else{this.checkout= res;}}) 


    this.checkin = this.checkin;
    this.checkout = this.checkout;
    

   

    if(this.checkin != null || this.checkout != null){
      this.hides = true;
      this.filterProperties();
    }else if(this.checkin == null || this.checkout == null || this.checkin == '' || this.checkout == ''){
      this.hides = false;
    }


    this.title.setTitle("Exclusive Properties");  
          
      this.metaService.updateTag(  
        { name: 'description', content: "Book For 3 Nights & Get 1 Night Free." } 
           
      );  
      this.metaService.updateTag({ name: 'og:title', content: "Exclusive Properties" })
      this.metaService.updateTag({ name: 'og:description', content: "Book For 3 Nights & Get 1 Night Free." })


  }

  checkIn(value:any){
    var date1 = moment(this.checkin).format('YYYY-MM-DD');
    this.checkin = date1
    
   }
  
   checkOut(value:any){
    var date2 = moment(this.checkout).format('YYYY-MM-DD');
    this.checkout = date2

   }
   loadVilla() { 

    this.hides = true;
      // this.aboutservice.getAbouts(this.state,this.location,this.propertyName,this.guest,this.checkin,this.checkout,this.type,this.party_friendly,this.budget_friendly,this.newly_villas,this.group_villas,this.offbeat_villas, this.sorting).subscribe((response:any) =>
      // {
      //   this.aboutData = response['data']['properties']; 
      //   this.inputname_mondayy = response['data']['properties'][0]['inputname_monday'];
      //   this.finalPrice = response['data']['properties'][0]['final_price_pi'];
      //   this.finalPriceGuest = response['data']['properties'][0]['final_price_with_extraa_guest'];
        
        
      //   if (this.checkout != null || this.checkin != null) {
          
      //     this.inputname_monday = 0;
          
      //   }else {
      //     this.inputname_monday = 1;
      //   }
   
        
      //   this.aboutData.sort((a:any,b:any) => {
  
      //   return a.booked - b.booked;
      //   });
      
      //   this.aboutData.sort((a:any, b:any)=>{
      //     return b.status - a.status;
      //   });    
        
  
      //   this.hides = false;
        
      // });
  

      
  
     
  
  
  }


   filterProperties(){  
    this.SpinnerService.show();
    if (this.checkout == "Invalid date" || this.checkout == '') {
      this.SpinnerService.hide();
    }else {
      this.SpinnerService.show();
    }
    if(this.checkin != null && this.checkin != '') 
    {
      this.siteService.updateCheckIn(this.checkin)
    }
    if(this.checkout != null  && this.checkin != '') 
    {
      this.siteService.updateCheckOut(this.checkout)  
    }
    this.router.navigate(['/property/location/'+this.location], { queryParams: { propertyName:this.propertyName,guest:this.guest,checkin: this.checkin, checkout: this.checkout,type: this.type,sortby:this.sorting},relativeTo : this.activatedRoute });
    var dateN = new Date(this.checkin);
    var dateM = new Date(this.checkout);
  
    if (this.checkin != '' && this.checkout != '') {
      var timeDiff = dateM.getTime() - dateN.getTime();
      var numberOfNights = timeDiff / (1000 * 3600 * 24);
      }else {
        var numberOfNights = 0;
      }
       
    this.numberOfNightss = numberOfNights; 
    
   this.loadVilla();
  
    
   this.router.routeReuseStrategy.shouldReuseRoute = () => true;
   this.SpinnerService.hide(); 
      
    
    }

}
