<section class="page-wrapper">
  <section class="page-banner">
    <h1>Exclusive Properties</h1>
    <h2 class="heading-2">Book For 2 Nights & get 1 free.</h2>
  </section>
  <section class="about-page page-section">
    <div class="container followMeBar">
      <div class="filter-wrapper">
        <div class="row">
          <div class="col-md-4 filter-feild">
            <mat-form-field appearance="fill">
              <mat-date-range-input
                [rangePicker]="picker"
                [matDatepickerFilter]="dateFilter"
                [min]="minDate"
                class="form-control"
              >
                <input
                  matStartDate
                  #fromInput
                  matInput
                  placeholder="Check in"
                  [(ngModel)]="checkin"
                  (dateInput)="checkIn(checkin)"
                />
                <input
                  matEndDate
                  #toInput
                  matInput
                  placeholder="Check out"
                  [(ngModel)]="checkout"
                  (dateInput)="checkOut(checkout)"
                  id="datepicker"
                  (dateChange)="filterProperties()"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <div class="col-md-4 filter-feild">
            <div class="form-group">
              <mat-select
                type="text"
                class="form-control"
                (selectionChange)="filterProperties()"
                [(ngModel)]="guest"
                placeholder="Guest"
              >
                <mat-option *ngFor="let option of optionss" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-select>
            </div>
          </div>
          <div class="col-md-4 filter-feild">
            <div class="form-group">
              <mat-select
                class="form-control"
                (selectionChange)="filterProperties()"
                [(ngModel)]="type"
              >
                <mat-option value="" selected="selected" disabled="disabled"
                  >Category Type</mat-option
                >
                <mat-option
                  *ngFor="let category of categoryData"
                  [value]="category.id"
                  >{{ category.name }}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="Properties property-list aboutDetail">
        <div class="row" *ngIf="aboutData.length != 0">
          <div *ngFor="let property of aboutData">
            <ng-container
              *ngIf="
                property?.id == 223 ||
                property?.id == 232 ||
                property?.id == 204 ||
                property?.id == 99 ||
                property?.id == 12 ||
                property?.id == 207 ||
                property?.id == 254 ||
                property?.id == 78 ||
                property?.id == 253 ||
                property?.id == 93 ||
                property?.id == 220 ||
                property?.id == 251 ||
                property?.id == 154 ||
                property?.id == 63
              "
            >
              <div class="col-md-4 bsp_padding-0">
                <div
                  class="bsp_bbb_item"
                  [ngClass]="{
                    'gold-bg': property.gold == 'on',
                    'luxe-bg': property.luxe == 'on'
                  }"
                >
                  <div class="image-box">
                    <ngx-skeleton-loader
                      count="1"
                      *ngIf=""
                      [theme]="{
                        width: '360px',
                        height: '200px',
                        'border-radius': 0
                      }"
                    ></ngx-skeleton-loader>
                    <div
                      class="badges"
                      *ngIf="property.gold != null || property.luxe != null"
                    >
                      <span
                        class="badge alert-primary"
                        *ngIf="property.gold == 'on'"
                        >EKO GOLD</span
                      >
                      <span
                        class="badge alert-success"
                        *ngIf="property.luxe == 'on'"
                        >EKO LUXE</span
                      >
                    </div>
                    <owl-carousel-o
                      [options]="customOptions"
                      *ngIf="property.property_media.length != 0"
                    >
                      <ng-container
                        *ngFor="
                          let pmedia of property.property_media;
                          index as i
                        "
                      >
                        <ng-container *ngIf="i == 0">
                          <ng-template carouselSlide [id]="pmedia.id">
                            <div class="slide">
                              <a
                                routerLink="property/location/villas-in-{{
                                  property.locname
                                }}/{{ property.permalink }}"
                                class="bsp_a"
                                onclick="theFunction();"
                              >
                                <img
                                  src="{{ imagePropertySrc }}{{ pmedia.media }}"
                                  alt=""
                                  target="_blank"
                                />
                              </a>
                            </div>
                          </ng-template>
                        </ng-container>
                        <ng-container *ngIf="i > 0">
                          <ng-template carouselSlide [id]="pmedia.id">
                            <div class="slide">
                              <a
                                routerLink="property/location/villas-in-{{
                                  property.locname
                                }}/{{ property.permalink }}"
                                loading="lazy"
                                class="bsp_a"
                                target="_blank"
                                onclick="theFunction();"
                              >
                                <img
                                  src="{{ imagePropertySrc }}{{ pmedia.media }}"
                                  loading="lazy"
                                  alt=""
                                />
                              </a>
                            </div>
                          </ng-template>
                        </ng-container>
                      </ng-container>
                    </owl-carousel-o>
                    <a
                      routerLink="property/location/villas-in-{{
                        property.locname
                      }}/{{ property.permalink }}"
                      class="bsp_a"
                      *ngIf="property.property_media.length == 0"
                      target="_blank"
                      onclick="theFunction();"
                    >
                      <img
                        src="{{ imageSrc }}/{{ property.image }}"
                        alt="Villa in {{ name }} {{ location }}"
                      />
                    </a>
                  </div>

                  <a
                    routerLink="property/location/villas-in-{{
                      property.locname
                    }}/{{ property.permalink }}"
                    class="bsp_a"
                    target="_blank"
                    onclick="theFunction();"
                  >
                    <div class="detail-desc">
                      <h5
                        *ngIf="property.status == 0"
                        class="bsp_card-title inactive-title"
                      >
                        <span>Currently Inactive</span>
                      </h5>
                      <div class="text-left p-10">
                        <div>
                          <p class="bsp_card-text">
                            {{ property.property_name }}
                          </p>
                        </div>
                        <p class="loct">
                          <a href="#" class="more-details"
                            ><i class="fa fa-map-marker" aria-hidden="true"></i>
                            {{ property.locname }}
                          </a>
                        </p>
                        <ul class="roominn">
                          <li class="first-desc">
                            <i aria-hidden="true" class="fa fa-bed"></i>
                            {{ property.bed }} BHK
                          </li>

                          <li class="second-desc">
                            <i class="fa fa-bath" aria-hidden="true"></i>
                            {{ property.bathrooms }} Bathrooms
                          </li>

                          <li class="third-desc">
                            <i aria-hidden="true" class="fa fa-users"></i> Max
                            {{ property.member }} Guest
                          </li>
                        </ul>
                      </div>
                      <ng-container
                        *ngIf="property.member >= guest; else guested"
                      >
                        <ng-container *ngIf="inputname_monday == 1">
                          <h5
                            class="bsp_card-title"
                            *ngIf="property.status != 0"
                          >
                            <ngx-skeleton-loader
                              animation="pulse"
                              *ngIf="hides"
                              [theme]="{
                                width: 'rand',
                                height: '30px',
                                'background-color': '#A1A1A1',
                                'animation-duration': '2s'
                              }"
                            ></ngx-skeleton-loader>
                            <span *ngIf="!hides">
                              <span
                                class="price-desc"
                                *ngIf="numberOfNightss == 1"
                              >
                                ₹
                                {{
                                  property.inputname_monday * 1 | number : "2."
                                }}
                                /
                              </span>

                              <s class="straight" *ngIf="numberOfNightss == 1"
                                >₹{{
                                  property.inputname_monday *
                                    (1 + 0.26) *
                                    numberOfNightss | number : "2."
                                }}</s
                              >

                              <span
                                class="price-desc"
                                *ngIf="numberOfNightss > 1"
                              >
                                ₹
                                {{
                                  property.inputname_monday * numberOfNightss
                                    | number : "2."
                                }}
                                /
                              </span>

                              <s class="straight" *ngIf="numberOfNightss > 1"
                                >₹{{
                                  property.inputname_monday *
                                    (1 + 0.26) *
                                    numberOfNightss | number : "2."
                                }}</s
                              >

                              <span
                                class="price-desc"
                                *ngIf="numberOfNightss == 0"
                              >
                                ₹
                                {{
                                  property.inputname_monday | number : "2."
                                }}
                                /
                              </span>

                              <s class="straight" *ngIf="numberOfNightss == 0"
                                >₹{{
                                  property.inputname_monday * (1 + 0.26)
                                    | number : "2."
                                }}</s
                              >

                              <span
                                class="night-desc"
                                *ngIf="numberOfNightss == 1"
                                >For {{ numberOfNightss }} Night
                              </span>

                              <span
                                class="night-desc"
                                *ngIf="numberOfNightss > 1"
                                >For {{ numberOfNightss }} Nights
                              </span>

                              <span
                                class="night-desc"
                                *ngIf="numberOfNightss == 0"
                                >Night Onwards</span
                              >
                            </span>
                          </h5>
                          <span *ngIf="!hides"
                            ><span
                              *ngIf="property.status != 0"
                              class="float-right right-arrow"
                              ><i class="fa fa-angle-right font-20"></i></span
                          ></span>
                        </ng-container>
                        <ng-container *ngIf="inputname_monday == 0">
                          <ng-container
                            *ngIf="
                              property.min_nights > numberOfNightss;
                              else minnightsn
                            "
                          >
                            <h5
                              class="bsp_card-title"
                              *ngIf="property.status != 0"
                            >
                              <ngx-skeleton-loader
                                animation="pulse"
                                *ngIf="hides"
                                [theme]="{
                                  width: 'rand',
                                  height: '30px',
                                  'background-color': '#A1A1A1',
                                  'animation-duration': '2s'
                                }"
                              ></ngx-skeleton-loader>
                              <span *ngIf="!hides">
                                <span
                                  >Minimum
                                  {{ property.min_nights }} Required</span
                                >
                              </span>
                            </h5>
                            <span *ngIf="!hides"
                              ><span
                                *ngIf="property.status != 0"
                                class="float-right right-arrow"
                                ><i class="fa fa-angle-right font-20"></i></span
                            ></span>
                          </ng-container>
                          <ng-template #minnightsn>
                            <ng-container
                              *ngIf="
                                property.extra_guest == '1';
                                else normalprice
                              "
                            >
                              <h5
                                class="bsp_card-title"
                                *ngIf="property.status != 0"
                              >
                                <ngx-skeleton-loader
                                  animation="pulse"
                                  *ngIf="hides"
                                  [theme]="{
                                    width: 'rand',
                                    height: '30px',
                                    'background-color': '#A1A1A1',
                                    'animation-duration': '2s'
                                  }"
                                ></ngx-skeleton-loader>
                                <span *ngIf="!hides">
                                  <span
                                    class="price-desc"
                                    *ngIf="numberOfNightss == 1"
                                  >
                                    ₹
                                    {{
                                      property.final_price_with_extraa_guest
                                        | number : "2."
                                    }}
                                    /
                                  </span>

                                  <s
                                    class="straight"
                                    *ngIf="numberOfNightss == 1"
                                    >₹{{
                                      property.final_price_with_extraa_guest *
                                        (1 + 0.26) | number : "2."
                                    }}</s
                                  >

                                  <span
                                    class="price-desc"
                                    *ngIf="numberOfNightss > 1"
                                  >
                                    ₹
                                    {{
                                      property.final_price_with_extraa_guest
                                        | number : "2."
                                    }}
                                    /
                                  </span>

                                  <s
                                    class="straight"
                                    *ngIf="numberOfNightss > 1"
                                    >₹{{
                                      property.final_price_with_extraa_guest *
                                        (1 + 0.26) | number : "2."
                                    }}</s
                                  >

                                  <span
                                    class="price-desc"
                                    *ngIf="numberOfNightss == 0"
                                  >
                                    ₹
                                    {{
                                      property.final_price_with_extraa_guest
                                        | number : "2."
                                    }}
                                    /
                                  </span>

                                  <s
                                    class="straight"
                                    *ngIf="numberOfNightss == 0"
                                    >₹{{
                                      property.final_price_with_extraa_guest *
                                        (1 + 0.26) | number : "2."
                                    }}</s
                                  >

                                  <span
                                    class="night-desc"
                                    *ngIf="numberOfNightss == 1"
                                    >For {{ numberOfNightss }} Night
                                  </span>

                                  <span
                                    class="night-desc"
                                    *ngIf="numberOfNightss > 1"
                                    >For {{ numberOfNightss }} Nights
                                  </span>

                                  <span
                                    class="night-desc"
                                    *ngIf="numberOfNightss == 0"
                                    >Night Onwards</span
                                  >
                                </span>
                              </h5>
                              <span *ngIf="!hides"
                                ><span
                                  *ngIf="property.status != 0"
                                  class="float-right right-arrow"
                                  ><i
                                    class="fa fa-angle-right font-20"
                                  ></i></span
                              ></span>
                            </ng-container>
                            <ng-template #normalprice>
                              <h5
                                class="bsp_card-title"
                                *ngIf="property.status != 0"
                              >
                                <ngx-skeleton-loader
                                  animation="pulse"
                                  *ngIf="hides"
                                  [theme]="{
                                    width: 'rand',
                                    height: '30px',
                                    'background-color': '#A1A1A1',
                                    'animation-duration': '2s'
                                  }"
                                ></ngx-skeleton-loader>
                                <span *ngIf="!hides">
                                  <span
                                    class="price-desc"
                                    *ngIf="numberOfNightss == 1"
                                  >
                                    ₹
                                    {{
                                      property.final_price_pi | number : "2."
                                    }}
                                    /
                                  </span>

                                  <s
                                    class="straight"
                                    *ngIf="numberOfNightss == 1"
                                    >₹{{
                                      property.final_price_pi * (1 + 0.26)
                                        | number : "2."
                                    }}</s
                                  >

                                  <span
                                    class="price-desc"
                                    *ngIf="numberOfNightss > 1"
                                  >
                                    ₹
                                    {{
                                      property.final_price_pi | number : "2."
                                    }}
                                    /
                                  </span>

                                  <s
                                    class="straight"
                                    *ngIf="numberOfNightss > 1"
                                    >₹{{
                                      property.final_price_pi * (1 + 0.26)
                                        | number : "2."
                                    }}</s
                                  >

                                  <span
                                    class="price-desc"
                                    *ngIf="numberOfNightss == 0"
                                  >
                                    ₹
                                    {{
                                      property.final_price_pi | number : "2."
                                    }}
                                    /
                                  </span>

                                  <s
                                    class="straight"
                                    *ngIf="numberOfNightss == 0"
                                    >₹{{
                                      property.final_price_pi * (1 + 0.26)
                                        | number : "2."
                                    }}</s
                                  >

                                  <span
                                    class="night-desc"
                                    *ngIf="numberOfNightss == 1"
                                    >For {{ numberOfNightss }} Night
                                  </span>

                                  <span
                                    class="night-desc"
                                    *ngIf="numberOfNightss > 1"
                                    >For {{ numberOfNightss }} Nights
                                  </span>

                                  <span
                                    class="night-desc"
                                    *ngIf="numberOfNightss == 0"
                                    >Night Onwards</span
                                  >
                                </span>
                              </h5>
                              <span *ngIf="!hides"
                                ><span
                                  *ngIf="property.status != 0"
                                  class="float-right right-arrow"
                                  ><i
                                    class="fa fa-angle-right font-20"
                                  ></i></span
                              ></span>
                            </ng-template>
                          </ng-template>
                        </ng-container>
                      </ng-container>
                      <ng-template #guested>
                        <h5 *ngIf="property.status != 0" class="bsp_card-title">
                          <ngx-skeleton-loader
                            animation="pulse"
                            *ngIf="hides"
                            [theme]="{
                              width: 'rand',
                              height: '30px',
                              'background-color': '#A1A1A1',
                              'animation-duration': '2s'
                            }"
                          ></ngx-skeleton-loader>
                          <span *ngIf="!hides">
                            <span>Guest Limit Exceeding</span>
                          </span>
                        </h5>
                        <span *ngIf="!hides"
                          ><span
                            *ngIf="property.status != 0"
                            class="float-right right-arrow"
                            ><i class="fa fa-angle-right font-20"></i></span
                        ></span>
                      </ng-template>
                    </div>
                  </a>
                </div>
              </div>
            </ng-container>
          </div>

          <div
            *ngIf="show < aboutData.length"
            (click)="increaseShow()"
            class="view-more"
          >
            <h4>Loading Please Wait</h4>
          </div>
        </div>
      </div>
      <ngx-spinner
        bdColor="rgba(49, 15, 72, 0.8)"
        size="medium"
        type="ball-grid-beat"
        [fullScreen]="true"
      >
        <p style="color: #fff; z-index: 999999">Loading Your Staycation</p>
      </ngx-spinner>
    </div>
  </section>
</section>
