import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { loginservice } from "../../services/loginservice";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { isPlatformBrowser } from "@angular/common";
declare var wigzo: any;

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.css"],
})
export class AccountComponent implements OnInit {
  unsubscriber: Observable<any>;
  showErrorModal: any;
  id: any = "";
  name = "";
  phone = "";
  fname = "";
  email = "";
  dob = "";
  uid = "";
  member = "";
  totalConfirm = "";
  bookings: any = [];
  datas: any = [];
  isBrowser: boolean;
  InitialBookings: any;
  userDetails: any;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private authService: AuthService,
    private loginservice: loginservice,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      let id = localStorage.getItem("token");
      this.loginservice.getProfile(id).subscribe(
        (response: any) => {
          this.datas = response["data"];
          this.name = response["data"]["name"];
          this.fname = response["data"]["fname"];
          this.phone = response["data"]["phone"];
          this.email = response["data"]["email"];
          this.dob = response["data"]["dob"];
          this.member = response["data"]["member"];
          this.uid = response["data"]["id"];
          localStorage.setItem("isMembership", this.member);
          this.loginservice.getBooking(this.uid).subscribe((response: any) => {
            this.bookings = response["data"];
            this.InitialBookings = this.bookings;
          });
          this.loginservice
            .getConfirmedStatus(this.uid)
            .subscribe((response: any) => {
              this.totalConfirm = response["data"];
            });
            let phone = this.phone.toString();
      phone = phone.substring(2);
            wigzo ("identify", {
              email: this.email,
              phone: phone,
              fullName: this.name });
        },
        (error) => {},
        () => {}
      );
    }
  }

  openDialogs() {
    const dialogRef = this.dialog.open(DialogContentExampleDialog, {
      width: "400px",
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  openDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "400px";
    dialogConfig.data = this.datas; // Pass data to the dialog

    const dialogRef = this.dialog.open(
      DialogOverviewExampleDialog,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result) => {
      this.datas = result;
    });
  }

  ngOnInit(): void {
    if (this.isBrowser) {
      this.id = localStorage.getItem("token");
    }
  }
  logout() {
    this.authService.logout();
    this.router.navigate(["/login"]);
  }

  isUpcoming: boolean = false;
  isHistory: boolean = false;
  isBooking: boolean = false;

  checkBookingDetails(status: string) {
    const today = new Date();
    if (status === "now") {
      this.isUpcoming = !this.isUpcoming;
      this.isHistory = false;
      this.bookings = this.InitialBookings.filter(
        (booking: any) => new Date(booking.checkin) >= today
      );
    } else if (status === "past") {
      this.isHistory = !this.isHistory;
      this.isUpcoming = false;
      this.bookings = this.InitialBookings.filter(
        (booking: any) => new Date(booking.checkin) <= today
      );
    }
    if (this.bookings.length === 0) {
      this.isBooking = true;
    } else this.isBooking = false;
  }

  isRotated: boolean = false;
  isRotatedH: boolean = false;

  rotateIcon() {
    this.isRotated = !this.isRotated;
    this.isRotatedH = false;
  }
  rotateIcon1() {
    this.isRotatedH = !this.isRotatedH;
    this.isRotated = false;
  }

  openMembershipDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "69%"; // Set the desired width
    dialogConfig.maxWidth = "510px";
    dialogConfig.height = "650px";
    dialogConfig.minHeight = "10vh";
    dialogConfig.panelClass = "filter_modal";
    dialogConfig.data = this.datas;
    const dialogRef = this.dialog.open(MembershipDialog, dialogConfig);
  }

  cleanPropertyName(property_name: string): string {
    return property_name?.replace(/EKOSTAY -|EKOSTAY-/g, "");
  }
}

@Component({
  selector: "dialog-overview-example-dialog",
  templateUrl: "dialog-overview-example-dialog.html",
  styleUrls: ["./dialog-overview-example-dialog.css"],
})
export class DialogOverviewExampleDialog {
  maxDate: any;
  id: any;
  fname: any;
  dob: any;
  email: any;
  phone: any;
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) data: any,
    public loginService: loginservice
  ) {
    this.id = data.id;
    this.fname = data.name;
    this.dob = data.dob;
    this.phone = data.phone;
    this.email = data.email;
    this.maxDate = new Date();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  updateProfile() {
    const dateOfBirth = new Date(this.dob);
    const formattedDateOfBirth = `${dateOfBirth.getFullYear()}-${(
      dateOfBirth.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${dateOfBirth.getDate().toString().padStart(2, "0")}`;
    this.dob = formattedDateOfBirth;
    const data = {
      id: this.id,
      fname: this.fname,
      email: this.email,
      dateofbirth: this.dob,
    };
    this.loginService.updateProfile(data).subscribe((res: any) => {
      this.dialogRef.close(res.data);
    });
  }
}

@Component({
  selector: "membership-dialog",
  templateUrl: "membership-dialog.html",
  styleUrls: ["./membership-dialog.css"],
})
export class MembershipDialog {
  membershipData: any;
  constructor(
    public dialogRef: MatDialogRef<MembershipDialog>,
    @Inject(MAT_DIALOG_DATA) public data: MatDialog
  ) {
    this.membershipData = data;
  }
  ngOnInit() {}
  closeDialog(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: "dialog-content-example-dialog",
  templateUrl: "dialog-content-example-dialog.html",
  styleUrls: ["./account.component.css"],
})
export class DialogContentExampleDialog {}
