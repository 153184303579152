<section class="page-wrapper">
	<section class="page-banner">
		<h1>Villas Near Mumbai</h1>
	</section>
	<section class="about-page page-section">		
		<div class="container">	            
			<div class="Properties property-list aboutDetail">								
				<div class="row">
					<div class="col-md-4 bsp_padding-0" *ngFor="let property of locationsNearbyData">
						
						<div class="bsp_bbb_item" 
							[ngClass]="{'gold-bg':property.gold == 'on','luxe-bg':property.luxe == 'on', 'display-none': property.status == 0}">
							
							<div class="image-box">
							
								<div class="badges" *ngIf="property.gold !=null || property.luxe !=null">
									<span class="badge alert-primary" *ngIf="property.gold == 'on'">EKO GOLD</span>
									<span class="badge alert-success" *ngIf="property.luxe == 'on'">EKO LUXE</span>
								</div>
								<owl-carousel-o [options]="customOptions" *ngIf="property.property_media.length !=0">
									<ng-container *ngFor="let pmedia of property.property_media  index as i">
										<ng-container *ngIf="i == 0">
											<ng-template carouselSlide [id]="pmedia.id">
												<div class="slide">
													<div *ngIf="property?.booked == true">
														<div class="old-ribbon">Booked</div>
													</div>
													<a routerLink="property/location/villas-in-{{property.locname}}/{{property.permalink}}" class="bsp_a">
														<img src="{{imagePropertySrc}}{{pmedia.media}}" alt="">
													</a>
												</div>
											</ng-template>
										</ng-container>
										<ng-container *ngIf="i>0">
											<ng-template carouselSlide [id]="pmedia.id">
												<div class="slide">
													<div *ngIf="property?.booked == true">
														<div class="old-ribbon">Booked</div>
													</div>
													<a routerLink="property/location/villas-in-{{property.locname}}/{{property.permalink}}" loading="lazy"
														class="bsp_a">
														<img src="{{imagePropertySrc}}{{pmedia.media}}" loading="lazy"
															alt="">
													</a>
												</div>
											</ng-template>
										</ng-container>
									</ng-container>
								</owl-carousel-o>
								<a routerLink="property/location/villas-in-{{property.locname}}/{{property.permalink}}" class="bsp_a"
									*ngIf="property.property_media.length ==0">
								</a>
							</div>
							
						
							<a routerLink="property/location/villas-in-{{property.locname}}/{{property.permalink}}" class="bsp_a">
								<div class="detail-desc">								
									<h5 *ngIf="property.status == 0" class="bsp_card-title inactive-title">
										<span>Currently Inactive</span>
									</h5>
									<div class="text-left p-10">
										
										<div >							
									
												
											<p class="bsp_card-text">{{property.property_name}}</p>	
												
																				
										</div>		
											<p class="loct">										
												<a href="#" class="more-details"><i class="fa fa-map-marker"
														aria-hidden="true"></i>
													{{property.locname}}</a>
											</p>
											
											<ul class="roominn">										
												
												<li class="first-desc"><i aria-hidden="true" class="fa fa-bed"></i> {{property.bed}} BHK</li>
											
												
												<li class="second-desc"><i class="fa fa-bath" aria-hidden="true"></i> {{property.bathrooms}}
												Bathrooms</li>
											
												
												<li class="third-desc"><i aria-hidden="true" class="fa fa-users"></i> Max {{property.member}} Guest
												</li>
												
											</ul>
												
									</div>
										<ng-container>
											<h5 class="bsp_card-title">
												
												<span class="price-desc" *ngIf="numberOfNightss == 1"> ₹ {{property.inputname_monday * 1 | number:
													'2.'}} / </span>
									
												<s class="straight" *ngIf="numberOfNightss == 1">₹{{(property.inputname_monday * (1 + 0.35)) *
													numberOfNightss | number: '2.'}}</s>
									
									
									
									
												<span class="price-desc" *ngIf="numberOfNightss > 1"> ₹ {{property.inputname_monday * numberOfNightss |
													number: '2.'}} / </span>
									
												<s class="straight" *ngIf="numberOfNightss > 1">₹{{(property.inputname_monday * (1 + 0.35)) *
													numberOfNightss | number: '2.'}}</s>           
									
									
												<span class="price-desc" *ngIf="numberOfNightss == 0"> ₹ {{property.inputname_monday | number: '2.'}} /
												</span>
									
												<s class="straight" *ngIf="numberOfNightss == 0">₹{{property.inputname_monday * (1 + 0.35) | number:
													'2.'}}</s>
									
									
												<span class="night-desc" *ngIf="numberOfNightss == 1">For {{numberOfNightss}} Night </span>
									
									
									
												<span class="night-desc" *ngIf="numberOfNightss > 1">For {{numberOfNightss}} Nights </span>
									
									
									
												<span class="night-desc" *ngIf="numberOfNightss == 0">Night Onwards</span>
											</h5>
										</ng-container>
									
									
								</div>
							</a>
						</div>
					</div>
				</div>

			
			</div>
			<ngx-spinner bdColor="rgba(49, 15, 72, 0.8)" size = "medium" type = "ball-grid-beat" [fullScreen] = "true">  
				<p style="color: #fff; z-index: 999999;">Loading Your Staycation</p>  
			</ngx-spinner>
		</div>	
	</section>
</section>