import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

@Component({
  selector: "app-partner-with-contactus-dialog",
  templateUrl: "./partner-with-contactus-dialog.component.html",
  styleUrls: ["./partner-with-contactus-dialog.component.css"],
})
export class PartnerWithContactsDialogComponent implements OnInit {
  public showError: boolean = false;
  partnerForm: FormGroup;
  public showStatus: boolean = false;

  constructor() {
    this.partnerForm = new UntypedFormGroup({
      name: new UntypedFormControl("", Validators.required),
      phone: new UntypedFormControl(),
      email: new UntypedFormControl("", Validators.required),
      location: new UntypedFormControl(),
      area: new UntypedFormControl(),
      areaSize: new UntypedFormControl(),
      bedrooms: new UntypedFormControl(),
      bathrooms: new UntypedFormControl(),
      sp: new UntypedFormControl(),
      caretaker: new UntypedFormControl(),
      condition: new UntypedFormControl(),
    });
  }

  ngOnInit(): void {}
  submitContactForm() {
    var me = this;
    if (this.partnerForm.valid) {
      me.showError = false;
      me.showStatus = true;
  
    } else {
      me.showError = true;
    }
  }
}
