import { Component, OnInit } from '@angular/core';
import { aboutservice } from '../../services/aboutservice';
import { locationservice } from '../../services/locationservice';
import { environment } from '../../../environments/environment';
import {Router, ActivatedRoute } from '@angular/router';
import { Title,Meta } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-original',
  templateUrl: './original.component.html',
  styleUrls: ['./original.component.css']
})
export class OriginalComponent implements OnInit {

  imageSrc = 'https://'+environment.domain+'/public/uploads/images/';
  imagePropertySrc = 'https://'+environment.domain+'/public/property_images/';
  aboutData: any = [];
  locationData: any = [];
  categoryData: any = [];
  locationDatas: any = [];
  locationsNearbyData: any = [];
  propertyName = '';
  state = '';
  checkin = '';
  checkout = '';
  location = '';
  type = '';
  guest = '';
  party_friendly = '';
  budget_friendly = '';
  newly_villas = '';
  group_villas = '';
  offbeat_villas = '';

  constructor(public aboutservice: aboutservice,public locationservice: locationservice,private activatedRoute: ActivatedRoute, private router: Router,private metaService: Meta,private title: Title) {
    let state = this.activatedRoute.snapshot.params.state;
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.state = params['state'] || '';
        var location = params['location'] || '';
        this.propertyName = params['propertyName'] || '';
        this.guest = params['guest'] || '';
        this.checkin = params['checkin'] || '';
        this.checkout = params['checkout'] || '';
        this.location = params['location'] || '';
        this.type = params['type'] || '';
        this.party_friendly = params['party_friendly'] || '';
        this.budget_friendly = params['budget_friendly'] || '';
        this.newly_villas = params['newly_villas'] || '';
        this.group_villas = params['group_villas'] || '';
        this.offbeat_villas = params['offbeat_villas'] || '';
        
        //call method here based upon values to get filtered list.
        aboutservice.getOriginal(this.location,this.checkin,this.checkout, this.guest).subscribe((response:any) =>
        {
         this.aboutData = response['data']['properties'];
        });

        aboutservice.getLocationsNearby(this.location,this.checkin,this.checkout).subscribe((response:any) =>
        {
          this.locationsNearbyData = response['data']['properties'];
        });
      });
    
      this.title.setTitle('Ekostay - Original Category Villas');
      this.metaService.addTag({ name: 'description', content: 'Original properties are a new offering by EKOSTAY designed for a luxurious and premium staycation' });

      locationservice.getLocations().subscribe((response:any) =>
      {
        this.locationData = response['data'];
      });

      locationservice.getLocationsd().subscribe((response:any) =>
      {
        this.locationDatas = response['data'];
      });

      aboutservice.getCategories().subscribe((response:any) =>
      {
        this.categoryData = response['data'];
      });

     

      
   	}

  customOptions: OwlOptions = {
  loop: false,
  mouseDrag: false,
  touchDrag: true,
  pullDrag: true,
  dots: false,
  navSpeed: 700,
  navText: ['<', '>'],
  responsive: {
    0:{
      items:1
    }
  },
  nav: true
}

  ngOnInit(): void {
  }

  filterProperties(){
    this.router.navigate(['/original'], { queryParams: {location: this.location } });
    window.scrollTo(0, 200)
  }

}
