<section class="page-wrapper">
	<section class="page-banner">
		<h1>Careers</h1>
	</section>
</section>

<section class="section-career">
	<div class="example-container">
		<div class="row">
			<div class="container containers">
				<h2>Please Provide Your details</h2>
				<ul>
					<li >
						<mat-form-field appearance="legacy">
							<mat-label>Full Name</mat-label>
							<input matInput type="text" placeholder="Full Name" [formControl]="name" required>
							<mat-icon matSuffix></mat-icon>
							<mat-error *ngIf="name.invalid">{{getErrorMessageName()}}</mat-error>
						</mat-form-field>
					</li>
					<li >
						<mat-form-field appearance="legacy">
							<mat-label>Phone Number</mat-label>
							<input matInput type="number" placeholder="Phone Number" [formControl]="phone" required>
							<mat-icon matSuffix></mat-icon>
							<mat-error *ngIf="phone.invalid">{{getErrorMessagePhoneNumber()}}</mat-error>
						</mat-form-field>
					</li>
					<li >
						<mat-form-field appearance="fill">
						<mat-label>Enter your email</mat-label>
						<input matInput placeholder="pat@example.com" [formControl]="email" required>
						<mat-icon matSuffix></mat-icon>
						<mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
						</mat-form-field>	
					</li>
				</ul>
			</div>
		</div>			
	</div>	

</section>


