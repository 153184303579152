<main class="page_wrapper">
	<section class="membership_wrapper">
		<div class="container">
			<div class="heading">
				<h1 class="title" data-aos="fade-up" data-aos-duration="1000">Ekostay Membership</h1>
			</div>
			<div class="row desktop_membership">
				<div class="col-sm-4" data-aos="fade-up" data-aos-duration="1000">
					<div class="membership_card">
						<h2 class="title">Original</h2>
						<h4 class="price"><span style="text-decoration: line-through;">₹ 3,499</span> &nbsp;₹ 1,199</h4>

						<ul class="featured_list">
							<li><i class="fa fa-check vill-detail-btn-icon"></i> Get a Flat discount of ₹500/- on every stay
								</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> Free 1 night stay on your 8th stay (Original Category)
								</li>
							<!-- <li><i class="fa fa-check vill-detail-btn-icon"></i> 10% off on Food & Beverages order (Maximum discount - ₹1500/-)
								</li> -->
							<li><i class="fa fa-check vill-detail-btn-icon"></i> You can add 1 buddy in your membership.
								</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> Access to exclusive last minute deals</li>
						</ul>

						<a href="https://pages.razorpay.com/membershipekostay" class="membership_ctr" style="margin-top: 65px !important;">Get Membership</a>

					</div>
				</div>

				<div class="col-sm-4" data-aos="fade-up" data-aos-duration="1000">
					<div class="membership_card">
						<h2 class="title">Membership Plus</h2>
						<h4 class="price"><span style="text-decoration: line-through;">₹ 4,999</span>&nbsp;₹ 2,499</h4>

						<ul class="featured_list">
							<li><i class="fa fa-check vill-detail-btn-icon"></i> ₹1000 off on first stay
								</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> ₹1500 off next consecutive stays
								</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> Free 1 night stay on 6th stay (Original Category)
								</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> 10% off on Food & Beverages order on their First stay ( Maximum discount - ₹1500/-)
								</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> You can add 1 buddy in your membership
								</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> 12 months plus 3 months free
								</li>
								<li><i class="fa fa-check vill-detail-btn-icon"></i> Access to exclusive last minute deals</li>
						</ul>

						<a href="https://rzp.io/l/v62aGVfr" class="membership_ctr" style="margin-top: 65px !important;">Get Membership</a>

					</div>
				</div>

				<div class="col-sm-4" data-aos="fade-up" data-aos-duration="1000">
					<div class="membership_card">
						<h2 class="title">Membership Max</h2>
						<h4 class="price"><span style="text-decoration: line-through;">₹ 7,999</span>&nbsp;₹4,999</h4>
						<ul class="featured_list">
							<li><i class="fa fa-check vill-detail-btn-icon"></i> ₹1500 off on Orignal</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> ₹2000 off on Gold</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> ₹2500 off on Luxe</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i>
								Free 1 night stay on 4th stay (Original Category). Upgrade at Gold category at ₹2000 extra.
								</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> Free 1 night stay on 8th stay (Luxe Category)
								</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> 10% off on Food & Beverages order on their First stay ( Maximum discount - ₹1500/-)
								</li>
								<li><i class="fa fa-check vill-detail-btn-icon"></i>
									12 months plus 3 months free</li>
									<li><i class="fa fa-check vill-detail-btn-icon"></i>
										You can add 1 buddy in your membership.</li>
										<li><i class="fa fa-check vill-detail-btn-icon"></i> 
											Access to exclusive last minute deals</li>
						</ul>

						<a href="https://rzp.io/l/AekSFzBb" class="membership_ctr">Get Membership</a>

					</div>
				</div>
			</div>

			<div class="mb_membership">
				<cdk-accordion class="example-accordion membership_original" data-aos="fade-up" data-aos-duration="1000">
					<cdk-accordion-item
					  #accordionItem1="cdkAccordionItem"
					  class="example-accordion-item"
					  role="button"
					  tabindex="0"
					  [attr.id]="'accordion-header-' + 1"
					  [attr.aria-expanded]="accordionItem1.expanded"
					  [attr.aria-controls]="'accordion-body-' + 1">
					  <div class="example-accordion-item-header" (click)="accordionItem1.toggle()">

						<span class="example-accordion-item-description accordion_arrow">
							Original <span class="price"><span style="text-decoration: line-through;">₹ 3,499</span> &nbsp;₹ 1,199</span>   <mat-icon>{{accordionItem1.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
						</span>
					  </div>
					  <div
						class="example-accordion-item-body"
						role="region"
						[style.display]="accordionItem1.expanded ? '' : 'none'"
						[attr.id]="'accordion-body-' + 1"
						[attr.aria-labelledby]="'accordion-header-' + 1">
						
						<div>
							<ul class="featured_list">
								<li><i class="fa fa-check vill-detail-btn-icon"></i> Get a Flat discount of ₹500/- on every stay
								</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> Free 1 night stay on your 8th stay (Original Category)
								</li>
							<!-- <li><i class="fa fa-check vill-detail-btn-icon"></i> 10% off on Food & Beverages order (Maximum discount - ₹1500/-)
								</li> -->
							<li><i class="fa fa-check vill-detail-btn-icon"></i> You can add 1 buddy in your membership.
								</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> Access to exclusive last minute deals</li>
							</ul>
	
							<a href="https://pages.razorpay.com/membershipekostay" class="membership_ctr">Get Membership</a>
						</div>

					  </div>
					</cdk-accordion-item>
				  </cdk-accordion>

				  <cdk-accordion class="example-accordion gradient-border" data-aos="fade-up" data-aos-duration="1000">
					<cdk-accordion-item
					  #accordionItem2="cdkAccordionItem"
					  class="example-accordion-item"
					  role="button"
					  tabindex="0"
					  [attr.id]="'accordion-header-' + 1"
					  [attr.aria-expanded]="accordionItem2.expanded"
					  [attr.aria-controls]="'accordion-body-' + 1">
					  <div class="example-accordion-item-header" (click)="accordionItem2.toggle()">

						<span class="example-accordion-item-description accordion_arrow">
							Membership Plus <span class="price"><span style="text-decoration: line-through;">₹ 4,999</span> &nbsp;₹ 2,499</span> <mat-icon>{{accordionItem2.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
						</span>
					  </div>
					  <div
						class="example-accordion-item-body"
						role="region"
						[style.display]="accordionItem2.expanded ? '' : 'none'"
						[attr.id]="'accordion-body-' + 1"
						[attr.aria-labelledby]="'accordion-header-' + 1">
						
						<div>
							<ul class="featured_list">
								<li><i class="fa fa-check vill-detail-btn-icon"></i> ₹1000 off on first stay
								</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> ₹1500 off next consecutive stays
								</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> Free 1 night stay on 6th stay (Original Category)
								</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> 10% off on Food & Beverages order on their First stay ( Maximum discount - ₹1500/-)
								</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> You can add 1 buddy in your membership
								</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> 12 months plus 3 months free
								</li>
								<li><i class="fa fa-check vill-detail-btn-icon"></i> Access to exclusive last minute deals</li>
							</ul>
	
							<a href="https://rzp.io/l/v62aGVfr" class="membership_ctr">Get Membership</a>
						</div>

					  </div>
					</cdk-accordion-item>
				  </cdk-accordion>


				  <cdk-accordion class="example-accordion membership_mex" data-aos="fade-up" data-aos-duration="1000">
					<cdk-accordion-item
					  #accordionItem3="cdkAccordionItem"
					  class="example-accordion-item"
					  role="button"
					  tabindex="0"
					  [attr.id]="'accordion-header-' + 1"
					  [attr.aria-expanded]="accordionItem3.expanded"
					  [attr.aria-controls]="'accordion-body-' + 1">
					  <div class="example-accordion-item-header" (click)="accordionItem3.toggle()">

						<span class="example-accordion-item-description accordion_arrow">
							MEMBERSHIP MAX <span class="price"><span style="text-decoration: line-through;">₹ 7,999</span> &nbsp;₹ 4,999</span> <mat-icon>{{accordionItem3.expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
						</span>
					  </div>
					  <div
						class="example-accordion-item-body"
						role="region"
						[style.display]="accordionItem3.expanded ? '' : 'none'"
						[attr.id]="'accordion-body-' + 1"
						[attr.aria-labelledby]="'accordion-header-' + 1">
						
						<div>
							<ul class="featured_list">
								<li><i class="fa fa-check vill-detail-btn-icon"></i> ₹1500 off on Orignal</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> ₹2000 off on Gold</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> ₹2500 off on Luxe</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i>
								Free 1 night stay on 4th stay (Original Category). Upgrade at Gold category at ₹2000 extra.
								</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> Free 1 night stay on 8th stay (Luxe Category)
								</li>
							<li><i class="fa fa-check vill-detail-btn-icon"></i> 10% off on Food & Beverages order on their First stay ( Maximum discount - ₹1500/-)
								</li>
								<li><i class="fa fa-check vill-detail-btn-icon"></i>
									12 months plus 3 months free</li>
									<li><i class="fa fa-check vill-detail-btn-icon"></i>
										You can add 1 buddy in your membership.</li>
										<li><i class="fa fa-check vill-detail-btn-icon"></i> 
											Access to exclusive last minute deals</li>
							</ul>
	
							<a href="https://rzp.io/l/AekSFzBb" class="membership_ctr">Get Membership</a>
						</div>

					  </div>
					</cdk-accordion-item>
				  </cdk-accordion>

			</div>


		</div>
	</section>

	<section>
		<div class="oofering-sec">
			<div class="container">
			<div class="row heading">
			 <div class="col-md-12">
			 <h2 class="title" data-aos="fade-up" data-aos-duration="1000">Ekostay Offerings</h2>
			 </div>
			</div>
			
			<div class="row">
			 <div class="col-md-4" data-aos="fade-up" data-aos-duration="1000">
			 <div class="gold-image"> 
			 <img src="assets/img/standredimg.jpg" alt="" style="width: 100%;">
			 <h4>Original</h4>
			 <p>Designed to provide affordable homestays
			  with best value for money deals. Ideal for a
			  spontaneous, fun getaway.</p>
			 </div>
			</div>	   
			 
			 <div class="col-md-4" data-aos="fade-up" data-aos-duration="1000">	   
			 <div class="gold-image">
			 <img src="assets/img/goldimg.jpg" alt="" style="width: 100%;">
			 <h4>Gold</h4>
			 <p>Designed for a luxurious and premium staycation.
			  A gold category gives homely vibes as well as
			  matches the standards of a luxury hotel.</p>
			 </div>
			 </div>
	  
			 <div class="col-md-4" data-aos="fade-up" data-aos-duration="1000">	   
			 <div class="gold-image">
			 <img src="assets/img/luxe-property.jpg" alt="" style="width: 100%;">
			 <h4>Luxe</h4>
			 <p>EKO LUXE covers the most exclusive and elite accommodations of villas across India while introducing one Luxe property every month exclusively to our portfolio. With the commitment of decadence, privacy and opulence, every EKO LUXE Home is luxury and comfort, guaranteed!</p>
			 </div>
			 </div>
	  
	  
	  
			</div>
			</div>
			</div>
	</section>


<!-- --------------------STANDARD AMENITIES---------------- -->

<section class="amenities_sec">
	<div class="container">
	  <div class="row">
		<div class="col-sm-12">
		  <div class="amenities_cont" data-aos="fade-right" data-aos-duration="1000">
			<h2 class="title">Standard Amenities</h2>
		  </div>
  
		  <div class="row amenities_serv">
			<div class="col-lg-3 col-md-6 col-xs-6" data-aos="fade-up" data-aos-duration="1050">
			  <div class="item">
				<img src="../../../assets/img/dwifi.png">
				<div class="title">FREE WIFI</div>
			  </div>
			</div>
			<div class="col-lg-3 col-md-6 col-xs-6" data-aos="fade-up" data-aos-duration="1200">
			  <div class="item">
				<img src="../../../assets/img/dbarbeque.png">
				<div class="title">BARBEQUE PROVISION</div>
			  </div>
			</div>
			<div class="col-lg-3 col-md-6 col-xs-6" data-aos="fade-up" data-aos-duration="1250">
			  <div class="item">
				<img src="../../../assets/img/dcaretaker.png">
				<div class="title">CARETAKER SERVICE</div>
			  </div>
			</div>
			<div class="col-lg-3 col-md-6 col-xs-6" data-aos="fade-up" data-aos-duration="1300">
			  <div class="item">
				<img src="../../../assets/img/d-kitchen-icon.png">
				<div class="title">Kitchen</div>
			  </div>
			</div>
		  </div>
  
		  <div class="row amenities_serv">
			<div class="col-lg-3 col-md-6 col-xs-6" data-aos="fade-up" data-aos-duration="1350">
			  <div class="item">
				<img src="../../../assets/img/d-list-icon.png">
				<div class="title">REGULAR INSPECTION</div>
			  </div>
			</div>
			<div class="col-lg-3 col-md-6 col-xs-6" data-aos="fade-up" data-aos-duration="1400">
			  <div class="item">
				<img src="../../../assets/img/d-clean.png">
				<div class="title">CLEAN LINEN</div>
			  </div>
			</div>
			<div class="col-lg-3 col-md-6 col-xs-6" data-aos="fade-up" data-aos-duration="1450">
			  <div class="item">
				<img src="../../../assets/img/d-music-icon.png">
				<div class="title">MUSIC SYSTEM</div>
			  </div>
			</div>
			<div class="col-lg-3 col-md-6 col-xs-6" data-aos="fade-up" data-aos-duration="1500">
			  <div class="item">
				<img src="../../../assets/img/d-manager.png">
				<div class="title">DEDICATED HOST MANAGER</div>
			  </div>
			</div>
		  </div>
		</div>
  
	  </div>
	</div>
  </section>
  
  <!-- --------------------STANDARD AMENITIES---------------- -->


  <div class="counter-sec">
	<div class="container">
	<div class="row heading">
	 <div class="col-md-12">
	 <h2 class="title" data-aos="fade-up" data-aos-duration="1500">Ekostay</h2>
	 <p class="subtitle" data-aos="fade-up" data-aos-duration="1500">Your Most Safe & Trusted Homestay Brand.</p>
	 </div>
	</div>
	
				   <div class="row">
				  <div class="main_counter_content text-center white-text wow fadeInUp">
					  <div class="col-md-4" data-aos="fade-up" data-aos-duration="1500">
						  <div class="single_counter p-y-2 m-t-1">
							<i class='fa fa-building icon'></i>
							  <h2 class="statistic-counter">40+</h2> <span></span>
							  <p>Cities</p>
						  </div>
					  </div>
					  <div class="col-md-4" data-aos="fade-up" data-aos-duration="1500">
						  <div class="single_counter two p-y-2 m-t-1"> 
							<i class='fa fa-home icon'></i>
							  <h2 class="statistic-counter">200+</h2>
							  <p>Properties</p>
						  </div>
					  </div>
					  <div class="col-md-4" data-aos="fade-up" data-aos-duration="1500">
						  <div class="single_counter p-y-2 m-t-1"> 
							<i class='fa fa-users icon'></i>
							  <h2 class="statistic-counter">20,80,000</h2>
							  <p>Guests </p>
						  </div>
					  </div>
				  </div>
			  </div>
	</div>
  </div>

</main>






  
  