import { Component, EventEmitter, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject } from "rxjs";
import { aboutservice } from "src/app/services/aboutservice";

@Component({
  selector: "app-goldLuxe-dialog",
  template: ` <div class="row d-flex close_btn">
      <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="filter_btn_wrapper">
      <div class="btn_item" *ngFor="let button of buttonName">
        <button
          class="diolog-bttn"
          [class.diolog-bttn1]="button.name === 'Price'"
          (click)="onClick(button.name)"
          [class.filter_btn_active]="viewMode == button.name"
        >
          <ng-container *ngIf="button.name == 'Top Filters'">
            <div
              [hidden]="selectedCheckboxCount === 0"
              matBadge="{{ selectedCheckboxCount }}"
              matBadgePosition="after"
              matBadgeColor="accent"
            >
              {{ button.name }}
            </div>
            <div *ngIf="selectedCheckboxCount === 0">
              {{ button.name }}
            </div>
          </ng-container>
          <ng-container *ngIf="button.name == 'Price'">
            {{ button.name }}
          </ng-container>
        </button>
      </div>
    </div>

    <div [ngSwitch]="viewMode">
      <div *ngSwitchCase="'Price'">
        <div class="price_range">
          <div class="price_item">
            <div class="price_tag">₹</div>
            <input
              type="text"
              [(ngModel)]="minPrice"
              placeholder="Min Price For Stay"
            />
          </div>

          <div class="price_item">
            <div class="price_tag">₹</div>
            <input
              type="text"
              [(ngModel)]="maxPrice"
              placeholder="Max Price For Stay"
            />
          </div>

          <div class="apply_ctr">
            <button (click)="onApplyFilter(viewMode)">Apply</button>
          </div>
        </div>
      </div>

     

      <div *ngSwitchCase="'Top Filters'">
        <div class="row" style="padding:20px" *ngIf="isShow == true">
          <div class="top-filter">
            <p>No. of Bedrooms</p>
            <div class="counter">
              <button class="counter-bttn" (click)="incrementCounter()">
                +
              </button>
              <div class="counter-value">{{ counterValue }}</div>
              <button class="counter-bttn" (click)="decrementCounter()">
                -
              </button>
            </div>
          </div>
          <div class="top-filter">
            <p>No. of Bathrooms</p>
            <div class="counter">
              <button
                class="counter-bttn"
                (click)="incrementCounterBathrooms()"
              >
                +
              </button>
              <div class="counter-value">{{ bathroomsCounterValue }}</div>
              <button
                class="counter-bttn"
                (click)="decrementCounterBathrooms()"
              >
                -
              </button>
            </div>
          </div>
          <div>
            <ul class="filter_featured_list">
              <li *ngFor="let data of topFilterData">
                <label class="material-checkbox">
                  <input
                    type="checkbox"
                    [(ngModel)]="data.selected"
                    (change)="(selectedCheckboxCount)"
                  />
                  <span class="checkmark"></span>
                  {{ data.name }}
                </label>
              </li>
            </ul>

            <div class="apply_ctr">
              <button (click)="onApplyFilter(viewMode)">Apply</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </form> -->
    <div></div>`,
  styleUrls: ["./gold.component.css"],
})
export class GoldLuxeDialogComponent {
  @Output() selectedTopFilterCountChange = new EventEmitter<number>();
  isShow: boolean = false;
  btnName: any;
  viewMode: any = "Price";
  counterValue = 0;
  bathroomsCounterValue = 0;
  filterForm: FormGroup;
  minPrice: 0;
  maxPrice: 0;
  ekoOriginal: boolean = true;
  ekoGold: boolean = false;
  ekoLuxe: boolean = false;
  filterData: any;

  constructor(
    public dialogRef: MatDialogRef<GoldLuxeDialogComponent>,
    private aboutService: aboutservice
  ) {}

  buttonName = [
    {
      name: "Price",
    },
    {
      name: "Top Filters",
    },
  ];

  topFilterData: any[] = [
    {
      name: "Chef Service ",
      selected: false, // Add a selected property to track the checkbox state
    },
    {
      name: "Pet Friendly",
      selected: false,
    },
    {
      name: "Best Rated",
      selected: false,
    },
    {
      name: "Offbeat",
      selected: false,
    },
    {
      name: "Newly Launched",
      selected: false,
    },
    {
      name: "Family Friendly",
      selected: false,
    },
    {
      name: "Budget Friendly",
      selected: false,
    },
   
  ];


  increment=0;
  increment1=0
  ngOnInit() {}

  /* <-------------- mat badge -----------------> */
  get selectedCheckboxCount(): number {
    if(this.counterValue > 0){
this.increment = 1
    } 
    if(this.bathroomsCounterValue > 0){
      this.increment1 = 1
          }
    return (
      this.topFilterData.filter((item) => item.selected).length +
      this.increment +
      this.increment1
    );
  }

  onClick(name: any) {
    this.isShow = true;
    this.btnName = name;
    this.viewMode = name;
  }

  incrementCounter() {
    this.counterValue++;
  }

  decrementCounter() {
    if (this.counterValue > 0) {
      this.counterValue--;
    }
  }

  incrementCounterBathrooms() {
    this.bathroomsCounterValue++;
  }

  decrementCounterBathrooms() {
    if (this.bathroomsCounterValue > 0) {
      this.bathroomsCounterValue--;
    }
  }

 

 

  onApplyFilter(name: string): void {
    

    if (name === "Price") {
      this.dialogRef.close();
      this.viewMode = "Eko Category";
    } else if (name === "Eko Category") {
      this.dialogRef.close();
      this.viewMode = "Top Filters";
      this.isShow = true;
    } else if (name === "Top Filters") {
      this.dialogRef.close();
    }

    const selectedTopFilters = this.topFilterData.filter(
      (item) => item.selected
    );

    let filterCriteria: any;

    switch (name) {
      case "Price":
        filterCriteria = {
          minPrice: this.minPrice,
          maxPrice: this.maxPrice,
        };
        break;
      case "Top Filters":
        filterCriteria = {
          bedroom: this.counterValue,
          bathroom: this.bathroomsCounterValue,
          topFilterData: selectedTopFilters,
        };
        break;
      // Add other cases as needed

      default:
    }

    // Assign the filterCriteria to this.filterData
    this.filterData = filterCriteria;

    // Additional code if needed based on the filterData

    this.aboutService.goldLuxeDataBehaviour.next(this.filterData);

    this.selectedTopFilterCountChange.emit(this.selectedCheckboxCount);
  }
}
