import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-keep-updated',
  templateUrl: './keep-updated.component.html',
  styleUrls: ['./keep-updated.component.css']
})
export class KeepUpdatedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
