
    <section id="portfolio">
        <div class="content-wrapper">
            <div class="inner-container container">
                <div class="row">
                    <div class="col-md-4 col-sm-12">
                        <div class="section-heading">
                            <h4>Our Gallery</h4>
                            <div class="line-dec"></div>
                            <p>In malesuada placerat ligula et bibendum. Aenean eget urna enim. Sed enim ante, bibendum nec dictum ac, gravida ac lectus.</p>
                            <div class="filter-categories">
                                <ul class="project-filter">
                                    <li class="filter" data-filter="all"><span>Show All</span></li>
                                    <li class="filter" data-filter="branding"><span>Branding</span></li>
                                    <li class="filter" data-filter="graphic"><span>Graphic</span></li>
                                    <li class="filter" data-filter="nature"><span>Nature</span></li>
                                    <li class="filter" data-filter="animation"><span>Animation</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="projects-holder-3">
                            <div class="projects-holder">
                                <div class="row">
                                    <div class="col-md-6 col-sm-6 project-item mix nature">
                                      <div class="thumb">
                                            <div class="image">
                                                <a href="assets/img/portfolio_big_item_01.jpg" data-lightbox="image-1"><img src="assets/img/portfolio_item_01.jpg"></a>
                                            </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 project-item mix animation">
                                      <div class="thumb">
                                        <div class="image">
                                                <a href="assets/img/portfolio_big_item_02.jpg" data-lightbox="image-1"><img src="assets/img/portfolio_item_02.jpg"></a>
                                            </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 project-item mix branding">
                                      <div class="thumb">
                                            <div class="image">
                                                <a href="assets/img/portfolio_big_item_03.jpg" data-lightbox="image-1"><img src="assets/img/portfolio_item_03.jpg"></a>
                                            </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 project-item mix graphic nature">
                                        <div class="thumb">
                                            <div class="image">
                                                <a href="assets/img/portfolio_big_item_04.jpg" data-lightbox="image-1"><img src="assets/img/portfolio_item_04.jpg"></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 project-item mix branding">
                                      <div class="thumb">
                                            <div class="image">
                                                <a href="assets/img/portfolio_big_item_05.jpg" data-lightbox="image-1"><img src="assets/img/portfolio_item_05.jpg"></a>
                                            </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 project-item mix graphic animation">
                                        <div class="thumb">
                                            <div class="image">
                                                <a href="assets/img/portfolio_big_item_06.jpg" data-lightbox="image-1"><img src="assets/img/portfolio_item_06.jpg"></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </section>
