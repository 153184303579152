import { Component, OnInit } from '@angular/core';
import { Title,Meta, MetaDefinition } from '@angular/platform-browser';
import {UntypedFormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {

  iflogin: boolean = false;
  ifnotlogin: boolean = true;

  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  name = new UntypedFormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(20)]);
  phone = new UntypedFormControl('', [Validators.required,Validators.pattern("[0-9 ]{10}")]);

  getErrorMessage() {
    if (this.email.hasError('required')) {
      return 'You must enter a Email';
    }

    return this.email.hasError('email') ? 'Not a valid email' : '';
    
  }

  getErrorMessageName() {
    if (this.name.hasError('required')) {
      return 'You must enter a Name';
    }

    return this.name.hasError('name') ? 'Not a valid name' : '';
  }

  getErrorMessagePhoneNumber() {
    if (this.phone.hasError('required')) {
      return 'You must enter a Phone Number';
    }

    return this.phone.hasError('phone') ? 'Not a valid phone' : '';
  
  }

  constructor(private metaService: Meta,private title: Title) { 
    this.title.setTitle('Ekostay - Career');
     this.metaService.addTag({ name: 'description', content: 'Ekostay - Career' });

  }

  ngOnInit(): void {
  }

}
