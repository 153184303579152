<footer>
  <div class="container">
    <div class="row top">
      <div class="col-md-5 col-xs-12">
        <div class="logo">
          <a class="logo-ft scroll-top" href="#"
            ><img
              style="height: 30px; width: 46%"
              src="assets/img/logo.png"
              alt=""
              loading="lazy"
          /></a>
          <div
            class="newsletter_heading font_style_head"
            style="margin-top: 20px"
          >
            <h4>NEVER MISS A MOMENT</h4>
          </div>

          <div class="follow_us">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/ekostayindia?mibextid=JRoKGi"
                  target="_blank"
                  ><i class="fa fa-facebook"></i
                ></a>
              </li>
              <li>
                <a href="https://www.instagram.com/ekostay?igsh=MWtkY2FodTFxenh1&utm_source=qr" target="_blank"
                  ><i class="fa fa-instagram"></i
                ></a>
              </li>
              <li>
                <a
                  href="https://youtube.com/@ekostay?feature=shared"
                  target="_blank"
                  ><i class="fa fa-youtube"></i
                ></a>
              </li>
              <li></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-xs-12">
        <div class="location font_style_head">
          <h4>Quick Links</h4>
          <ul>
            <li>
              <a routerLink="/partner-with-us" class="font_style_title"
                >Partner with Us
              </a>
            </li>
            <li>
              <a routerLink="/faqs" data-id="faqs" class="font_style_title"
                >FAQ's</a
              >
            </li>
            <li>
              <a
                routerLink="/privacypolicy"
                data-id="privacy-policy"
                class="font_style_title"
                >Privacy Policy</a
              >
            </li>
            <li>
              <a routerLink="/terms" data-id="terms" class="font_style_title"
                >Terms &amp; Conditions</a
              >
            </li>
            <li>
              <a
                href="https://ekostay.com/blogs/"
                class="font_style_title"
                >Blogs</a
              >
            </li>
            <li>
              <a
                routerLink="/contact"
                data-id="contact-us"
                class="font_style_title"
                >Contact Us</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="col-md-4 col-xs-12">
        <div class="connect-us font_style_head">
          <h4>Contact</h4>
          <!-- <p class="footer-location" class="font_style_title">
            EKOSTAY LLP, Kaliandas Udyog Bhavan, Prabhadevi, Worli,
            Mumbai-400025
          </p> -->
          <p class="footer-call">
            <!-- <a href="tel:9833078888" class="font_style_title">+91-9833078888</a> -->
            <a href="tel:8169019090" class="font_style_title"
              >+91-81690 19090</a
            >
          </p>
          <p class="footer-mail font_style_title">reservations@ekostay.com</p>
        </div>
      </div>
    </div>

    <!-- ------------------Popular Destination----------------- -->

    <div class="whtsicon">
      <a [href]="'https://wa.me/+91'+ contactNo +'?text='"
        ><img src="assets/img/whatsapp.png" alt="" (click)="onWhatsapp()" loading="lazy"/>
      </a>
    </div>
  </div>

  <div class=" ">
    <div class="">
      <div class="copyright">
        <p class="font_style_title">
          Copyright © {{ newyear }} EkoStay | All rights reserved.
        </p>
      </div>
    </div>
  </div>
</footer>
