

<div class="conatiner  desk-slide"  *ngIf="isMobileResolution==true">
        <div class="row" id="row1">
                <div class="col-md" id="col1">
                        <h2 class="share"> Share </h2>
                </div>
                <div class="col-md" id="col2" >
                        <mat-icon style="cursor: pointer;" mat-button matRipple  mat-dialog-close>close</mat-icon>
                </div>
              </div>
        <div class="row" id="row2">
                <div class="share-btn-container col-md">
                        <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src={{postlocation}}"  class="facebook-btn" target="_blank"><img src="assets/logo/facebook.png" /></a>
                        <a href="https://twitter.com/intent/tweet?text={{postlocation}}" class="twitter-btn" target="_blank"><img src="assets/logo/twitter.png" /></a>
                        <a href="https://www.linkedin.com/sharing/share-offsite/?url={{postlocation}}" class="linkedin-btn" target="_blank"><img src="assets/logo/linkedin.png" /></a>
                        <a href="https://api.whatsapp.com/send?text={{postlocation}}" class="whatsapp-btn" target="_blank"><img src="assets/logo/whatsapp.png" /></a>
                </div>
        </div>
        <div class="row"  id="row3">
                        <div class="col-md"  id="col3">
                                <input id="post" type="text"  [(ngModel)]="postlocation" readonly>
                        </div>
                        <div class="col-md" id="col4">
                                <button mat-button color="primary" [cdkCopyToClipboard]="postlocation">Copy</button>
                        </div>
        </div>
        
</div>

<div class="conatiner mob-slide" id="container" *ngIf="isMobileResolution==false" >
        <div class="row" id="m-row1">
                <div class="col-md" id="col1">
                        <h2 class="share"> Share </h2>
                </div>
                <div class="col-md" id="col2" >
                        <mat-icon style="cursor: pointer;" mat-button matRipple  mat-dialog-close>close</mat-icon>
                </div>
              </div>
        <div class="row" id="row2">
                <div class="share-btn-container ">
                        <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdevelopers.facebook.com%2Fdocs%2Fplugins%2F&amp;src={{postlocation}}"  class="facebook-btn" target="_blank"><img src="assets/logo/facebook.png" /></a>
                        <a href="https://twitter.com/intent/tweet?text={{postlocation}}" class="twitter-btn" target="_blank"><img src="assets/logo/twitter.png" /></a> 
                        <a href="https://www.linkedin.com/sharing/share-offsite/?url={{postlocation}}" class="linkedin-btn" target="_blank"><img src="assets/logo/linkedin.png" /></a>
                        <a href="https://api.whatsapp.com/send?text={{postlocation}}" class="whatsapp-btn" target="_blank"><img src="assets/logo/whatsapp.png" /></a>
                </div>
        </div>
        <div class="row"  id="m-row3">

                        <div class="col-md"  id="col3">
                                <input id="m-post" type="text"  [(ngModel)]="postlocation" readonly>
                        </div>
                        <div class="col-md" id="col4">
                                <button mat-button color="primary" style="font-size:12px;" [cdkCopyToClipboard]="postlocation">Copy</button>
                        </div>
              
        </div>
        
</div>