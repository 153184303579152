import { Injectable } from '@angular/core';

import {Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';

@Injectable()
export class bookingservice {
  isSessionVaild = true;
  constructor(private http: HttpClient, public router: Router) {  }
  

  verifyOTP(phone:any) {
    return this.http.get('https://'+environment.domain+'/public/api/getOTPdata/'+phone,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }

  checkOTP(otp:any,details:any) {
    return this.http.post('https://2factor.in/API/V1/a44826f2-67a4-11ec-b710-0200cd936042/SMS/VERIFY/'+details+'/'+otp,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }

  

  updateBooking(data:any) {
    return this.http.post('http://127.0.0.1:8000/api/update-booking',data,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }


  createBooking(data:any):Observable<{data:any}>{
    return this.http.post<any>('https://'+environment.domain+'/public/api/bookings',data,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }

  // contactForm(data:any) {
  //   return this.http.post('https://'+environment.domain+'/public/api/contactform',data,{
  //     headers : {        
  //         'Content-Type' : 'application/json'
  //     }
  //   });
  // }

  contactForm(data:any) {
    return this.http.post('https://'+environment.domain+'/public/api/storeLeadStatus',data,{
      headers : {        
          'Content-Type' : 'application/json'
      }
    });
  }
 

  partnerForm(data:any) {
    return this.http.post('https://'+environment.domain+'/public/api/partnerform',data,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }

  Coupons(coupon:any) {
    return this.http.get('https://'+environment.domain+'/public/api/coupons/'+coupon,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }
}
