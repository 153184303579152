import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlRedirectionService {

  constructor() { }

  redirectUrl(url: string, router: any) {
    if (url === "/property?location=Lonavala")
      router.navigateByUrl('/property/location/villas-in-lonavala');
    else if (url === "/property?location=Alibaug")
      router.navigateByUrl('/property/location/villas-in-alibaug');
    else if (url === "/property?location=Candolim")
      router.navigateByUrl('/property/location/villas-in-candolim');
    else if (url === "/property?location=Anjuna")
      router.navigateByUrl('/property/location/villas-in-anjuna');
    else if (url === "/property?location=Karjat")
      router.navigateByUrl('/property/location/villas-in-karjat');
    else if (url === "/property?location=Igatpuri")
      router.navigateByUrl('/property/location/villas-in-igatpuri');

      else if (url === "/property/location/Anjuna")
      router.navigateByUrl('/property/location/villas-in-anjuna');
      else if (url === "/property/location/North%20Goa")
      router.navigateByUrl('/property/location/villas-in-north%20goa');
       else if (url === "/property/location/Candolim")
      router.navigateByUrl('/property/location/villas-in-candolim');
       else if (url === "/property/location/Arambol")
      router.navigateByUrl('/property/location/villas-in-arambol');
      else if (url === "/property/location/Arambol")
      router.navigateByUrl('/property/location/villas-in-arambol');
      else if (url === "/property/location/Alibaug")
      router.navigateByUrl('/property/location/villas-in-alibaug');
      else if (url === "/property/location/Lonavala")
      router.navigateByUrl('/property/location/villas-in-lonavala');
      else if (url === "/property/location/Panchgani")
      router.navigateByUrl('/property/location/villas-in-panchgani');
      else if (url === "/property/location/Igatpuri")
      router.navigateByUrl('/property/location/villas-in-igatpuri');
       else if (url === "/property/location/Karjat")
      router.navigateByUrl('/property/location/villas-in-karjat');
    else if (url === "/property/location/Nashik")
      router.navigateByUrl('/property/location/villas-in-nashik');
      else if (url === "/property/location/Pune")
      router.navigateByUrl('/property/location/villas-in-pune');
      else if (url === "/property/location/Wada")
      router.navigateByUrl('/property/location/villas-in-wada');
      else if (url === "/property/location/Mumbai")
      router.navigateByUrl('/property/location/villas-in-mumbai');
      else if (url === "/property/location/Panvel")
      router.navigateByUrl('/property/location/villas-in-panvel');
      else if (url === "/property/location/Khopoli")
      router.navigateByUrl('/property/location/villas-in-khopoli');
      else if (url === "/property/location/Bangalore")
      router.navigateByUrl('/property/location/villas-in-bangalore');
      else if (url === "/property/location/Chikmagalur")
      router.navigateByUrl('/property/location/villas-in-chikmagalur');
      else if (url === "/property/location/Coorg")
      router.navigateByUrl('/property/location/villas-in-coorg');
      else if (url === "/property/location/Kunigal")
      router.navigateByUrl('/property/location/villas-in-kunigal');
       else if (url === "/property/location/Nainital")
      router.navigateByUrl('/property/location/villas-in-Nainital');
       else if (url === "/property/location/Bhimtal")
      router.navigateByUrl('/property/location/villas-in-bhimtal');
       else if (url === "/property/location/Dehradun")
      router.navigateByUrl('/property/location/villas-in-dehradun');
      else if (url === "/property/location/Mussoorie")
      router.navigateByUrl('/property/location/villas-in-mussoorie');
      else if (url === "/property/location/Ooty")
      router.navigateByUrl('/property/location/villas-in-ooty');
      else if (url === "/property/location/Jodhpur")
      router.navigateByUrl('/property/location/villas-in-jodhpur');
      else if (url === "/property/location/Jaipur")
      router.navigateByUrl('/property/location/villas-in-jaipur');
      else if (url === "/property/location/Udaipur")
      router.navigateByUrl('/property/location/villas-in-udaipur');
       else if (url === "/property/location/Ajmer")
      router.navigateByUrl('/property/location/villas-in-ajmer');
      else if (url === "/property/location/Pushkar")
      router.navigateByUrl('/property/location/villas-in-pushkar');
      else if (url === "/property/location/Munnar")
      router.navigateByUrl('/property/location/villas-in-munnar');
      else if (url === "/property/location/Alleppey")
      router.navigateByUrl('/property/location/villas-in-alleppey');
      else if (url === "/property/location/Kochi")
      router.navigateByUrl('/property/location/villas-in-kochi');
       else if (url === "/property/location/Arpookara")
      router.navigateByUrl('/property/location/villas-in-arpookara');
       else if (url === "/property/location/Dalhousie")
      router.navigateByUrl('/property/location/villas-in-Dalhousie');






      else if (url === "/property/state/Goa")
      router.navigateByUrl('/property/state/villas-in-goa');
       else if (url === "/property/state/Maharashtra")
      router.navigateByUrl('/property/state/villas-in-maharashtra');
      else if (url === "/property/state/Karnataka")
      router.navigateByUrl('/property/state/villas-in-karnataka');
      else if (url === "/property/state/Uttarakhand")
      router.navigateByUrl('/property/state/villas-in-uttarakhand');
      else if (url === "/property/state/Delhi")
      router.navigateByUrl('/property/state/villas-in-delhi');
      else if (url === "/property/state/Tamil%20Nadu")
      router.navigateByUrl('/property/state/villas-in-tamil%20nadu');
      else if (url === "/property/state/Rajasthan")
      router.navigateByUrl('/property/state/villas-in-rajasthan');
      else if (url === "/property/state/Kerala")
      router.navigateByUrl('/property/state/villas-in-kerala');
      else if (url === "/property/state/Himachal")
      router.navigateByUrl('/property/state/villas-in-himachal');
      else if (url === "/property/state/Pondicherry")
      router.navigateByUrl('/property/state/villas-in-pondicherry');



  }

  redirectToUrl(typeName: string, locationName: string, router: any) {
    let redirectUrl;
    const stateLocation = locationName.toLowerCase();
    const stateList = ['pondicherry', 'goa', 'rajasthan', 'karnataka', 'tamil nadu', 'maharashtra', 'uttarakhand', 'kerala', 'himachal'];
    if (typeName == 'location' && stateList.indexOf(stateLocation) != -1)
      redirectUrl = `/property/state/villas-in-${stateLocation}`;
    else {
      redirectUrl = `/property/${typeName}/villas-in-${stateLocation}`;
    }
    router.navigateByUrl(redirectUrl);
  }
}