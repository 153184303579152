<section class="page-wrapper">
  <section class="booking-page">
    <div class="container">
      <div class="section-intro">
        <div class="row">
          <div class="col-md-12">
            <div class="row" style="margin-top: -30px">
              <div class="col-md-12">
                <div
                  class="animation-tick"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <lottie-player
                    src="https://assets5.lottiefiles.com/packages/lf20_w5oe9omf.json"
                    background="transparent"
                    speed="0.5"
                    style="width: 300px; height: 300px"
                    autoplay
                  ></lottie-player>
                </div>
                <h4
                  class="thnk font_style_title"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  Thank You for your Reservation! Your Booking is not yet
                  Confirmed. You shall receive an email on {{ email }} with all
                  the reservation details. Please get in touch with your
                  dedicated host to confirm your booking!
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="booking_wrapper">
    <div class="container">
      <div class="row Reservation_dlts">
        <div class="col-sm-8" data-aos="fade-up" data-aos-duration="1200">
          <div class="review_details">
            <div class="defoult_h font_style_head">Property Details</div>
            <div class="reserve_info">
              <div class="view">
                <div class="villa_slide">
                  <owl-carousel-o [options]="customOptions">
                    <ng-container
                      *ngIf="propertyImage && propertyImage?.length > 0"
                    >
                      <ng-container *ngFor="let pmedia of propertyImage">
                        <ng-template carouselSlide
                          ><img [src]="imagePropertySrc + pmedia?.media"
                        /></ng-template>
                      </ng-container>
                    </ng-container>
                  </owl-carousel-o>
                </div>
              </div>
              <div class="occupancy">
                <h4 class="villa_name font_style_head">
                  <b class="font_style_head">{{ propertyName }}</b>
                </h4>
                <div class="villa_locad font_style_head">
                  <i class="fa fa-map-marker"></i>
                  {{ this.detailDatas[0]?.locname }}
                </div>

                <div class="interior font_style_head">
                  <i class="fa fa-home"></i>
                  <span class="font_style_head">
                    {{ detailDatas[0]?.name }}
                  </span>
                </div>
                <div class="interior font_style_head">
                  <i
                    aria-hidden="true"
                    class="fa fa-bath vill-detail-btn-icon"
                  ></i>
                  <span class="font_style_head">
                    {{ detailDatas[0]?.bathrooms }} Bath
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-4" data-aos="fade-up" data-aos-duration="1200">
          <div class="review_details">
            <div class="defoult_h font_style_head">Host Details</div>
            <div class="reserve_info">
              <div class="holder_dtl_row">
                <b class="font_style_title"
                  ><i class="fa fa-user"></i> Host Name:-</b
                >
                <span class="font_style_title">
                  {{ this.detailDatas[0]?.salename }}
                </span>
              </div>
              <div class="holder_dtl_row">
                <b class="font_style_title"
                  ><i class="fa fa-phone"></i> Host Phone Number:-</b
                >
                <span class="font_style_title">
                  {{ this.detailDatas[0]?.salephone }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-4" data-aos="fade-up" data-aos-duration="1200">
          <div class="review_details">
            <div class="defoult_h font_style_head">Booking Details</div>
            <div class="holder_dtl_row">
              <b class="font_style_title"
                ><i class="fa fa-calendar"></i> Check- In Date:</b
              >
              <span class="font_style_title">
                {{ checkin }}
              </span>
            </div>
            <div class="holder_dtl_row">
              <b class="font_style_title"
                ><i class="fa fa-calendar"></i> Check- Out Date:</b
              >
              <span class="font_style_title">
                {{ checkout }}
              </span>
            </div>
            <div class="holder_dtl_row">
              <b class="font_style_title"
                ><i class="fa fa-money"></i> Booking Traiff:</b
              >
              <span class="font_style_title"> ₹{{ price }} </span>
            </div>
            <div class="holder_dtl_row">
              <b class="font_style_title"
                ><i class="fa fa-user"></i> Number of Guest:</b
              >
              <span class="font_style_title">
                {{ guest }}
              </span>
            </div>
          </div>
        </div>

        <div class="col-sm-4" data-aos="fade-up" data-aos-duration="1200">
          <div class="review_details">
            <div class="defoult_h font_style_head">Guest Details</div>
            <div class="reserve_info">
              <div class="holder_dtl_row">
                <b class="font_style_title"
                  ><i class="fa fa-user"></i> Name:-</b
                >
                <span class="font_style_title">
                  {{ fname }}
                </span>
              </div>
              <div class="holder_dtl_row">
                <b class="font_style_title"
                  ><i class="fa fa-phone"></i> Phone Number:-</b
                >
                <span class="font_style_title">
                  {{ phoneno }}
                </span>
              </div>
              <div class="holder_dtl_row">
                <b class="font_style_title"
                  ><i class="fa fa-envelope"></i> Email:-</b
                >
                <span class="font_style_title">
                  {{ email }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
