import { Injectable } from '@angular/core';

import {Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';


@Injectable()
export class imagepathservice {
    myGlobalVar: any = '';
  isSessionVaild = true;
  constructor(private http: HttpClient, public router: Router) {  }
  

  getImagePaths() {
    return this.myGlobalVar = 'gaurav';
  }
}
