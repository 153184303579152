import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'SortBy'
})
export class IeSortPipe implements PipeTransform {

  transform(array: Array<string>, args?: string): any {

 
  if(!array || array === undefined || array.length === 0) return null;
    array.sort((a: any, b: any) => {
      if (a.sort < b.sort) {
        return -1;
      } else if (a.sort > b.sort) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;

  }
}
