<section class="page-wrapper">
		<section class="blog-page page-section">
	<div class="container" data-aos="fade-up" data-aos-duration="1000">

			<div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
			<h2>Terms &amp;  Conditions</h2>
		<p>Guests are requested to read and understand the Cancellation, Refund, Relocation, Reschedulement and other Policies applicable before making a booking via our website, other third party websites or applications, or through offline channels like any third parties, advertisements, referrals etc. </p>
		<p>Please note, EKOSTAY has 2 policies - standard and strict - to protect the interests of both Guest(s) and Homeowner(s), alike. Please check carefully, which Policy applies to the Home.</p>
	</div>

		<div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
		<div class="table_wrapper" data-aos="fade-up" data-aos-duration="1000">
		<h2>STANDARD POLICY</h2>
		<table class="table" data-aos="fade-up" data-aos-duration="1000">
			<tr>
			  <th colspan="2">15 Days Cancellation Policy </th>
			</tr>
			<tr>
				<th>When a Cancellation is made</th>
				<th>Cancellation Charges</th>
			  </tr>
			<tr>
			  <td>More than 15 days prior to check-in date</td>
			  <td>100% refund of the advance is applicable.</td>
			</tr>
			<tr>
			  <td>Between 14 days to 8 days prior to check-in date</td>
			  <td>Between 14 days to 8 days prior to check-in date	Non-refundable, however we shall open the reservation (after client's consent) and find another in the same price post which we shall share a credit note. The difference will be borne by the guest in the event that the alternate reservation value is less than the original reservation and the remaining amount will be returned in the form of a credit note. Validity of the credit note will be 90 days from the day of issuance.
				<br>
				In case we are unable to find an alternate reservation, amount shall be non refundable
				</td>
			</tr>
			<tr>
			  <td>Between 7 days prior to check-in date</td>
			  <td>No Refund / No Modification is possible.</td>
			</tr>
		  </table>
		  </div>

		  <div class="table_wrapper" data-aos="fade-up" data-aos-duration="1000">
			<h2>Policy For Reservations on Public Holidays Diwali, Holi, 22nd December to 2nd January and long weekends.</h2>
			<table class="table">
				<tr>
				  <th colspan="2">30 Days Cancellation Policy </th>
				</tr>
				<tr>
				  <th>When a Cancellation is made</th>
				  <th>Cancellation Charges</th>
				</tr>
				<tr>
				  <td>More than 30 days prior to check-in date</td>
				  <td>100% refund of the advance is applicable.</td>
				</tr>
				<tr>
				  <td>Within 30 days prior to check-in date</td>
				  <td>No Refund / No Modification is possible.</td>
				</tr>
			  </table>
		  </div>

		  <!-- <div class="table_wrapper" data-aos="fade-up" data-aos-duration="1000">
			<h2>STRICT POLICY</h2>
			<table class="table">
				<tr>
				  <th colspan="2">Non – Refundable & Non- Amendable Cancellation Policy</th>
				</tr>
				<tr>
				  <th>When a Cancellation is made</th>
				  <th>Cancellation Charges</th>
				</tr>
				<tr>
				  <td>Anytime after Reservation is made</td>
				  <td>No Refund / No Modification is possible.</td>
				</tr>
			  </table>
		  </div> -->

		</div>







<div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
<h2>Refund for Extra Guests:</h2>
<p>In case of any change in group size, the extra bedding charges collected can be refunded if intimated to EKOSTAY minimum 3 days prior to check-in. As with all refunds a 5% processing fee shall be levied for processing the refund. No refund for extra bedding charges shall be processed if informed within 3 days prior check-in or after check-in.</p>
<ul class="content_list">
<h3>Cancellation by EKOSTAY: EKOSTAY will provide one of the following options.</h3>
<li><i class="fa fa-check vill-detail-btn-icon"></i> EKOSTAY reserves to right to cancel a booking</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i> Book an alternative comparable available Home/ Villa/ Apartment</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i> EKOSTAY shall offer Travel Credit (validity of 6 months from date of issuance) of the full amount paid that may be redeemed while booking a new home.</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i> A full refund for the total amount paid. The refund shall be processed within 7 working days from the date of cancellation.</li>
</ul>
</div>


<div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
<h2>Reschedule Policy:</h2>
<ul class="content_list">
<li><i class="fa fa-check vill-detail-btn-icon"></i>	EKOSTAY allows reschedulement only upto 15 days before the Date of Check-In. Ekostay may charge a Reschedulement Charge + Taxes for any bookings that may have to be rescheduled.</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i>	If the tariff on the dates to which the booking is being rescheduled to, is higher than the tariffs of the original booking, the difference is payable to ekostay (along with applicable GST), along with the Reschedulement charge.</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i>	Reschedulement charge may be applicable, every time the booking is rescheduled.</li>
</ul>
</div>

<div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
<h2>Relocation Policy:</h2>
<p>Relocation (to another Home) is not permitted, once a booking is confirmed.</p>

<h3>Early Check-in and Late Check-out Policy:</h3>
<ul class="content_list" data-aos="fade-up" data-aos-duration="1000">
<li><i class="fa fa-check vill-detail-btn-icon"></i> Our standard check-in time is 2 pm and check-out time is 11 am.
We'd be happy to offer you a complimentary early check-in by 1 pm , provided there's no booking at the villa for the previous night. Please confirm with us a day prior to the check-in, which will  be subject to availability. In case you wish to avail an earlier check-in (earliest possible is 10:00 am), charges are applicable & subjective to availability. The rate is 20% of the tariff for that night.</li>

<li><i class="fa fa-check vill-detail-btn-icon"></i>	We'd be happy to offer a complimentary late check-out by 12:00 pm if there's no booking at the villa on the day of your checkout. However, we can only let you know by 10am on your checkout date as we do accept instant online and last-minute bookings.</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i>	In case you wish to avail a further extended checkout (post 11:00 am), charges are applicable. The rate is 20% of the tariff for that night for a Checkout up to 2 pm, and 40% upto 5 pm. Full night charges apply thereafter.</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i>	It is at the discretion of ekostay, whether to offer early check-in or late check-out.</li>
</ul>
</div>

<div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
<h2>Processing Refunds:</h2>
<ul class="content_list">
<li><i class="fa fa-check vill-detail-btn-icon"></i>	In case of any refunds that EKOSTAY owes Guest(s), the processing shall be completed within 7 working days.</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i>	A nominal cancellation fee of 5% shall be levied for processing refunds by ekostay. This is levied to cover payment gateway charges along with a minimal processing charge.</li>
</ul>
</div>


<div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
<h2>Liability:</h2>
<ul class="content_list">
<li><i class="fa fa-check vill-detail-btn-icon"></i> The maximum risk to the extent permitted by law arising out of access and use or any stay by the Guest at any Accommodation, participation in any Experience or Event or use of any other Host Service or any other interaction that the Guest have with other person whether in person or online shall be upon himself and not Ekostay. Neither Ekostay nor any other party involved in creating, producing, or delivering the services will be liable for any incidental, special, exemplary or consequential damages, including lost profits, loss of data or loss of goodwill, service interruption, computer damage or system failure or the cost of substitute products or services, or for any damages for personal or bodily injury or emotional distress arising out of or in connection with (i) these Terms, (ii) from the use of or inability to use the property, (iii) from any communications, interactions or meetings with other persons with whom you communicate, interact or meet with as a result of your use of the services of Ekostay rooms.</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i>	Inverter or generator back ups are provided at all Ekostay properties in case of electricity failure to let the guests stay not be hampered. However, inverter back ups last depending on usability of electric items by the client. Generator back up shall be chargeable at ₹500/hour. Kindly note, electricity in most of the tourist towns is provided by the government and not private players, hence restoration of the same takes longer than usual city power cuts. Ekostay will not be held liable for any power cut outs and no refund will be provided to the client for the same, as it’s out of the control of Ekostay.</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i>	Kindly note that there is no lifeguard stationed at any pool of Ekostay property and the guests should only indulge in swimming if they know how to swim & in case they don’t, they shall carry a float or tube as Ekostay shall not provide any. Kids should always be assisted by adults in or by the poolside. Any accidents incurred at the property/pool is not a liability of Ekostay. </li>
<li><i class="fa fa-check vill-detail-btn-icon"></i>	Guests shall at all given times take responsibility for keeping their luggage/valuables and all personal belongings  in a locked cupboard and the loss or damage of any item left unattended will not be a liability or responsibility of Ekostay. </li>
<li><i class="fa fa-check vill-detail-btn-icon"></i>	Guests shall make sure their cars are aptly parked in the designated parking areas avoiding any hindrance to other cars in the vicinity. Parking of cars will be solely at the owners risk and Ekostay does not accept any damage or responsibility for any such accidents or losses.</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i>	Certain Ekostay properties are close to nature and hence mosquitoes/insects/pests may be spotted more often than natural, Ekostay requests all its guests to make sure to shut all doors and windows at sunset to avoid such insects/pests from entering the property. Trespassing of pests/insects into an Ekostay property isn’t a liability of Ekostay and no refund will be provided by Ekostay. However, the ground team of Ekostay shall be notified in case of any such incident and necessary steps/actions will be taken to get rid of the insects/pests.</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i>	Wi-if network fluctuations may take place due to various unforeseen reasons & ekostay holds no control over the same. However, our ground team on being informed by the guests will reach out to the network provider to resolve the issue at the earliest. No refunds will be provided in such a case irrespective.</li>
</ul>
</div>

<div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
<h2>BASIC HOUSE RULES</h2>
<ul class="content_list">
<h3>The following are prohibited at EKOSTAY:</h3>

<li><i class="fa fa-check vill-detail-btn-icon"></i> We do not allow smoking of any kind inside the property  ( only balconies or terraces or garden are permitted areas), including but not limited to nicotine, tobacco derived products and/or cannabis derived substances (including vaping and e-cigarettes); breaking this rule will incur a INR 2500 fine;</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i> DJ Parties or events;</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i> Unreasonable numbers of guests including any that exceed the maximum permitted by Ekostay;</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i> Number of overnight guests cannot exceed the maximum allowed for the unit, this information is posted on the website;</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i> Excessive noise (in the form of yelling/shouting or music);</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i> Audible noise outside of the property after 10:30 pm local time;</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i> Disturbing neighbors or invading their privacy or security;</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i> Illegal substances or activity (e.g., involving drugs, the Internet, or Gambling or otherwise, including illegal downloads or file sharing whether using our, your own, or a third-party device or Internet connection);</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i> Pornography production or prostitution;</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i> Dangerous or hazardous items (e.g., those that may increase the risk of fire or explosion such as candles or lighters or fire crackers);</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i> Firearms and other weapons (except those rightfully possessed by on-duty or off-duty law enforcement officials or licensed security guards);</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i> Placing of obstructions, signage, garbage, food, or personal items unattended outside the property;</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i> Creating unsanitary conditions (e.g., generating excessive trash or vomiting in the property or on any linens or offensive odors, or throwing food all around the property or attracting pests);</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i> Making any change, modification, alteration, or addition whatsoever to the property or its contents (including moving furniture around);</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i> Soliciting or loitering around the space or building;</li>
<li><i class="fa fa-check vill-detail-btn-icon"></i> Commercial photography, film, or videography (without expressed written consent); </li>
<li><i class="fa fa-check vill-detail-btn-icon"></i> Using the property for any purpose other than for personal accommodation.     </li>
</ul>                                                                                                           
</div>


<div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
<h2>Other</h2>
<ul class="content_list">

<li><i class="fa fa-check vill-detail-btn-icon"></i> Zero tolerance. Please ensure that you respect all members of staff and your fellow residents. Ekostay is a safe and friendly environment where everybody should be treated with the same level of respect. In accordance with our non-discrimination and harassment policy, we have zero tolerance for racism, sexism, discrimination, intimidation, or violence to any kind.</li>

<li><i class="fa fa-check vill-detail-btn-icon"></i> Guests. You may permit guests to occupy the property during your stay only to the extent authorized by us in writing and you must accompany and supervise such guests at all times. If you exceed any applicable maximum authorized number of overnight guests, then you will be liable to pay additional fees of up to 100% of your effective daily rate for each unauthorized guest and we may revoke or terminate your license to occupy the property immediately. You also acknowledge and agree that we may require you and/or your daytime guests to provide us with documentation verifying your guests’ identity such as passport and other information.</li>

<li><i class="fa fa-check vill-detail-btn-icon"></i> Pets. Although we have pet friendly properties at Ekostay, guests must inform our team in advance about bringing pets to the property.We do not allow pets inside our swimming pools & violation of our pet policy results in a INR 10000 fine. Guests must ensure they scoop the poop of the pets and dispose of it aptly without creating any mess in the property.</li>

<li><i class="fa fa-check vill-detail-btn-icon"></i> Supplies. We provide an initial supply of consumable household supplies and toiletries.</li>

<li><i class="fa fa-check vill-detail-btn-icon"></i> Houseware. We also provide linens and kitchenware for your use during your stay. Any such items that are removed from the property will be charged to you in accordance with the Ekostay’s Terms.</li>

<li><i class="fa fa-check vill-detail-btn-icon"></i> Amenities. Only those amenities that are described in your booking confirmation email are available to you. Please follow any instructions that have been provided to you or may be posted on the property. Your use of any amenities is at your sole risk. Guests younger than 18 years must be accompanied by an adult when using any amenities and other common areas.</li>

<li><i class="fa fa-check vill-detail-btn-icon"></i> Parking. Parking options and instructions may be provided in our booking confirmation email to you. If you or your guests choose to make use of any recommended parking options, then you (and your guests, if applicable) must comply with all provided instructions, all publicly posted parking signs and policies, and any other applicable parking laws. Ekostay is not responsible for your or your guests’ non-compliance with such instructions, signs, policies, or laws.</li>

<li id="goa"><i class="fa fa-check vill-detail-btn-icon"></i> Safety and Security. You are responsible for your and your guests’ safety and security. Please read, listen to, and comply with any fire, health, or safety rules and regulations which are provided to you.</li>

<li><i class="fa fa-check vill-detail-btn-icon"></i> Reporting Damage to the property. You must notify us in writing of any damage done to the property or any of its contents which occurs during your stay, even if you regard the damage as minimal or normal wear and tear, or if you do not believe the damage is your fault. If you do not notify us of any such damage, you will be responsible for such damage in accordance with the Ekostay’s Term & the cost of repairing/replacing the damaged item will be deducted from your Security Deposit.</li>

<li><i class="fa fa-check vill-detail-btn-icon"></i> Communications. There may be property management or other professionals affiliated with Ekostay located on-site. </li>
<h3>Please direct all of your requests and communications to them or at the contact information previously provided to you.</h3>

<li><i class="fa fa-check vill-detail-btn-icon"></i> Other Instructions. In connection with your stay at Ekostay, we may provide you with other written instructions applicable to your stay. These written instructions are “House Rules” as defined in Ekostay’s Terms. Please follow those instructions, ensure that your guests follow them too, and call us if you have any questions.</li>

<li><i class="fa fa-check vill-detail-btn-icon"></i> As a reminder, you are solely responsible for ensuring that you and your guests are aware of and comply with the Ekostay’s Terms and these Ekostay’s Rules, including keeping the property clean and tidy and in the same condition as you found it. We hope you enjoy your stay!</li>
</ul>
</div>

<div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
<h2>Book 3 Nights in Goa and Get 1 Additional Night Free Offer :</h2> 
<ul class="content_list">
	<li><i class="fa fa-check vill-detail-btn-icon"></i> This offer is applicable on bookings made for stays in the month of January & February  only.</li>
	<li><i class="fa fa-check vill-detail-btn-icon"></i> Offer valid till 1st September.</li>
	<li id="mumbai"><i class="fa fa-check vill-detail-btn-icon"></i> Book 3 Nights Stay in Goa and get an Additional Night Stay Free. This offer is applicable only on select Goa properties.</li>
	<li><i class="fa fa-check vill-detail-btn-icon"></i> Two offers can’t be clubbed/ availed for an individual booking.</li>
	<li><i class="fa fa-check vill-detail-btn-icon"></i> Any future modifications to the  Offer will be disclosed on this page.</li>
	<li><i class="fa fa-check vill-detail-btn-icon"></i> Maximum Discount Rs. 20,000 /-</li>
</ul>
</div>

<div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
<h2>Book 2 Nights in Maharashtra and Get 15% Off Offer :</h2> 
<ul class="content_list">
	<li><i class="fa fa-check vill-detail-btn-icon"></i> This offer is applicable on bookings made for stays in the month of January & February  from Sunday - Thursday only.</li>
	<li><i class="fa fa-check vill-detail-btn-icon"></i> Offer valid till 1st September.</li>
	<li><i class="fa fa-check vill-detail-btn-icon"></i> Book 2 Nights Stay in Maharashtra and get 15% Off. This offer is applicable only on select Maharashtra properties.</li>
	<li><i class="fa fa-check vill-detail-btn-icon"></i> Two offers can’t be clubbed/ availed for an individual booking.</li>
	<li><i class="fa fa-check vill-detail-btn-icon"></i> Any future modifications to the  Offer will be disclosed on this page.</li>
	<li><i class="fa fa-check vill-detail-btn-icon"></i> Maximum Discount Rs. 4,000 /-</li>
</ul>
</div>

</div>

</section>
