<section class="contact-page page-section">
	<div class="container">
		
		<div class="row">
			
			<div class="container"><div class="col-md-12  loginBox">    
      
                Welcome, {{datas.name | titlecase}}</div></div>
		</div>
	</div>
	<!-- <div class="row"> -->
    
    <div class="container ">
      <div class="row">
      <div class="col-md-6 card">
        <h1 class="col-md-8">User Details</h1>
        <div class="col-md-4 right">
          <!-- matTooltip="Edit Details" -->
          <button mat-icon-button [matMenuTriggerFor]="menu"  class="button-edit" aria-label="Example icon-button with a menu">
            <mat-icon >more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">  
            <button mat-menu-item (click)="openDialog()">
              <mat-icon>edit</mat-icon>
              <span>Edit Details</span>
            </button>          
          </mat-menu>
        </div>
        <div class="col-md-12 phone-header">   
          <div>
            Name :
          </div>
          
          <div class="name-user">{{datas.name | titlecase}}</div>
        </div>        
        <div class="col-md-12 phone-header">   
          
          <div>
            Phone Number :
          </div>
          
          <div class="name-user">{{datas.phone}}</div>
          
          
        </div>
        <div class="col-md-12 phone-header">   
      
          <div>
            Date of birth :
          </div>
          
          <div class="name-user">{{datas.dob}}</div>
          
          
        </div>
        <div class="col-md-12 phone-header">   
      
          <div>
            Email :
          </div>
          
          <div class="name-user">{{datas.email}}</div>
          
          
        </div>
        <div class="col-md-12 phone-header" *ngIf="datas.member">   
          
          <div>
            Membership Type :
          </div>
          
          <div class="name-user"><span *ngIf="datas.membership_type == 1">Basic</span><span *ngIf="datas.membership_type == 2">Plus</span><span *ngIf="datas.membership_type == 3">Max</span>
          </div>
          
          
        </div>
        <div class="col-md-12 phone-header" *ngIf="!datas.member">   
          
          <div>
            You are not an Eko Member :
          </div>
          
          <div class="name-user"><a href="https://pages.razorpay.com/membershipekostay" target="_blank">Avail Member</a></div>
          
          
        </div>
      
      </div>
    </div>
      <div class="row">
      <div class="col-md-6 tabs">   
          
        <button class="button-tab" (click)="checkBookingDetails('now')" (click)="rotateIcon()">
          Upcoming Booking <span class="down-arrow"><i class="fa fa-chevron-down"  [class.rotate]="isRotated" ></i></span>
        </button>
        
        <button class="button-tab" (click)="checkBookingDetails('past')"  (click)="rotateIcon1()">
          Historical Booking <span class="down-arrow"><i class="fa fa-chevron-down" [class.rotate]="isRotatedH"></i></span>
        </button>

        <button class="button-tab" (click)="openMembershipDialog()">
          View Membership Details 
        </button>
      </div>
    </div>
    </div>
  <!-- </div> -->
	
	<div class="container" *ngIf="(isUpcoming || isHistory) && !isBooking">
      
      <div class="row stay_row">
        <div class="col-md-6  confstays">   
      
          Total Confirmed Stays :
          {{totalConfirm}}
        </div>       
          
  
        <div class="col-md-6  membtype" *ngIf="datas.member">       
          
          Membership Status : <span>EKOSTAY Preferred Member</span> 
        </div>
          
      </div>
    </div>

</section>


    
	
	<div class="profile">
	<div class="container" >
	<div class="row" *ngIf="(isUpcoming || isHistory) && !isBooking">
	<div class="col-md-12">
   <div class="table-responsive">  
	<table class="table table-bordered">
  <thead>
  <tr>
    <!-- <th class="table_header">Id</th> -->
    <th class="table_header">Booking No</th>
    <th class="table_header">Booking Date</th>
    <th class="table_header">Villa Name</th>
    <th class="table_header">Check In Date</th>
    <th class="table_header">Check Out Date</th>
    <th class="table_header">No. of Guest & pets</th>
    <th class="table_header" *ngIf="datas.member">Membership Discount</th>
    <th class="table_header">Payable Amount</th>
    <th class="table_header">Status</th>
    <th class="table_header">Balance pending</th>
  </tr>
  </thead>
  <tbody>

    <tr *ngFor="let details of bookings" class="table-content">
      <!-- <td>{{details.id}}</td> -->
      <ng-container *ngIf="details.status === 3 || details.status === 1 || details.status === 4 || details.status === 6">
      <td>{{details.booking_no}}</td>
      <td>{{details.created_at | date: 'dd/MM/yyyy'}}</td>
      <td>{{cleanPropertyName(details.property_name)}}</td>
      <td>{{details.checkin}}</td>
      <td>{{details.checkout}}</td>
      <td>{{details.guest}} Guest <span *ngIf="details.pet">& {{details.pet}} Pet</span></td>
      <td *ngIf="datas.member">{{details.membership_discount}}</td>
      <td>{{details.price}}</td>
      <td>
        <span *ngIf="details.status == 1">
          Approve
        </span>
        <span *ngIf="details.status == 2">
          Inactive
        </span>
        <span *ngIf="details.status == 3">
          Confirm
        </span>
        <span *ngIf="details.status == 4">
          Disapprove
        </span>
        <span *ngIf="details.status == 6">
          Cancelled
        </span>
        <span *ngIf="details.status == 10">
          Maintenance
        </span>
        <span *ngIf="details.status == 11">
          Functionality
        </span>
        <span *ngIf="details.status == 12">
          Owner Stay
        </span>
        <span *ngIf="details.status == 13">
          Influencer
        </span>
        <span *ngIf="details.status == 14">
          Blocked
        </span>
        <span *ngIf="details.status == 15">
          Not Intrested
        </span>
        <span *ngIf="details.status == 16">
          Confirm & Block
        </span>
        <span *ngIf="details.status == 17">
          Lead
        </span>
      </td>
      <td>{{details.panding_price}}</td>
    </ng-container>
    </tr>    
	 
 
     </tbody>
    </table>
    </div>
    </div>	
    </div>
    <div class="row" *ngIf="isBooking">
      <p class="no-history" *ngIf="isUpcoming">You don't have any Upcoming bookings with ekostay</p>
      <p class="no-history" *ngIf="isHistory">You don't have any History booking with ekostay</p>
    </div>
    </div>

    
    </div>

    