<section class="page-wrapper">
	<section class="faq-page-banner">
    <div class="container">
      <h1 class="heading" data-aos="fade-up" data-aos-duration="1000">Frequently Asked Questions</h1>
      <h4  class="subheading" data-aos="fade-up" data-aos-duration="1000">To help you understand why homestays are the perfect choice for a private, comfortable, and luxurious holiday, here are the Frequently Asked Questions on the general information on villas and the standard operating procedures. Whether it’s your first time to book a private villa rental for your holiday or your first time to book with EKO STAY, these FAQ’s will serve as your basic guide to pursuing a luxury vacation to your dream destination. Stay at a villa once and you’ll never look at hotels the same way again</h4>
    </div>
	</section>
</section>



<section class="faq-section">
	<div class="container">
<div class="row">
  <div class="faq_wrapper">
    <ul>
      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>How to book a property with EKOSTAY directly? </h2>
        <p>You can book our properties from our website. Once we receive your reservation our team shall be in touch with you to confirm your booking.</p>
      </li>
      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>Can I make a last minute booking?</h2>
        <p>YES, you can definitely make a last minute booking by calling our central concierge on 91 9004844487. This will be subject to availability.</p>
      </li>
      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>Is my reservation as good as a confirmation?</h2>
        <p>NO, once you reserve our property via the website our concierge will call you in order to confirm the booking details & the availability & price of the property for your desired dates.</p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>How do I know if my booking is confirmed or not?</h2>
        <p>The sales person/concierge whom you coordinated with for the booking will send you an email or whatsapp confirmation message with all your booking details.</p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>Can I make a booking directly with the contact number provided?</h2>
        <p>YES, on our homepage we have provided the contact number of the sales person allocated to each city. They are highly trained professionals capable of converting your enquiry into a confirmed booking by coordinating with you on Call/Whatsapp/Email, whichever way you find it convenient.</p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>Do I have to make any payment in advance to confirm the booking?</h2>
        <p>YES, 100% of the advance amount has to be paid in order to block the dates for you & confirm the said booking.</p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>Can I pay the entire amount directly at the property?</h2>
        <p>NO, 50% of the amount has to be paid in advance. However, the balance 50% can be paid at the time of check in. For festive season, bank holidays and long weekends, we would require 100% prepayment. This shall be informed by our sales team in advance.</p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>What is the mode of payment accepted by EKOSTAY?</h2>
        <p>Once the guests have confirmed the booking details, our designated sales person/concierge will provide the guests with the bank details in order to let the guests do a bank transfer via NEFT/IMPS or they can even do a cash deposit into our accounts directly. We also accept payments via GPAY. Payment links too can be provided at your request however; a 3% surcharge will be levied on payments made via this link. It also accepts Credit Cards, Debit Cards and mobile wallets.</p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>Will the pricing vary if the number of guests increase or decrease after confirming the booking?</h2>
        <p>YES, each property has a maximum number of guests it can accommodate & you will be charged for it accordingly. The tariff increases after 12 pax in any of our properties & extra persons will be charged extra. It is best to confirm these details while making the booking with our sales person/concierge who can give you detailed clarity on the same. For some property, the standard occupancy is less than 12 pax, which shall be informed by the sales team in advance.</p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>Are all EKOSTAY properties pet friendly?</h2>
        <p>NO, all EKOSTAY properties are not pet friendly but our sales person/concierge can help you understand as to which ones are pet friendly.</p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>Are HOUSE RULES supposed to be followed strictly?</h2>
        <p>YES, house rules have to be strictly obeyed for the betterment of the guests & the entire neighbourhood. </p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>How can I reach an EKOSTAY property?</h2>
        <p>Our sales team will share a Google map link with you on whatsapp & besides that you will also be provided with the regional manager’s number who can help you with better directions or assistance to direct you towards the property in case you’re lost.</p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>What are the Check-in/ Check-out timings?</h2>
        <p>
          All our properties follow a standard check-in & check-out time & requests for early check-in or late check-out are subjective to availability.

           STANDARD CHECK-IN TIME: 2:00pm

           STANDARD CHECK-OUT TIME: 11:00am

           EKOSTAY reserves the rights to whether offer an early check-in or late check-out.
        </p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>Does every EKOSTAY property have a caretaker?</h2>
        <p>YES, every property of ours has a caretaker available at your service from morning to night but some properties have caretakers available on call. The sales team will be able to give you better clarity on it while confirming your booking.</p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>How well equipped are EKOSTAY properties?</h2>
        <p>All EKOSTAY properties are fully furnished with clean white linens, well-equipped kitchen utensils & crockery. They also have a pre-installed Wi-Fi, DTH & speaker system. Some locations with poor infrastructure may not have WiFi. Our sales team shall inform in advance about the  same.</p>
      </li>


      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>Does EKOSTAY provide food service?</h2>
        <p>NO, EKOSTAY does not provide food service itself, but our regional managers & caretakers are more than happy to give you good recommendations.</p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>What & how much is a Security Deposit?</h2>
        <p>EKOSTAY charges all its guests a security deposit amount that varies from property to property. The purpose of a security deposit is to assure that a guest keeps the rented property in a good condition & incase of any breakage the cost of damage is then deducted from the security deposit. Guests must pay the mentioned security amount in cash at the time of check-in & the regional manager or caretaker shall refund the same to them at the time of check-out.</p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>Do we need to carry an ID Proof?</h2>
        <p>YES, guests have to compulsorily submit one original address ID proof (Aadhar, Driving license, Passport).</p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2> Am I obliged to pay the Caretaker for the services rendered by him/her?</h2>
        <p>NO, guests are not obliged to pay the caretaker for keeping the villa clean and housekeeping related services. However, if guests want the caretaker to cook meals for them then caretakers have the right to state their charges towards these services rendered.</p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>What is the cancellation policy?</h2>
        <p>
          All guests are obliged to read & understand carefully the Cancellation & Refund policy before making a booking. EKOSTAY requests all guests to follow this policy strictly in order to safeguard both- the Guests as well as Home Owners.

			Full refund if cancelled before 30 days of check-in date.

			50% refund if cancelled before 15 days of check-in date.

			No Refund if cancelled within 15 days of check-in date.
        </p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>How long will the refunds take?</h2>
        <p>All refunds will be processed within 5-7 working days from the date of cancellation.</p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2> What is the rescheduling policy?</h2>
        <p>EKOSTAY understands guests may have some last minute change in plans due to which they would need to reschedule their stay at one of our properties. However, rescheduling is only possible if done up to 15 days prior to the check-in date. If the there is a difference in the tariff compared to the original booking made then the guests are liable to pay the additional charges to EKOSTAY.</p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>Are the villas well maintained and hygiene is taken care of?</h2>
        <p>YES. All our villas go through a timely maintenance drill by our dedicated maintenance team on ground across all locations. However you might notice a nominal wear and tear during harsh weather conditions which we assure wouldn't hamper your stay or your experience. Quality control as far as villa and caretaker hygiene is of utmost importance to us and the same is implemented across all our villa. We ensure a deep cleaning process in regular intervals. </p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>Incase of any sort of emergency/difficulty will we be assisted?</h2>
        <p>Yes. Our area managers are well trained and have the knowledge to assist you with the right contact incase of emergency - be it medical or any sort of issue within the villa. </p>
      </li>

      <li data-aos="fade-up" data-aos-duration="1000">
        <input type="checkbox" checked>
        <i></i>
        <h2>What happens if there is a cancellation from EKOSTAY?</h2>
        <p>
          Under unforeseen circumstances, EKOSTAY holds the rights to cancel a booking at its discretion. If a booking is cancelled by EKOSTAY it may:
          <p>	1. Provide the guests with an alternative comparable property.</p>

			<p>2. Offer EKOSTAY credit of the entire booking amount, which can be redeemed to book another stay at another time with one of our EKOSTAY properties.</p>

          <p>3. A full refund of the entire amount paid. The refund shall be processed within 5-7 working days.</p>

      </li>


    </ul>
  </div>
</div>
	</div>
</section>