import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import * as AOS  from 'aos';
@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.css']
})
export class TermsComponent implements OnInit {
isBrowser:boolean;

  constructor(private metaService: Meta,private title: Title,  @Inject(PLATFORM_ID) platformId: Object) { 

    this.isBrowser = isPlatformBrowser(platformId);
    this.title.setTitle('Ekostay - Terms & Conditions');
     this.metaService.addTag({ name: 'description', content: 'Ekostay - Terms & Conditions' });

  }

  ngOnInit(): void {
if(this.isBrowser){

  AOS.init();
  window.addEventListener('load', AOS.refresh)
}

  }

}
