
<!--------------------Featured Properties--------------------->

<div class="Properties PropertiesBorder">
  <div class="container">
    <div class="row heading">
      <div class="col-md-12">
        <h2>Featured Properties</h2>
        <img ngSrc="assets/img/heding-icon.png" width="105" height="16" alt="heding-icon.png">
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <ul class="nav nav-tabs">
          <li class="active"><a data-toggle="tab" href="#tab1">Near Mumbai</a></li>
          <li><a data-toggle="tab" href="#tab2" (click)="loadGoa()">Goa</a></li>
          <li><a data-toggle="tab" href="#tab3" (click)="loadnorthindia()">North India</a></li>
        </ul>

        <div class="tab-content">
          <div id="tab1" class="tab-pane fade in active">
            <div class="container containerss">
              <div class="row">
                <owl-carousel-o [options]="customOptions" class="owl-carouselss">
                  <ng-container *ngFor="let property of propertyData">
                    <ng-template carouselSlide *ngIf="property?.status == 1">
                      <div class="item">
                        <div class="bsp_bbb_item">

                          <div class="image-box">                            
                            <img ngSrc="{{imageSrc}}{{property.image}}"  width="325" height="200" alt="{{property.image}}" priority>
                            <a routerLink="property/location/villas-in-{{property.locname}}/{{property.permalink}}" class="more-details" target="_blank">More Details</a>
                          </div>

                          <div *ngFor="let property_media_d of property.property_media"><span>{{property_media_d}}</span>
                          </div>
                          <div class="featured_details">
                            <div>                              
                              <div class="bsp_card-title_home" >
                                <p  class="bsp_card-text">{{property.property_name}}</p>
                              </div>
                            </div>

                            <div class="text-left">
                              <span class="property-price">₹ {{property.inputname_monday |
                                number:'1.0':'en-US'}}</span> / <span><s class="straight">₹ {{property.inputname_monday *
                                  (1 + 0.35) | number: '2.'}}</s> Night Onwards</span>

                              
                              <p class="loct" >
                                <a href="#" class="more-details"><i class="fa_home fa-map-marker" aria-hidden="true"
                                    style="color: #e7b315;"></i>
                                  {{property.locname}}</a>
                              </p>
                              <ul class="roominn">
                                
                                <li ><i aria-hidden="true" class="fa_home fa-bed"></i>
                                  {{property.bed}} BHK</li>


                                
                                <li ><i class="fa_home fa-bath" aria-hidden="true"></i>
                                  {{property.bathrooms}} Bathrooms</li>

                              
                                <li ><i aria-hidden="true" class="fa_home fa-users"></i> Max
                                  {{property.guest}} Guest</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                </owl-carousel-o>


              </div>
            </div>
            <div class="view-more-villa">
              <a href="near_mumbai">
                <div class="hovertext" data-hover="Explore More">
                  <i class="fa fa-caret-right"></i>
                </div>
                
                <div class="Mydivname">
                  <a href="near_mumbai"> Explore More</a>               
                </div>
              </a>
            </div>
          </div>
                   
          <div id="tab2" class="tab-pane fade">
            <div class="container containerss">
              <div class="row">
                
                <owl-carousel-o [options]="customOptions" class="owl-carouselss">
                  <ng-container *ngFor="let propertygoa of propertyDataGoa">
                    <ng-template carouselSlide >
                      <div class="item">
                        <div class="bsp_bbb_item">
                          <div class="image-box">
                            <img ngSrc="{{imageSrc}}{{propertygoa.image}}" width="325" height="200" alt="{{propertygoa.image}}" priority>
                            <a routerLink="property/location/villas-in-Goa/{{propertygoa.permalink}}" class="more-details" target="_blank">More Details</a>
                          </div>

                          <div class="featured_details">
                            <div class="bsp_card-title_home margin0 " style="margin: 0px !important;">
                              <p class="bsp_card-text">{{propertygoa.property_name}}</p>
                            </div>
                            
                            <div class="text-left">
                              <span>₹ {{propertygoa.inputname_monday | number:'1.0':'en-US'}}</span> / <span><s
                                  class="straight">₹{{propertygoa.inputname_monday * (1 + 0.35) | number: '2.'}}</s> Night
                                Onwards</span>

                              <p class="loct">
                                <a href="#" class="more-details"><i class="fa fa-map-marker" aria-hidden="true"></i>
                                  {{propertygoa.locname}}</a>
                              </p>
                              <ul class="roominn">
                                <li><i aria-hidden="true" class="fa_home fa-bed"></i> {{propertygoa.bed}} BHK</li>
                                <li><i class="fa_home fa-bath" aria-hidden="true"></i> {{propertygoa.bathrooms}} Bathrooms
                                </li>
                                <li><i aria-hidden="true" class="fa_home fa-users"></i> Max {{propertygoa.guest}} Guest
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                </owl-carousel-o>
              </div>
            </div>
            <div class="view-more-villa">
              <a routerLink="/property/state/villas-in-Goa">
                <div class="hovertext" data-hover="Explore More">
                  <i class="fa fa-caret-right"></i>
                </div>
                <div class="Mydivname">
                  <a routerLink="/property/state/villas-in-Goa"> Explore More</a>           
                </div>
              </a>
            </div>
            <div *ngIf="loadgoa" class="loading-prop">
              <div id="loading-bar-spinner" class="spinner"><div class="spinner-icon"></div></div>
            </div>
          </div>

          <div id="tab3" class="tab-pane fade">
            <div class="container containerss">
              <div class="row">
                <owl-carousel-o [options]="customOptions" class="owl-carouselss">
                  <ng-container *ngFor="let propertynorth of propertyDataNorth">
                    <ng-template carouselSlide *ngIf="propertynorth?.status == 1">
                      <div class="item">
                        <div class="bsp_bbb_item">
                          <div class="image-box">
                            <img ngSrc="{{imageSrc}}{{propertynorth.image}}" width="325" height="200" alt="{{propertynorth.image}}" priority>
                            <a routerLink="property/location/villas-in-North-India/{{propertynorth.permalink}}" class="more-details" target="_blank">More Details</a>                            
                          </div>

                          <div class="featured_details">
                            <div class="bsp_card-title_home">
                              <p class="bsp_card-text">{{propertynorth.property_name}}</p>
                            </div>
                            <div class="text-left">

                              <span>₹ {{propertynorth.inputname_monday | number:'1.0':'en-US'}}</span> / <span><s
                                  class="straight">₹{{propertynorth.inputname_monday * (1 + 0.35) | number: '2.'}}</s>
                                Night Onwards</span>
                              <p class="loct">
                                <a href="#" class="more-details"><i class="fa fa-map-marker" aria-hidden="true"></i>
                                  {{propertynorth.locname}}</a>
                              </p>
                              <ul class="roominn">
                                <li><i aria-hidden="true" class="fa_home fa-bed"></i> {{propertynorth.bed}} BHK</li>
                                <li><i class="fa_home fa-bath" aria-hidden="true"></i> {{propertynorth.bathrooms}}
                                  Bathrooms</li>
                                <li><i aria-hidden="true" class="fa_home fa-users"></i> Max {{propertynorth.guest}} Guest
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </ng-container>
                </owl-carousel-o>
              </div>
            </div>
            <div class="view-more-villa">
              <a href="north_india">
                <div class="hovertext" data-hover="Explore More">
                  <i class="fa fa-caret-right"></i>
                </div>
                <div class="Mydivname">
                  <a href="north_india"> Explore More</a>             
                </div>
              </a>
            </div>
            <div *ngIf="loadnorth" class="loading-prop">
              <div id="loading-bar-spinner" class="spinner"><div class="spinner-icon"></div></div>
            </div>
          </div>


        </div>

      </div>

    </div>  

  </div>

  

</div>

<style>
  .tab-pane {
    display: block !important;
    height: 0;
    overflow: visible;
  }
</style>