import { Component, OnInit } from '@angular/core';
import { homeadservice } from '../../services/homeadservice';
import { imagepathservice } from '../../services/imagepathservice';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-homeads',
  templateUrl: './homeads.component.html',
  styleUrls: ['./homeads.component.css']
})
export class HomeadsComponent implements OnInit {
  imageSrc = 'https://'+environment.domain+'/public/uploads/images/';
  
  homeadData: any = [];
	constructor(public homeadservice: homeadservice) {
		homeadservice.getHomeads().subscribe((response:any) =>
		{
			this.homeadData = response['data'];
		});
	}
  

  ngOnInit(): void {
  }

}
