<div class="benefit-contact-us">
  <div class="container">
    <div class="row heading">
      <div class="col-md-12">
        <h2 style="margin: 0">
          CONTACT <span style="color: #e7b315">US</span>
        </h2>
        <img src="assets/img/heding-icon.png" alt="" />
      </div>
    </div>
    <div class="benefits-text">
      You've put in your time, money & sweat on building your estate. You can
      now leave it at hands of professional to better manage your property.
      Kindly fill in these details or you reach out to us on
      <a href="mailto:alok@ekostay.com"> uzmanaaz@ekostay.com</a>
      <a href="mailto:alok@ekostay.com"> varun.a@ekostay.com</a>
    </div>
    <form [formGroup]="partnerForm" (submit)="submitContactForm()">
      <div class="row" *ngIf="showError" style="margin-top: 10px">
        <div class="col-md-12">
          <label style="color: rgb(128, 0, 0)"
            >Please fill required feilds name and email</label
          >
        </div>
      </div>
      <div class="row" *ngIf="showStatus" style="margin: 10px 0px">
        <div class="col-md-12">
          <label style="color: green"
            >We just received your message. Thank you very much for writing to
            us.</label
          >
        </div>
      </div>
      <div class="benefit-contact-us-form">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <input
                type="text"
                placeholder="Name *"
                formControlName="name"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <input
                type="email"
                placeholder="Email ID *"
                formControlName="email"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <input
                type="tel"
                placeholder="Contact Number"
                formControlName="phone"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <input
                type="text"
                placeholder="Location of the property"
                formControlName="location"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="Area of the House"
                    formControlName="area"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label
                    ><input
                      type="radio"
                      name="areaSize"
                      value="Sq.Ft."
                      formControlName="areaSize"
                    />
                    Sq.Ft.</label
                  >
                  &nbsp;
                  <label
                    ><input
                      type="radio"
                      formControlName="areaSize"
                      value="Sq.Meter"
                      name="areaSize"
                    />
                    Sq.Meter</label
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <input
                type="text"
                placeholder="No. of Bedrooms"
                formControlName="bedrooms"
                class="form-control"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <input
                type="text"
                placeholder="No. of Bathrooms"
                formControlName="bathrooms"
                class="form-control"
              />
            </div>
          </div>
       
          <div class="col-md-4">
            <div class="form-group">
              <div class="label-head">Does it have a Swimming Pool ?</div>
              <label
                ><input
                  type="radio"
                  name="sp"
                  value="Yes"
                  formControlName="sp"
                />
                Yes</label
              >
              &nbsp;
              <label
                ><input
                  type="radio"
                  name="sp"
                  formControlName="sp"
                  value="No"
                />
                No</label
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <div class="label-head">Does it have a caretaker ?</div>
              <label
                ><input
                  type="radio"
                  name="caretaker"
                  value="Yes"
                  formControlName="caretaker"
                />
                Yes</label
              >
              &nbsp;
              <label
                ><input
                  type="radio"
                  name="caretaker"
                  formControlName="caretaker"
                  value="No"
                />
                No</label
              >
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <div class="label-head">Condition of the house ?</div>
              <label
                ><input
                  type="radio"
                  name="condition"
                  value="Perfect"
                  formControlName="condition"
                />
                Perfect</label
              >
              &nbsp;
              <label
                ><input
                  type="radio"
                  name="condition"
                  value="Decent"
                  formControlName="condition"
                />
                Decent
              </label>
              &nbsp;
              <label
                ><input
                  type="radio"
                  name="condition"
                  value="Requires work"
                  formControlName="condition"
                />
                Requires work
              </label>
            </div>
          </div>
          <div class="col-md-12 text-center">
            <div class="form-group">
              <input
                type="submit"
                value="Submit"
                class="submit_button btn btn-default"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
