import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { Title, Meta, MetaDefinition } from "@angular/platform-browser";
import * as AOS from "aos";

@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.css"],
})
export class AboutUsComponent implements OnInit {
  selectedStep = 0;
  viewMode = "2020";
  iflogin: boolean = false;
  ifnotlogin: boolean = true;
  isBrowser:boolean;

  constructor(
    private metaService: Meta,
    private title: Title,
    private _formBuilder: FormBuilder,
    @Inject(PLATFORM_ID) platformId: Object
  ) {

    this.isBrowser = isPlatformBrowser(platformId);
    this.title.setTitle("Ekostay - About Us");
    this.metaService.addTag({
      name: "description",
      content:
        "At EKOSTAY we offer you comfort at our private homes/villas/apartments that you can share with your loved ones.",
    });
  }

  ngOnInit(): void {
    if(this.isBrowser){
      
      AOS.init();
      window.addEventListener("load", AOS.refresh);
    }
  }

  scrollWins() {
    if(this.isBrowser){

      window.scrollTo({
        top: 720,
        behavior: "smooth",
      });
    }
  }

  firstFormGroup = this._formBuilder.group({
    firstCtrl: ["", Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ["", Validators.required],
  });
  isLinear = false;

  selectSchange(event: any) {
    this.selectedStep = event.selectedIndex;
  }
}
