import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { IeSortPipe } from 'src/app/sort-pipe'
import { MaterialModule } from '../material/material.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2CompleterModule } from 'ng2-completer';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SwiperModule } from 'swiper/angular';
import { ToastrModule } from 'ngx-toastr';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { PropertyCardComponent } from 'src/app/components/property-card/property-card.component';
import { NumberonlyDirective } from 'src/app/directive/numberonly.directive';
import { MatSliderModule } from '@angular/material/slider';

const sharedModule: any[] = [
  MaterialModule,
  NgOptimizedImage,
  InfiniteScrollModule,
  CarouselModule,
  FormsModule,
  NgxUsefulSwiperModule,
  ReactiveFormsModule,
  Ng2CompleterModule,
  NgxSpinnerModule,
  NgxSkeletonLoaderModule,
  SlickCarouselModule,
  SwiperModule,
  ToastrModule.forRoot(),
  NgxGoogleAnalyticsModule.forRoot("UA-118643405-3"),
  MatSliderModule
  
];
const sharedComponents: any[] = [IeSortPipe, PropertyCardComponent, NumberonlyDirective];
@NgModule({
  imports: [CommonModule, ...sharedModule],
  declarations: [...sharedComponents],
  providers: [],
  exports: [...sharedModule, ...sharedComponents],
})
export class SharedModule {}

