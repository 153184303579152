<span class="back_button"
  ><i class="fa fa-arrow-left" aria-hidden="true" (click)="closeDialog()"></i
></span> 

<div class="col-md-4 filter-feild" style="margin-top: 10px;">
    <div class="form-group new-form">
      
        <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" class="form-control" placeholder="Location" (ngModelChange)="onLocationChange($event)">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (opened)="autoCompleteOpen()"
        #firstBox>
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
            <i class="fa fa-map-marker " style="color: #000;"></i>&nbsp;
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
    </div>
    
  </div>