<main class="desktop_nav">
  <div class="pop_bg_main" *ngIf="div1">
    <div class="contact-form-wrapper">
      <!-- <div class="add-extra"> -->
        <span class="back_button"
        ><i class="fa fa-close" aria-hidden="true" (click)="pop_bg_main_hide()"></i
      ></span> 
        <h2 class="offer-heading">Plan Your Getaway</h2>
        <form [formGroup]="contactForm" (submit)="sendContact()">
          <div class="row" *ngIf="showStatus" style="margin: 10px 0px">
            <div class="col-md-12">
              <label style="color: green"
                >We just received your message. Thank you very much for writing to
                us.</label
              >
            </div>
          </div>
          <div class="contact-form" *ngIf="hideForm">
            <div class="form-group">
              <input
                type="text"
                placeholder="Name *"
                formControlName="name"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <input
                type="tel"
                placeholder="Phone No. *"
                formControlName="phone"
                class="form-control"
                appNumberonly
              />
              <div class="row" *ngIf="showError && contactForm.get('phone')?.invalid">
                <div class="col-md-12" class="error-feild">
                  <label style="color: #fff; margin-left: 25px;">{{ phoneErrorMessage }}</label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <input
                type="text"
                placeholder="Email id"
                formControlName="email"
                class="form-control"
              />
              <div class="row" *ngIf="showError && contactForm.get('email')?.invalid">
                <div class="col-md-12" class="error-feild">
                  <label style="color: #fff; margin-left: 25px;">{{ emailErrorMessage }}</label>
                </div>
              </div>
            </div>
            <div class="form-group mat-class">
              <mat-form-field appearance="outline">
                <mat-date-range-input
                  [rangePicker]="picker"
                  [min]="minDate"
                  class="form-control check-date-check"
                >
                  <input
                    class="villa_checkin discount_feild"
                    matStartDate
                    matInput
                    placeholder="Check in"
                    formControlName="checkin"
                    (focus)="picker.open()"
                  />
                  <input
                    class="villa_checkin discount_feild"
                    matEndDate
                    matInput
                    (focus)="picker.open()"
                    placeholder="Check out"
                    formControlName="checkout"
                    id="datepicker"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matPrefix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker
                #overlayContainer #picker (opened)="onPickerOpened()" (closed)="onPickerClosed()"
                ></mat-date-range-picker>
              </mat-form-field>
              <div class="row" *ngIf="showError && (contactForm.get('checkin')?.invalid || contactForm.get('checkout')?.invalid)">
                <div class="col-md-12" class="error-feild">
                  <label style="color: #fff; margin-left: 25px;">{{ dateErrorMessage }}</label>
                </div>
              </div>
              </div>
            <div class="form-group icon-class">
              <i class="fa fa-map-marker"></i>
              <input
                type="text"
                placeholder="Prefered location"
                formControlName="location"
                class="form-control input-class"
              />
              <div class="row" *ngIf="showError && contactForm.get('location')?.invalid">
                <div class="col-md-12" class="error-feild">
                  <label style="color: #fff; margin-left: 25px;">{{ locationErrorMessage }}</label>
                </div>
              </div>
            </div>
            <div class="form-group icon-class">
              <i class="fa fa-pencil"></i>
              <input
              placeholder="Anything else you would like to let us know"
              formControlName="customer_message"
              class="form-control input-class"
            />
            </div>
            <h2 class="sales_exe">Are You Connected with any Sales Executive?</h2>
            <div class="booking_for">
              <div class="for_up">
                <input
                  type="radio"
                  id="yes"
                  formControlName="sales_executive"
                  value="yes"
                />
                <label for="yes" class="font_style_title">Yes</label>
              </div>
              <div class="for_up salesClass">
                <input
                  type="radio"
                  id="no"
                  formControlName="sales_executive"
                  value="no"
                />
                <label for="no" class="font_style_title">No</label>
              </div>
            </div>
            <div
            class="form-group"
            *ngIf="contactForm.get('sales_executive')?.value === 'yes'"
          >
            <input
              type="text"
              placeholder="Sales Executive Name"
              formControlName="sales_executive_name"
              class="form-control"
            />
            <div class="row" *ngIf="showError && contactForm.get('sales_executive_name')?.invalid">
              <div class="col-md-12" class="error-feild">
                <label style="color: #fff; margin-left: 25px;">{{ salesExecutiveNameErrorMessage }}</label>
              </div>
            </div>
          </div>
            <div class="form-group submit-form">
              <input type="submit" class="btn btn-default" value="Avail Special Offers" />
            </div>
          </div>
        </form>
    </div>
  </div>

  <div class="header">
    <div class="head-top" *ngIf="divhidetop">
      <div class="container header-part">
        <div class="row hide-phone">
          <div class="col-md-10">
            <p
              class="other-text"
              style="text-align: center !important"
              *ngIf="!isGold && !isLuxe && !isFeatured && !ekoElement"
            >
              FLAT 26% OFF ON ALL PROPERTIES.
            </p>
            <p
              class="other-text"
              style="text-align: center !important"
              *ngIf="!isGold && !isLuxe && !isFeatured && !ekoElement"
            >
              <img
                class="gift-icon"
                src="assets/img/gift.png"
                width="20px"
                height="20px"
                alt="gift-icon"
                loading="lazy"
              />The Great Gataway Sale<a
                routerLink="property/GGS15"
                style="text-decoration: underline; padding-left: 5px"
                >Check Now</a
              >
            </p>
            <p
              class="other-text1"
              style="text-align: center !important"
              *ngIf="isGold"
            >
              EKO GOLD
            </p>
            <p
              class="other-text1"
              style="text-align: center !important"
              *ngIf="isLuxe"
            >
              EKO LUXE
            </p>
            <p
              class="other-text1"
              style="text-align: center !important"
              *ngIf="isFeatured"
            >
              FEATURED PROPERTY
            </p>
            <p
              class="other-text1"
              style="text-align: center !important"
              *ngIf="ekoElement"
            >
              {{ ekoElementData.circleSectionTitle }} -
              {{ ekoElementData.circleSectionSubtitle }}
            </p>
          </div>
          <div class="col-md-2 hidden-xs col-sm-6">
            <ul class="d-flex float-right">
              <li>
                <button class="btn-close-icon" (click)="hide_top_bar()">
                  <!-- <img
                    class="close-icon"
                    src="assets/logo/error1.png"
                    alt="phone-icon"
                  /> -->
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div class="row visible-phone">
          <div class="col-sm-12">
            <p class="other-text" style="text-align: center !important">
              FLAT 26% OFF ON ALL PROPERTIES.
            </p>
            <p class="other-text" style="text-align: center !important">
              Click to call <a href="tel:+918169019090">+91 8169019090</a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="head-bot" id="myHeader">
      <div class="nav_container">
        <nav class="navbar" role="navigation">
          <div class="row">
            <div class="col-md-5 col-xs-6">
              <div class="navbar-header mob">
                <a routerLink="/" class="navbar-brand scroll-top">
                  <img src="assets/img/logo.png" alt="logo" loading="lazy"/>
                </a>
              </div>
              <div
                id="main-nav"
                class="collapse navbar-collapse"
                *ngIf="menuVisible"
              >
                <ul class="nav navbar-nav navbar-left">
                  <li><a routerLink="/" class="scroll-link active">Home</a></li>

                  <li>
                    <a
                      href="#"
                      data-toggle="dropdown"
                      class="dropdown-toggle"
                      data-id="location"
                      (click)="showNavigationMenu(); showNavigationMenusub()"
                      >Location <span class="caret"></span>
                    </a>
                    <ul
                      class="dropdown-menu first maindad"
                      *ngIf="submenuVisible"
                      style="background-color: black; width: 100%"
                    >
                      <li *ngFor="let headerdatastate of headerData">
                        <!-- href="/property/state/{{ headerdatastate.permalink }}" -->
                        <a
                          *ngIf="headerdatastate.state !== 'Pondicherry'"
                          data-toggle="dropdown5"
                          class="dropdown-toggle"
                          data-id="city"
                          (click)="hideNavigationMenu('state', headerdatastate)"
                          >{{ headerdatastate.state }}
                          <span class="expand"
                            ><i
                              class="fa fa-angle-right"
                              style="margin-top: 0px; float: right"
                            ></i
                          ></span>
                        </a>
                        <ul
                          class="dropdown-menu secnd child"
                          style="background-color: black"
                        >
                          <li
                            *ngFor="
                              let headerdatamain of headerdatastate.locations
                            "
                          >
                          <!-- href="/property/location/{{
                            headerdatamain.Permalink
                          }}" -->
                            <a
                              *ngIf="
                                headerdatamain.name !== 'Arambol' &&
                                headerdatamain.name !== 'Pune' &&
                                headerdatamain.name !== 'Kunigal' &&
                                headerdatamain.name !== 'Noida' &&
                                headerdatamain.name !== 'Anjuna' &&
                                headerdatamain.name !== 'Rest of North Goa' &&
                                headerdatamain.name !== 'Candolim' &&
                                headerdatamain.name !== 'Arambol' &&
                                headerdatamain.name !== 'South Goa'
                              "
                               [routerLink]="['/property/location', headerdatamain.Permalink]"
                              (click)="hideNavigationMenu('location', headerdatamain)"
                              >{{ headerdatamain.name }}</a
                            >
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <!-- routerLink="#" -->
                    <a data-toggle="dropdown" class="dropdown-toggle"
                      >Luxury <span class="caret"></span
                    ></a>
                    <ul
                      class="dropdown-menu first"
                      style="background-color: black; width: 100%"
                    >
                      <li>
                        <a
                          [routerLink]="['/', 'gold']"
                          data-id="about"
                          (click)="hideNavigationMenu()"
                        >
                          EKO Gold</a
                        >
                      </li>
                      <li>
                        <a
                          [routerLink]="['/', 'luxe']"
                          (click)="hideNavigationMenu()"
                          >EKO Luxe</a
                        >
                      </li>
                    </ul>
                  </li>

                  <li>
                    <!-- routerLink="#" -->
                    <a data-toggle="dropdown" class="dropdown-toggle"
                      >About Us <span class="caret"></span
                    ></a>
                    <ul
                      class="dropdown-menu first"
                      style="background-color: black; width: 100%"
                    >
                      <li>
                        <a
                          [routerLink]="['/', 'about-us']"
                          data-id="about"
                          (click)="hideNavigationMenu()"
                        >
                          Our Story</a
                        >
                      </li>
                      <li>
                        <a
                          [routerLink]="['/', 'ourteam']"
                          (click)="hideNavigationMenu()"
                          >Our Team</a
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-md-2 col-xs-3">
              <button
                type="button"
                class="navbar-toggle"
                (click)="showNavigationMenu()"
                data-toggle="collapse"
                data-target="#main-nav"
              >
                <label class="hamburger">
                  <input type="checkbox" />
                  <svg viewBox="0 0 32 32" onClick="showNavigationMenu()">
                    <path
                      class="line line-top-bottom"
                      d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
                    ></path>
                    <path class="line" d="M7 16 27 16"></path>
                  </svg>
                </label>
              </button>

              <div class="navbar-header desk">
                <a routerLink="/" class="navbar-brand scroll-top">
                  <img src="assets/img/logo.png" alt="logo" loading="lazy"
                /></a>
              </div>
            </div>

            <div class="col-md-5 col-xs-3">
              <ul class="nav navbar-nav">
                <li>
                  <a
                    [routerLink]="['/', 'partner-with-us']"
                    data-id="partner-with-us"
                    (click)="hideNavigationMenu()"
                    >Partner With Us</a
                  >
                </li>
                <li>
                  <a
                    [routerLink]="['/', 'contact']"
                    data-id="contact-us"
                    (click)="hideNavigationMenu()"
                    >Contact Us</a
                  >
                </li>
                <li>
                  <a
                    [routerLink]="['/', 'membership']"
                    data-id="membership"
                    class="mbrbtn"
                    (click)="hideNavigationMenu()"
                    >Membership</a
                  >
                </li>
                <li *ngIf="ifnotlogin">
                  <a [routerLink]="['/', 'login']">Login </a>
                </li>
                <li *ngIf="iflogin">
                  <a
                    [routerLink]="['/', 'account']"
                    data-toggle="dropdown"
                    class="dropdown-toggle"
                    data-id="location"
                    (click)="showNavigationMenu(); showNavigationMenusub()"
                    ><i class="fa fa-user" aria-hidden="true"></i> Hi,
                    {{ datas }} <span class="caret"></span>
                  </a>
                  <ul
                    class="dropdown-menu first maindad"
                    *ngIf="submenuVisible"
                    style="background-color: #000; width: 100%; color: #fff;"
                  >
                    <li>
                      <a href="javascript:void(0)" (click)="logout()" class="log-mob">
                        Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div class="col-md-1 col-xs-3">
              <ul class="nav navbar-nav navbar-right rt">
                <li *ngIf="ifnotlogin">
                  <a [routerLink]="['/', 'login']"
                    ><i class="fa fa-user" aria-hidden="true"></i> Login
                  </a>
                </li>
                <li *ngIf="iflogin">
                  <a
                    [routerLink]="['/', 'account']"
                    data-toggle="dropdown"
                    class="dropdown-toggle"
                    data-id="location"
                    (click)="showNavigationMenu(); showNavigationMenusub()"
                    ><i class="fa fa-user" aria-hidden="true"></i> Account
                    <span class="caret"></span>
                  </a>
                  <ul
                    class="dropdown-menu first maindad"
                    *ngIf="submenuVisible"
                    style="background-color: #000; width: 100%; color: #fff;"
                  >
                    <li>
                      <a href="javascript:void(0)" (click)="logout()" class="log-mob">
                        Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</main>

<!-- ----------------Mobile Header------------------ -->

<main class="mobile_nav" *ngIf="!isBookingWidgetOpen">
  <div class="pop_bg_main" *ngIf="div1">
    <div class="contact-form-wrapper">
      <div class="add-extra">
        <span class="back_button"
        ><i class="fa fa-close" aria-hidden="true" (click)="pop_bg_main_hide()"></i
      ></span> 
        <h2 class="offer-heading">Plan Your Getaway</h2>
        <form [formGroup]="contactForm" (submit)="sendContact()">
          <div class="row" *ngIf="showStatus" style="margin: 10px 0px">
            <div class="col-md-12">
              <label style="color: green"
                >We just received your message. Thank you very much for writing to
                us.</label
              >
            </div>
          </div>
          <div class="contact-form" *ngIf="hideForm">
            <div class="form-group">
              <input
                type="text"
                placeholder="Name *"
                formControlName="name"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <input
                type="tel"
                placeholder="Phone No. *"
                formControlName="phone"
                class="form-control"
                appNumberonly
              />
              <div class="row" *ngIf="showError && contactForm.get('phone')?.invalid">
                <div class="col-md-12" class="error-feild">
                  <label style="color: #fff; margin-left: 25px;">{{ phoneErrorMessage }}</label>
                </div>
              </div>
            </div>
            <div class="form-group">
              <input
                type="text"
                placeholder="Email id"
                formControlName="email"
                class="form-control"
              />
              <div class="row" *ngIf="showError && contactForm.get('email')?.invalid">
                <div class="col-md-12" class="error-feild">
                  <label style="color: #fff; margin-left: 25px;">{{ emailErrorMessage }}</label>
                </div>
              </div>
            </div>
            <div class="form-group mat-class">
              <mat-form-field appearance="outline">
                <mat-date-range-input
                  [rangePicker]="picker"
                  [min]="minDate"
                  class="form-control check-date-check"
                >
                  <input
                    class="villa_checkin discount_feild"
                    matStartDate
                    matInput
                    placeholder="Check in"
                    formControlName="checkin"
                    (focus)="picker.open()"
                  />
                  <input
                    class="villa_checkin discount_feild"
                    matEndDate
                    matInput
                    (focus)="picker.open()"
                    placeholder="Check out"
                    formControlName="checkout"
                    id="datepicker"
                  />
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matPrefix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker
                #overlayContainer #picker (opened)="onPickerOpened()" (closed)="onPickerClosed()"
                ></mat-date-range-picker>
              </mat-form-field>
              </div>
              <div class="row" *ngIf="showError && (contactForm.get('checkin')?.invalid || contactForm.get('checkout')?.invalid)">
                <div class="col-md-12" class="error-feild">
                  <label style="color: #fff; margin-left: 25px;">{{ dateErrorMessage }}</label>
                </div>
              </div>
            <div class="form-group icon-class">
              <i class="fa fa-map-marker"></i>
              <input
                type="text"
                placeholder="Prefered location"
                formControlName="location"
                class="form-control input-class"
              />
              <div class="row" *ngIf="showError && contactForm.get('location')?.invalid">
                <div class="col-md-12" class="error-feild">
                  <label style="color: #fff; margin-left: 25px;">{{ locationErrorMessage }}</label>
                </div>
              </div>
            </div>
            <div class="form-group icon-class">
              <i class="fa fa-pencil"></i>
              <input
              placeholder="Anything else you would like to let us know"
              formControlName="customer_message"
              class="form-control input-class"
            />
            </div>
            <h2 class="sales_exe">Are You Connected with any Sales Executive?</h2>
            <div class="booking_for">
              <div class="for_up">
                <input
                  type="radio"
                  id="yes"
                  formControlName="sales_executive"
                  value="yes"
                />
                <label for="yes" class="font_style_title">Yes</label>
              </div>
              <div class="for_up salesClass">
                <input
                  type="radio"
                  id="no"
                  formControlName="sales_executive"
                  value="no"
                />
                <label for="no" class="font_style_title">No</label>
              </div>
            </div>
            <div
            class="form-group"
            *ngIf="contactForm.get('sales_executive')?.value === 'yes'"
          >
            <input
              type="text"
              placeholder="Sales Executive Name"
              formControlName="sales_executive_name"
              class="form-control"
            />
            <div class="row" *ngIf="showError && contactForm.get('sales_executive_name')?.invalid">
              <div class="col-md-12" class="error-feild">
                <label style="color: #fff; margin-left: 25px;">{{ salesExecutiveNameErrorMessage }}</label>
              </div>
            </div>
          </div>
            <div class="form-group submit-form">
              <input type="submit" class="btn btn-default" value="Avail Special Offers" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="header">
    <div class="head-bot" id="myHeader">
      <div class="nav_container">
        <nav class="navbar" role="navigation">
          <div class="">
            <div class="col-md-2 col-xs-3 class-logo">
              <button
                type="button"
                class="navbar-toggle"
                (click)="showNavigationMenu()"
                data-toggle="collapse"
                data-target="#main-nav-new"
              >
                <label class="hamburger">
                  <input type="checkbox" />
                  <svg viewBox="0 0 32 32" onClick="showNavigationMenu()">
                    <path
                      class="line line-top-bottom"
                      d="M27 10 13 10C10.8 10 9 8.2 9 6 9 3.5 10.8 2 13 2 15.2 2 17 3.8 17 6L17 26C17 28.2 18.8 30 21 30 23.2 30 25 28.2 25 26 25 23.8 23.2 22 21 22L7 22"
                    ></path>
                    <path class="line" d="M7 16 27 16"></path>
                  </svg>
                </label>
              </button>

              <div class="navbar-header desk">
                <a routerLink="/" class="navbar-brand scroll-top">
                  <img src="assets/img/logo.png" alt="logo" loading="lazy"
                /></a>
              </div>
            </div>
            <div class="col-md-8 col-xs-6 class-middle">
              <div class="navbar-header mob">
                <a routerLink="/" class="navbar-brand scroll-top">
                  <img src="assets/img/logo.png" alt="logo" loading="lazy"
                /></a>
              </div>
              <div
                id="main-nav-new"
                class="collapse navbar-collapse"
               *ngIf="menuVisible"
              >
              <i class="fa fa-close" (click)="hideNavigationMenu()" style="margin-top: 20px;"></i>
                <ul class="nav navbar-nav navbar-right">
                  <li><a routerLink="/" class="scroll-link active" (click)="hideNavigationMenu();menuVisible = false" style="margin-top: 30px;">Home</a></li>

                  <li>
                    <a
                      href="#"
                      data-toggle="dropdown"
                      class="dropdown-toggle"
                      data-id="location"
                      (click)="showNavigationMenu(); showNavigationMenusub()"
                      >Location <span class="caret"></span>
                    </a>
                    <ul
                      class="dropdown-menu first maindad"
                      *ngIf="submenuVisible"
                      style="background-color: white; width: 100%"
                    >
                      <li *ngFor="let headerdatastate of headerData; let in = index">
                        <!-- href="/property/state/{{ headerdatastate.permalink }}" -->
                        <a
                          *ngIf="headerdatastate.state !== 'Pondicherry'"
                          data-toggle="dropdown5"
                          class="dropdown-toggle"
                          data-id="city"
                          (click)=" headerdatastate.subMenu = (!headerdatastate.subMenu || menuVisibilityIndex != in) ; menuVisibilityIndex = in; hideNavigationMenu('state', headerdatastate);handleClick(headerdatastate)"
                          >{{ headerdatastate.state }}
                          <span class="expand"
                            ><i
                              class="fa fa-angle-right"
                              style="margin-top: 0px; float: right"
                            ></i
                          ></span>
                        </a>
                        <ul
                          [style]="{display: headerdatastate.subMenu && menuVisibilityIndex == in ? 'block !important': 'none !important'}"
                          class="dropdown-menu secnd child"
                          style="background-color: black"
                        >
                          <li
                            *ngFor="
                              let headerdatamain of headerdatastate.locations
                            "
                          >
                            <a
                              *ngIf="
                                headerdatamain.name !== 'Arambol' &&
                                headerdatamain.name !== 'Pune' &&
                                headerdatamain.name !== 'Kunigal' &&
                                headerdatamain.name !== 'Noida' &&
                                headerdatamain.name !== 'Anjuna' &&
                                headerdatamain.name !== 'Rest of North Goa' &&
                                headerdatamain.name !== 'Candolim' &&
                                headerdatamain.name !== 'Arambol' &&
                                headerdatamain.name !== 'South Goa'
                              "
                           (click)="navigateByLocation(headerdatamain)"
                              >{{ headerdatamain.name }}</a
                            >
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <!-- routerLink="#" -->
                    <a data-toggle="dropdown" class="" (click)="showLuxe()"
                      >Luxury <span class="caret"></span
                    ></a>
                    <ul
                      class="dropdown-menu"
                      *ngIf="showLuxeMenu"
                      style="background-color: #fff; width: 100%; height: 9rem;" 
                    >
                      <li>
                        <a
                          [routerLink]="['/', 'gold']"
                          data-id="about"
                          (click)="hideNavigationMenu();menuVisible = false"
                        >
                          EKO Gold</a
                        >
                      </li>
                      <li>
                        <a
                          [routerLink]="['/', 'luxe']"
                          (click)="hideNavigationMenu();menuVisible = false"
                          >EKO Luxe</a
                        >
                      </li>
                    </ul>
                  </li>

                  <li>
                    <!-- routerLink="#" -->
                    <a data-toggle="dropdown" class="" (click)="showAboutUs()"
                      >About Us <span class="caret"></span
                    ></a>
                    <ul
                      class="dropdown-menu "
                      *ngIf="showAboutMenu"
                      style="background-color: #fff; width: 100%; height: 9rem;"
                    >
                      <li>
                        <a
                          [routerLink]="['/', 'about-us']"
                          data-id="about"
                          (click)="hideNavigationMenu();menuVisible = false"
                        >
                          Our Story</a
                        >
                      </li>
                      <li>
                        <a
                          [routerLink]="['/', 'ourteam']"
                          (click)="hideNavigationMenu(); menuVisible = false"
                          >Our Team</a
                        >
                      </li>
                    </ul>
                  </li>

                  <li>
                    <a
                      [routerLink]="['/', 'partner-with-us']"
                      data-id="partner-with-us"
                      (click)="hideNavigationMenu(); menuVisible = false"
                      >Partner With Us</a
                    >
                  </li>
                  <li>
                    <a
                      [routerLink]="['/', 'contact']"
                      data-id="contact-us"
                      (click)="hideNavigationMenu();menuVisible = false"
                      >Contact Us</a
                    >
                  </li>
                  <li>
                    <a
                      [routerLink]="['/', 'membership']"
                      data-id="membership"
                      class="mbrbtn"
                      (click)="hideNavigationMenu();menuVisible = false"
                      >EKO Membership</a
                    >
                  </li>
                  <li *ngIf="ifnotlogin">
                    <a [routerLink]="['/', 'login']" (click)="menuVisible = false">Login </a>
                  </li>
                  <li *ngIf="iflogin">
                    <a
                      [routerLink]="['/', 'account']"
                      data-toggle="dropdown"
                      class="dropdown-toggle"
                      data-id="location"
                      (click)="showNavigationMenu(); showNavigationMenusub()"
                      ><i class="fa fa-user" aria-hidden="true"></i> Hi,
                      {{ datas }} <span class="caret"></span>
                    </a>
                    <ul
                      class="dropdown-menu first maindad"
                      *ngIf="iflogin"
                      style="background-color: #000; width: 100%; color: #fff;"
                    >
                      <li>
                        <a href="javascript:void(0)" (click)="logout()" class="log-mob">
                          Logout
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-2 col-xs-6 class-call">
              <div
                id="main-nav-new"
                class="collapse navbar-collapse"
                *ngIf="menuVisible"
              >
                <ul class="nav navbar-nav navbar-right">
                  <li>
                    <a href="tel:+91 8169019090" data-id="partner-with-us"
                      ><img
                        class="phone-icon"
                        src="assets/logo/telephone.png"
                        alt="phone-icon"
                        (click)="telephoneClick()"
                        loading="lazy"
                    /></a>
                  </li>
                  <li>
                    <a
                      href="mailto:reservations@ekostay.com"
                      data-id="contact-us"
                      ><img
                        class="email-icon"
                        src="assets/logo/mail.png"
                        alt="email-icon"
                        (click)="mailClick()"
                        loading="lazy"
                    /></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-md-1 col-xs-3">
              <ul class="nav navbar-nav navbar-right rt">
                <li *ngIf="ifnotlogin" >
                  <a [routerLink]="['/', 'login']" 
                    ><i class="fa fa-user" aria-hidden="true"></i><span class="desk-log"> Login</span>
                  </a>
                </li>
                <li *ngIf="iflogin">
                  <a
                    [routerLink]="['/', 'account']"
                    data-toggle="dropdown"
                    class="dropdown-toggle"
                    data-id="location"
                    (click)="showNavigationMenu(); showNavigationMenusub()"
                    ><i class="fa fa-user" aria-hidden="true"></i> Account
                    <span class="caret"></span>
                  </a>
                  <!-- <ul
                    class="dropdown-menu first maindad"
                    *ngIf="submenuVisible"
                    style="background-color: #000; width: 100%; color: #fff;"
                  >
                    <li>
                      <a href="javascript:void(0)" (click)="logout()" class="log-mob">
                        Logoutsdd
                      </a>
                    </li>
                  </ul> -->
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</main>
