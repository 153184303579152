<button class="filter_close" (click)="closeDialog()">
  <i class="fa fa-chevron-left"></i> Back
</button>
<div class="filter-wrapper filterIn_MBD">
  <!-- <h2>Filters</h2> -->
  <div class="row">
    <!-- location -->
    <!-- <div class="col-md-4 filter-feild">
      <div class="form-group">
        <div class="villa">
          <div
            class="custom-wrapper-example search_item"
            matAutocompleteOrigin
            #origin1="matAutocompleteOrigin"
          >
            <input
              type="text"
              #trigger1="matAutocompleteTrigger"
              matAutocompletePosition="below"
              class="form-control"
              [(ngModel)]="location_property"
              [matAutocomplete]="auto"
              placeholder="location / Villa"
            />
            <mat-icon
              (click)="
                $event.stopPropagation();
                trigger1.panelOpen
                  ? trigger1.closePanel()
                  : trigger1.openPanel()
              "
            >
              <i class="fa fa-caret-down" aria-hidden="true"></i>
            </mat-icon>
          </div>
          <mat-autocomplete
            matAutocompletePosition="below"
            dropdown-arrow="true"
            #auto="matAutocomplete"
          >
            <ng-container *ngFor="let option of LocationPropertyRes">
              <ng-container *ngIf="option.name !== 'Central Head Office'">
                <mat-option [value]="option.name">
                  <img
                    class="image"
                    aria-hidden
                    [src]="option.image"
                    alt="imges-option"
                    style="max-width: 40px; margin-right: 15px"
                  />
                  <span>{{ option.name }}</span>
                </mat-option>
              </ng-container>
            </ng-container>
          </mat-autocomplete>
        </div>
      </div>
    </div> -->

    <div class="col-md-4 filter-feild calendar_place">
      <mat-form-field>
        <mat-date-range-input
          [rangePicker]="pickerrM"
          [min]="minDate"
          class="form-control check-date-check"
        >
          <input
            matStartDate
            #fromInput
            (dateInput)="checkIn(checkin)"
            class="placeholder"
            placeholder="Check in"
            [(ngModel)]="checkin"
            readonly
          />

          <input
            matEndDate
            #toInput
            placeholder="Check out"
            class="placeholder1"
            [(ngModel)]="checkout"
            (dateInput)="checkOut(checkout)"
            id="datepicker"
            readonly
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerrM"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #pickerrM></mat-date-range-picker>
      </mat-form-field>
    </div>

    <div class="col-md-4 filter-feild">
      <div class="form-group">
        <mat-select
          type="text"
          class="form-control"
          id="guest"
          [(ngModel)]="guest"
          placeholder="Guest"
        >
          <mat-option *ngFor="let option of guestoptions" [value]="option">
            {{ option }}
          </mat-option>
        </mat-select>
      </div>
    </div>

    <div class="col-md-4 book-ctr">
      <button
        (click)="sendDataToParent(); closeDialog(true)"
        type="submit"
        class="hero__scroll aos-init aos-animate"
      >
        Search
      </button>
    </div>
  </div>
</div>
