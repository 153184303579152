import {
  BrowserModule,
  Meta,
  provideProtractorTestingSupport,
} from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BlogComponent } from "./components/blog/blog.component";
import { ContactComponent } from "./components/contact/contact.component";
import { PortfolioComponent } from "./components/portfolio/portfolio.component";
import { FeedbackComponent } from "./components/feedback/feedback.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { PropertylistComponent } from "./components/propertylist/propertylist.component";
import { TestimonialsComponent } from "./components/testimonials/testimonials.component";
import { HomeadsComponent } from "./components/homeads/homeads.component";
import { KeepUpdatedComponent } from "./components/keep-updated/keep-updated.component";
import { AboutUsComponent } from "./components/about-us/about-us.component";
import { PartnerWithUsComponent } from "./components/partner-with-us/partner-with-us.component";
import { FaqsComponent } from "./components/faqs/faqs.component";
import { LoginComponent } from "./components/login/login.component";
import {
  AccountComponent,
  DialogContentExampleDialog,
  DialogOverviewExampleDialog,
  MembershipDialog
} from "./components/account/account.component";
import {
  LocationStrategy,
  Location,
  PathLocationStrategy,
} from "@angular/common";
import { locationservice } from "./services/locationservice";
import { propertyservice } from "./services/propertyservice";
import { testimonialservice } from "./services/testimonialservice";
import { detailservice } from "./services/detailservice";
import { aboutservice } from "./services/aboutservice";
import { headerservice } from "./services/headerservice";
import { bookingservice } from "./services/bookingservice";
import { blogservice } from "./services/blogservice";
import { homeadservice } from "./services/homeadservice";
import { imagepathservice } from "./services/imagepathservice";
import { loginservice } from "./services/loginservice";
import { CanonicalService } from "./services/canonical.service";
import { MatChipsModule } from "@angular/material/chips";
import { LocationsComponent } from "./components/locations/locations.component";
import { CarouselModule } from "ngx-owl-carousel-o";
import { GoldComponent } from "./components/gold/gold.component";
import { LuxeComponent } from "./components/luxe/luxe.component";
import { OriginalComponent } from "./components/original/original.component";
import { MembershipComponent } from "./components/membership/membership.component";
import { Ng2CompleterModule } from "ng2-completer";
import { OurteamComponent } from "./components/ourteam/ourteam.component";
import { CareersComponent } from "./components/careers/careers.component";
import { PrivacypolicyComponent } from "./components/privacypolicy/privacypolicy.component";
import { TermsComponent } from "./components/terms/terms.component";
import { ThankYouComponent } from "./components/thank-you/thank-you.component";
import { NearMumbaiComponent } from "./components/nearmumbai/nearmumbai.component";
/* Angular material 8 */
import { AngularMaterialModule } from "./angular-material.module";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from "@angular/core";

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatNativeDateModule,
} from "@angular/material/core";

import {
  AppDateAdapter,
  APP_DATE_FORMATS,
  MY_FORMATS,
} from "./my-date-formats";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";

import { AuthGuard } from "./guards/auth.guard";
import { AuthService } from "./services/auth.service";
import { NgxGoogleAnalyticsModule } from "ngx-google-analytics";
// import { IeSortPipe } from "./sort-pipe";
import { ShareDialogComponent } from "./directives/share-dialog/share-dialog.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { InterceptorService } from "../app/components/loader/interceptor.service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { NgxSpinnerModule } from "ngx-spinner";
import { TestComponent } from "./components/test/test.component";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldModule,
} from "@angular/material/form-field";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { NorthComponent } from "./components/north/north.component";
import { NgOptimizedImage } from "@angular/common";
import { NgxUsefulSwiperModule } from "ngx-useful-swiper";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { DataLayerService } from "./services/data-layer.service";
import { AboutDialogComponent } from "./modules/about/about/dialog.component";
import { CdkAccordionModule } from "@angular/cdk/accordion";
import { ToastrModule, ToastrService } from "ngx-toastr";
import { SwiperModule } from "swiper/angular";
import { GoldLuxeDialogComponent } from "./components/gold/goldLuxe.component";
import { VideoDialogComponent } from "./modules/about/about/video-dialog";
import { PartnerWithContactsDialogComponent } from "./components/partner-with-us/partner-with-contactus-dialog/partner-with-contactus-dialog.component";
import { FilterDialogComponent } from "./components/filter-dialog/filter-dialog.component";
// import { NumberonlyDirective } from "./directive/numberonly.directive";
import { SharedModule } from "./modules/shared/shared.module";
import { PropertyCardComponent } from "./components/property-card/property-card.component";
import { selectOptionDialog } from "./modules/about/about/selectoption-dialog";

@NgModule({
  declarations: [
    NorthComponent,
    TestComponent,
    AppComponent,
    // HomeComponent,
    // AboutComponent,
    BlogComponent,
    ContactComponent,
    PortfolioComponent,
    FeedbackComponent,
    FooterComponent,
    HeaderComponent,
    PropertylistComponent,
    TestimonialsComponent,
    HomeadsComponent,
    KeepUpdatedComponent,
    // MediaPrComponent,
    AboutUsComponent,
    PartnerWithUsComponent,
    FaqsComponent,
    LocationsComponent,
    GoldComponent,
    OriginalComponent,
    LuxeComponent,
    HomeadsComponent,
    MembershipComponent,
    OurteamComponent,
    CareersComponent,
    PrivacypolicyComponent,
    TermsComponent,
    ThankYouComponent,
    LoginComponent,
    AccountComponent,
    ShareDialogComponent,
    // IeSortPipe,
    DialogOverviewExampleDialog,
    DialogContentExampleDialog,
    MembershipDialog,
    NearMumbaiComponent,
    // EkoElementComponent,
    AboutDialogComponent,
    // LandingPageComponent,
    // CheckoutComponent,
    // OfferDialogComponent,
    // LandingPageDialogComponent,
    // FeaturedPropertyVillaComponent,
    GoldLuxeDialogComponent,
    VideoDialogComponent,
    // LanDialogComponent,
    // CustomerReviewDialogComponent,
    // ReviewDialogComponent,
    PartnerWithContactsDialogComponent,
    // ErrorDialogComponent,
    // PropertyCardComponent,
    FilterDialogComponent,
    selectOptionDialog
    // NumberonlyDirective,
  ],
  imports: [
    NgOptimizedImage,
    InfiniteScrollModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CarouselModule,
    FormsModule,
    NgxUsefulSwiperModule,
    ReactiveFormsModule,
    Ng2CompleterModule,
    AngularMaterialModule,
    MatInputModule,
    MatDatepickerModule,
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatFormFieldModule,
    MatListModule,
    MatMenuModule,
    NgxSpinnerModule,
    MatStepperModule,
    MatTooltipModule,
    NgxSkeletonLoaderModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatCheckboxModule,
    SlickCarouselModule,
    NgxGoogleAnalyticsModule.forRoot("UA-118643405-3"),
    CdkAccordionModule,
    SwiperModule,
    ToastrModule.forRoot(),
    SharedModule,
  ],
  exports: [
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill" },
    },

    { provide: LOCALE_ID, useValue: "en-nz" },
    ToastrService,
    Meta,
    locationservice,
    DataLayerService,
    testimonialservice,
    homeadservice,
    imagepathservice,
    propertyservice,
    detailservice,
    aboutservice,
    headerservice,
    bookingservice,
    blogservice,
    loginservice,
    AuthGuard,
    AuthService,
    CanonicalService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
