<main class="page-wrapper">

<section class="contact-page page-section">
	<div class="container">
		<div class="get-in-touch">
			<h2 data-aos="fade-up" data-aos-duration="1000">Get In Touch</h2>
			<p data-aos="fade-up" data-aos-duration="1000">Unlike other hospitality firms, all the properties are owned and run by the EKO STAY management itself. You can thus be rest assured about the consistency in all aspects of your voyage whether it is the stay, food or service, We are simply here to uplift your villa experience to make it more memorable!</p>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="contact-left-side">
					<div class="row">
						<div class="col-xs-6" data-aos="fade-up" data-aos-duration="1000">
							<div class="info-box" style="font-size: 14px;">
								<i class="fa fa-map-marker"></i>
								 EKOSTAY LLP, Kaliandas Udyog Bhavan, Prabhadevi, Worli, Mumbai-400025 
							</div>
						</div>
						<div class="col-xs-6" data-aos="fade-up" data-aos-duration="1000">
							<div class="info-box alt">
								<i class="fa fa-phone"></i>
								 +91 8169019090 
							</div>
						</div>
						<div class="col-xs-6" data-aos="fade-up" data-aos-duration="1000">
							<div class="info-box alt">
								<i class="fa fa-envelope"></i>
								reservations@ekostay.com
							</div>
						</div>
						<div class="col-xs-6" data-aos="fade-up" data-aos-duration="1000">
							<div class="info-box">
								<i class="fa fa-globe"></i>
								www.ekostay.com
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="contact-form-wrapper">
					<h2 data-aos="fade-up" data-aos-duration="1000">Contact Us</h2>
					<form [formGroup]="contactForm" (submit)="sendContact()">
					<div class="row" *ngIf="showError" style="margin-top:10px;" data-aos="fade-up" data-aos-duration="1000">
						<div class="col-md-12">
							<label style="color: rgb(0, 0, 0);">Please fill required feilds name and email</label>
						</div>
					</div>
					<div class="row" *ngIf="showStatus" style="margin:10px 0px;" data-aos="fade-up" data-aos-duration="1000">
						<div class="col-md-12">
							<label style="color: green;">We just received your message. Thank you very much for writing to us.</label>
						</div>
					  </div>	
					<div class="contact-form" data-aos="fade-up" data-aos-duration="1000">
						<div class="form-group">
							<input type="text" placeholder="Name *" formControlName="name" class="form-control">
						</div>
						<div class="form-group" data-aos="fade-up" data-aos-duration="1000">
							<input type="email" placeholder="Email *" formControlName="email" class="form-control">
						</div>
						<div class="form-group" data-aos="fade-up" data-aos-duration="1000">
							<input type="tel" placeholder="Phone" formControlName="phone" class="form-control">
						</div>
						<div class="form-group" data-aos="fade-up" data-aos-duration="1000">
							<textarea placeholder="Message" formControlName="message" class="form-control"></textarea>
						</div>
						<div class="form-group" data-aos="fade-up" data-aos-duration="1000">
							<input type="submit" class="btn btn-default" value="Submit">
						</div>
					</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	
	
	<div class="contact-map" data-aos="fade-up" data-aos-duration="1000"><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d60354.938934331236!2d72.824361!3d19.011626!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7cebe2f36bf21%3A0x9b0adf7a4d8818fd!2s423%20Kaliandas%20Udyog%20Bhavan%2C%20Sadanand%20Hasu%20Tandel%20Marg%2C%20Century%20Bazaar%2C%20Prabhadevi%2C%20Mumbai%2C%20Maharashtra%20400025%2C%20India!5e0!3m2!1sen!2sus!4v1634023966105!5m2!1sen!2sus" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe></div>
</section>

</main>
