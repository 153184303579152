import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from "@angular/forms";
import { Router } from "@angular/router";
import { Title, Meta } from "@angular/platform-browser";
import { bookingservice } from "../../services/bookingservice";
import * as AOS from "aos";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { PartnerWithContactsDialogComponent } from "./partner-with-contactus-dialog/partner-with-contactus-dialog.component";
import { isPlatformBrowser } from "@angular/common";

@Component({
  selector: "app-partner-with-us",
  templateUrl: "./partner-with-us.component.html",
  styleUrls: ["./partner-with-us.component.css"],
})
export class PartnerWithUsComponent implements OnInit {
  partnerForm: UntypedFormGroup;
  public showError: boolean = false;
  public showStatus: boolean = false;
  user: number = 0;
  customer: number = 0;
  locationCounter: number = 0;
  exp: number = 0;
  states: number = 0;
  isadd: boolean = false;
  isBrowser: boolean;

  constructor(
    private metaService: Meta,
    private title: Title,
    public dialog: MatDialog,
    private router: Router,
    private fb: UntypedFormBuilder,
    private bookingservice: bookingservice,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    this.partnerForm = new UntypedFormGroup({
      name: new UntypedFormControl("", Validators.required),
      phone: new UntypedFormControl(),
      email: new UntypedFormControl("", Validators.required),
      location: new UntypedFormControl(),
      area: new UntypedFormControl(),
      areaSize: new UntypedFormControl(),
      bedrooms: new UntypedFormControl(),
      bathrooms: new UntypedFormControl(),
      sp: new UntypedFormControl(),
      caretaker: new UntypedFormControl(),
      condition: new UntypedFormControl(),
    });
  }

  ngOnInit(): void {
    if (this.isBrowser) {
    const usercount = setInterval(() => {
      if (this.isadd) {
        this.user++;
        if (this.user == 5) {
          clearInterval(usercount);
        }
      }
    }, 50);

    const customercount = setInterval(() => {
      if (this.isadd) {
        this.customer++;
        if (this.customer == 300) {
          clearInterval(customercount);
        }
      }
    }, 50);

    const locationCount = setInterval(() => {
      if (this.isadd) {
        this.locationCounter++;
        if (this.locationCounter == 15) {
          clearInterval(locationCount);
        }
      }
    });

     AOS.init();
      window.addEventListener("load", AOS.refresh);
    }

    this.title.setTitle("Ekostay - Partner with us");
  }

  sendContact() {
    var me = this;
    if (this.partnerForm.valid) {
      me.showError = false;
      this.bookingservice
        .partnerForm(this.partnerForm.value)
        .subscribe((data) => {
          me.showStatus = true;
        });
    } else {
      me.showError = true;
    }
  }

  @ViewChild("myDOMElement", { static: true }) MyDOMElement?: ElementRef;

  @HostListener("window:scroll", ["$event"])
  isScrolledIntoView() {
    if (this.isBrowser) {
      if (this.MyDOMElement) {
        const rect = this.MyDOMElement.nativeElement.getBoundingClientRect();
        const topShown = rect.top < window.innerHeight;
        const bottomShown = rect.bottom >= 60;
        this.isadd = topShown && bottomShown;
      }
      if (this.isadd) {
      }
    }
  }

  openContactUsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.maxWidth = "90%";
    dialogConfig.height = "70vh";
    dialogConfig.panelClass = "partnerWith_contactUs_dialog";
    const dialogRef = this.dialog.open(
      PartnerWithContactsDialogComponent,
      dialogConfig
    );
  }
}
