import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-share-dialog',
  templateUrl: './share-dialog.component.html',
  styleUrls: ['./share-dialog.component.css']
})
export class ShareDialogComponent implements OnInit {
  isMobileResolution: boolean;
  postlocation:any;
  constructor() {
    this.popup();
   }

  ngOnInit(): void {

    if (window.innerWidth < 768) {
      this.isMobileResolution = false;
    } else {
      this.isMobileResolution = true;
    }
   
    

  }
  
popup(){
  const facebookBtn = document.querySelector(".facebook-btn");
  const twitterBtn = document.querySelector(".twitter-btn");
  const linkedinBtn = document.querySelector(".linkedin-btn");
  const whatsappBtn = document.querySelector(".whatsapp-btn");


  let postUrl = encodeURI(document.location.href);
  let postTitle = encodeURI("hi everyone please check this out")
  this.postlocation = document.location.href

  facebookBtn?.setAttribute(
    "href", 
    `https://www.facebook.com/sharer.php?u=${postUrl}`
  );
  twitterBtn?.setAttribute(
    "href", 
    `https://twitter.com/share?url=${postUrl}&text=${postTitle}`
  );
  linkedinBtn?.setAttribute(
    "href", 
    `https://www.linkedin.com/shareArticle?url=${postUrl}&title=${postTitle}`
  );
  whatsappBtn?.setAttribute(
    "href", 
    `https://api.whatsapp.com/send?text=${postTitle}${postUrl}`
  );
}


}
