import { Injectable } from '@angular/core';

import {Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';


@Injectable()
export class locationservice {
  isSessionVaild = true;
  constructor(private http: HttpClient, public router: Router) {  }
  

  getLocations() {
    return this.http.get('https://'+environment.domain+'/public/api/states',{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }

  getLocationsd() {
    return this.http.get('https://'+environment.domain+'/public/api/locations',{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }

  getLocationsR(id:any) {
    return this.http.get('https://'+environment.domain+'/public/api/locations/'+id,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }

  getStatesR(id:any) {
    return this.http.get('https://'+environment.domain+'/public/api/StateR/'+id,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }
}
