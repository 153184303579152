import { Component, OnInit } from '@angular/core';
import { aboutservice } from '../../services/aboutservice';
import { locationservice } from '../../services/locationservice';
import { environment } from '../../../environments/environment';
import {Router, ActivatedRoute} from '@angular/router';
import { Title,Meta } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SiteDataHandlerService } from 'src/app/services/site-data-handler.service';
import { propertyservice } from '../../services/propertyservice';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-luxe',
  templateUrl: './north.component.html',
  styleUrls: ['./north.component.css']
})
export class NorthComponent implements OnInit {

  imageSrc = 'https://'+environment.domain+'/public/uploads/images/';
  imagePropertySrc = 'https://'+environment.domain+'/public/property_images/';
  aboutData: any = [];
  locationData: any = [];
  categoryData: any = [];
  locationDatas: any = [];
  locationsNearbyData: any = [];
  propertyName = '';
  state = '';
  checkin:any;
  checkout:any;
  location = '';
  type = '';
  guest = '';
  party_friendly = '';
  budget_friendly = '';
  newly_villas = '';
  group_villas = '';
  offbeat_villas = '';
  numberOfNightss:any=[];
  iflogin: boolean = false;
  ifnotlogin: boolean = true;

  constructor(private SpinnerService: NgxSpinnerService,public propertyservice: propertyservice, public aboutservice: aboutservice,public locationservice: locationservice,private activatedRoute: ActivatedRoute, private router: Router,private metaService: Meta,private title: Title, private siteService:SiteDataHandlerService) {
    this.SpinnerService.show();
    
    let state = this.activatedRoute.snapshot.params.state;

    this.activatedRoute.queryParams.subscribe(
      params => {
        this.state = params['state'] || '';
        var location = params['location'] || '';
        this.propertyName = params['propertyName'] || '';
        this.guest = params['guest'] || '';
        this.checkin = params['checkin'] || '';
        this.checkout = params['checkout'] || '';
        this.location = params['location'] || '';
        this.type = params['type'] || '';
        this.party_friendly = params['party_friendly'] || '';
        this.budget_friendly = params['budget_friendly'] || '';
        this.newly_villas = params['newly_villas'] || '';
        this.group_villas = params['group_villas'] || '';
        this.offbeat_villas = params['offbeat_villas'] || '';
        
        //call method here based upon values to get filtered list.
        propertyservice.getPropertysNorthindia().subscribe((response:any) =>
        {
         this.aboutData = response['data']['properties'];
         this.SpinnerService.hide();
        });

        aboutservice.getLocationsNearby(this.location,this.checkin,this.checkout).subscribe((response:any) =>
        {
          this.locationsNearbyData = response['data']['properties'];
        });
      });
    
      this.title.setTitle('Ekostay - Villas in North India');
      this.metaService.addTag({ name: 'description', content: 'Gold properties are a new offering by EKOSTAY designed for a luxurious and premium staycation' });

      locationservice.getLocations().subscribe((response:any) =>
      {
        this.locationData = response['data'];
      });

      propertyservice.getPropertysNorthindia().subscribe((response:any) =>
        {
         this.aboutData = response['data']['properties'];
         this.SpinnerService.hide();
        });

      locationservice.getLocationsd().subscribe((response:any) =>
      {
        this.locationDatas = response['data'];
      });

      aboutservice.getCategories().subscribe((response:any) =>
      {
        this.categoryData = response['data'];
      });

      var dateN = new Date(this.checkin);
      var dateM = new Date(this.checkout);   
      
  
      if (this.checkin != '' && this.checkout != '') {
      var timeDiff = dateM.getTime() - dateN.getTime();
      var numberOfNights = timeDiff / (1000 * 3600 * 24);
      }else {
        var numberOfNights = 0;
      }
  
      this.numberOfNightss = numberOfNights;
       

      
   	}

  customOptions: OwlOptions = {
  loop: false,
  mouseDrag: false,
  touchDrag: true,
  pullDrag: true,
  dots: false,
  navSpeed: 700,
  navText: ['<', '>'],
  responsive: {
    0:{
      items:1
    }
  },
  nav: true
}

  ngOnInit(): void {
    this.siteService.checkIn.subscribe(res=>{
      if(res == undefined || res == null || res == ''){
        this.checkin = sessionStorage.getItem("check-in");
      }
      else{this.checkin= res;}
    })
    this.siteService.checkOut.subscribe(res=>{if(res == undefined || res == null || res == ''){
      this.checkout = sessionStorage.getItem("check-out");
    }
    else{this.checkout= res;}}) 
    
  }

  filterProperties(){

    this.siteService.updateCheckIn(this.checkin)
    this.siteService.updateCheckOut(this.checkout)
    this.router.navigate(['/party-friendly'], { queryParams: {location: this.location } });
    window.scrollTo(0, 200)
  }

}
