
  <!--------------------KEEP UPDATED --------------------->
  <div class="keep-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-5">
        <h2>STAY UPDATED <span class="highlight">GET EXCLUSIVE OFFERS & UPDATES</span></h2>
        </div>	 
        
        <div class="col-md-7">
        <form action="">
           <div class="form-group">
           <input type="email" class="form-control" id="email" name="email" placeholder="Email">
           </div>
           <button type="submit" class="btn btn-default">subscribe now</button>
        </form>
        </div>
      </div>
    </div>
  </div>