<section class="page-wrapper">

<section class="contact-page page-section">
	<div class="container">
		
		<div class="">
			<!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "ball-spin-clockwise-fade" [fullScreen] = "true"><p style="color: white" > Please Wait </p></ngx-spinner> -->
			<div style="margin: 0 auto;float: inherit;">
				<div class="contact-form-wrapper">
                    <h2>{{titleMSG}}</h2>
                    <mat-stepper linear #stepper>
                        <mat-step [stepControl]="bookForm" [editable]="isEditable">
                            
                            <form [formGroup]="bookForm">
                                <h3>Login</h3>											
                                <div class="">
                                    <div class="form-group">
                                        <input type="tel" id="phone" placeholder="Phone no." formControlName="phone" class="form-control custom_input"(keypress)="keyPress($event)" minlength=10 maxlength=10>
                                    </div>
                                </div>
                                <div class="bttn-boooking">
                                    <button class="btn btn-primary"  mat-button matStepperNext (click)="createNewOTP()">Continue</button>
                                    <!-- <button class="btn btn-primary"  mat-button matStepperNext (click)="generateOtp()">Continue</button> -->
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="secondFormGroup" [editable]="isEditable">
                            <form [formGroup]="secondFormGroup" *ngIf="!maxOTP">
                                <h3>OTP</h3>	
                                <div class="form-group otp-timer">
                                    <input type="tel" placeholder="Enter OTP" id="otp" formControlName="otp" class="form-control custom_input">
                                    <div class="resend-otp-timer">
                                        <span *ngIf="timer > 0">{{ countdown }}</span>
                                        <!-- <span>60</span> -->
                                        <!-- <button (click)="startTimer()">Resend OTP</button> -->
                                      </div>
                                </div> 
                                <div class="bttn-boooking"> 
                                    <button class="btn btn-primary" mat-button matStepperNext (click)="verifyNewOTP()" *ngIf="timer>0">Verify</button>
                                    <button class="btn btn-primary" mat-button matStepperNext (click)="resendOTP()" *ngIf="timer === 0">Resend OTP</button>
                                </div>  
                                                          										
                            </form>
                        </mat-step>  
                        <!-- *ngIf="loginhide"                       -->
                        <mat-step [stepControl]="thirdFormGroup" [editable]="isEditable" *ngIf="loginhide">
                            <form [formGroup]="thirdFormGroup">
                                <h3>Details</h3>	
                                 <ng-template matStepLabel>Enter Details</ng-template>  
                                    <div class="form-group"> 
                                        <input type="text" class="form-control" id="fname"
                                                    formControlName="fname" placeholder="First Name" ng-required="true">
                                    </div>

                                    <div class="form-group date-range">
                                        <input [matDatepicker]="picker" placeholder="Date of Birth" class="date-input form-control" formControlName="dateofbirth" [max]="maxDate"
                                        >
<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
<mat-datepicker #picker></mat-datepicker>
                                    </div>
                                    
                                    <div class="form-group">
                                        <input type="email" class="form-control" id="email"
                                        formControlName="email" placeholder="Email"
                                        ng-required="true">
                                    </div>

                                    <div class="form-group">
                                        <input type="phone" class="form-control" id="phone"
                                        formControlName="phone" type="number" placeholder="phone"
                                        ng-required="true">
                                        <div class="row" *ngIf="showPhoneError">
                                            <div class="col-md-12" class="error-feild">
                                              <label style="color: #000; margin-left: 25px;">The phone has already been taken</label>
                                            </div>
                                          </div>
                                    </div>
                                    	
                                    <div class="bttn-boooking"> 
                                        <button class="btn btn-primary" mat-button matStepperNext (click)="onsubmit()">Sign Up</button>
                                    </div>									
                            </form>
                        </mat-step>								
                    </mat-stepper>

                    <div class="row" *ngIf="showError" style="margin-top:10px;">
                        <div class="col-md-12">
                            <label style="color: rgb(128, 0, 0);">{{errorMSG}}</label>
                        </div>
                    </div>
                    <div class="row" *ngIf="showSuccess" style="margin-top:10px;">
                        <div class="col-md-12">
                            <label style="color: rgb(0, 255, 106);">{{errorMSG}}</label>
                        </div>
                    </div>
                    <div class="row" *ngIf="maxOTP" style="margin-top:10px;">
                        <div class="col-md-12">
                            <label style="color: rgb(0, 255, 106);">Your Max Out of OTP Please Try again after 12hr</label>
                        </div>
                    </div>
                    <div class="row" *ngIf="otplimit" style="margin-top:10px;">
                        <div class="col-md-12">
                            <label style="color: rgb(0, 255, 106);">you have {{resendOTPAttempts}}/3 OTP Left</label>
                        </div>
                    </div>
				</div>
			</div>
		</div>
	</div>
	
	
	
</section>
</section>
