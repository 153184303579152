import { Injectable } from '@angular/core';

import {Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, map } from 'rxjs';


@Injectable()
export class loginservice {
  isSessionVaild = true;
  public IsLoginData:BehaviorSubject<any> = new BehaviorSubject({});
  constructor(private http: HttpClient, public router: Router) {  }
  

  getlogin(id:any) {
    return this.http.get('https://'+environment.domain+'/public/api/loginbyphone/'+id,{
      headers : {
          'Content-Type' : 'application/json'
      }
    })
  //   .pipe(map(()=> ({
  //     "success": false,
  //     "message": "Invalid Credential"
  // })));
  }

  createlogin(data:any) {

    return this.http.post('https://'+environment.domain+'/public/api/register', data,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });

  }

  createOTP(phone: string): Observable<any> {
    const body = { phone: phone };
    return this.http.post('https://'+environment.domain+'/public/api/sendOtp', body);
  }

  verifyOTP(orderId:any,otp:any,phone:any) {
    const body = {otp: otp,
                    orderId:orderId,
                    phone: phone,
    };
    return this.http.post('https://'+environment.domain+'/public/api/verifyOtp', body)
  }

  resendOTP(orderId: string): Observable<any> {
    const body = { orderId: orderId };
    return this.http.post('https://'+environment.domain+'/public/api/resendOtp', body);
  }


  getProfile(id:any) {
    return this.http.get('https://'+environment.domain+'/public/api/getprofile/'+id,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }

  updateProfile(body:any){
    return this.http.post('https://'+environment.domain+'/public/api/updateProfile', body)
  }

  getBooking(id:any) {
    return this.http.get('https://'+environment.domain+'/public/api/getbookings/'+id,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }

  getConfirmedStatus(id:any) {
    return this.http.get('https://'+environment.domain+'/public/api/getConfirmedStatus/'+id,{
      headers : {
          'Content-Type' : 'application/json'
      }
    });
  }

  getMembershipDetail(phone: any, property_id: any): Observable<any> {
    return this.http.get(`https://${environment.domain}/public/api/get-membership-discount?phone=${phone}&property_id=${property_id}`);
}


 
}
