import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataLayerService {
  constructor(private http: HttpClient) {
    // Constructor logic if needed
  }

  pushToDataLayer(data: any) {
    (<any>window).dataLayer = (<any>window).dataLayer || [];
    (<any>window).dataLayer.push(data);
  }

  trackEvent(event: string, data: any): void {
    (<any>window).dataLayer.push({ event, ...data });
  }

  private apiUrl = 'https://app.wigzo.com/rest/v1/learn/identify';
  private token = '935966a282624121a7d21db2e233493a';
  private orgToken = 'vZ46DUbDSW2qqLxC8kF8VA';

  // Method to send user mapping data to Wigzo
  sendUserMapping(data: { userId: string; email: string; phone?: string; is_active?: boolean; source?: string }): Observable<any> {
    const url = `${this.apiUrl}?token=${this.token}&org_token=${this.orgToken}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(url, data, { headers });
  }

  sendEventTracking(data: { 
    eventName: string; 
    eventval: object; 
    eventCategory: string; 
    userId: string; 
    is_active?: boolean; 
    source?: string; 
  }): Observable<any> {
    const url = 'https://app.wigzo.com/rest/v1/learn/event';
    const params = {
      token: '935966a282624121a7d21db2e233493a',
      org_token: 'vZ46DUbDSW2qqLxC8kF8VA'
    };
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  
    return this.http.post(url, data, { headers, params });
  }
  
}
