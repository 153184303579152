import { Component, OnInit } from '@angular/core';

import { locationservice } from '../../services/locationservice';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.css']
})
export class LocationsComponent implements OnInit {
  imageSrc = 'https://'+environment.domain+'/public/uploads/images/';
  locationData: any = [];

  constructor(public locationservice: locationservice) {
    locationservice.getLocations().subscribe((response:any) =>
      {
     
       this.locationData = response['data'];
      });
   }

  ngOnInit(): void {
  }

}
