<section class="page-wrapper">
		<section class="page-banner">
			<div class="container">
		<h1 class="heading">Meet the people powering Ekostay</h1>
		<p class="subheading">We at EKO STAY take pride in professionally managing properties as vacation homes and remove the owner’s hassle associated with hosting and client servicing. We at EKO STAY take pride in professionally managing properties as vacation homes and remove the owner’s hassle
			associated with hosting and client servicing.</p>

			<div class="ourteam_wrapper">
			<div class="row">
				<div class="col-lg-3 col-md-3 col-sm-6" data-aos="fade-up" data-aos-duration="1000">
					<div class="ourteam_item">
						<img src="../../../assets/img/Husain.jpeg" style="width: 100%;">
						<div class="ourteam_dtl">
						  <div class="ourteam_name">Husain Khatumdi</div>
						  <div class="position">Managing Director & Co-Founder</div>
						  <a class="team_info" (click)="openDialog('0', '0', 1)">See Info <i class="fa fa-chevron-right"></i></a>
						</div>
					</div>
				</div>

				<div class="col-lg-3 col-md-3 col-sm-6" data-aos="fade-up" data-aos-duration="1000">
					<div class="ourteam_item">
						<img src="../../../assets/img/Varun.jpg" style="width: 100%;">
						<div class="ourteam_dtl">
						  <div class="ourteam_name">Varun Arora</div>
						  <div class="position">Chief Executive Officer & Co-Founder</div>
						  <a class="team_info" (click)="openDialog('0', '0', 2)">See Info <i class="fa fa-chevron-right"></i></a>
						</div>
					</div>
				</div>

				<div class="col-lg-3 col-md-3 col-sm-6" data-aos="fade-up" data-aos-duration="1000">
					<div class="ourteam_item">
						<img src="../../../assets/img/Sohail.jpg" style="width: 100%;">
						<div class="ourteam_dtl">
						  <div class="ourteam_name">Sohail Mirchandani</div>
						  <div class="position">Chief Operating Officer & Co-Founder</div>
						  <a class="team_info" (click)="openDialog('0', '0', 3)">See Info <i class="fa fa-chevron-right"></i></a>
						</div>
					</div>
				</div>

				<div class="col-lg-3 col-md-3 col-sm-6" data-aos="fade-up" data-aos-duration="1000">
					<div class="ourteam_item">
						<img src="../../../assets/img/Zeeshan.jpg" style="width: 100%;">
						<div class="ourteam_dtl">
						  <div class="ourteam_name">Zishan Khan</div>
						  <div class="position">Chief Acquisition Officer & Co-Founder</div>
						  <a class="team_info" (click)="openDialog('0', '0', 4)">See Info <i class="fa fa-chevron-right"></i></a>
						</div>
					</div>
				</div>
			</div>
			</div>


			<h1 class="heading">Our Team</h1>
			<div class="ourteam_wrapper">
				<div class="row">
					<div class="col-lg-6 col-md-6 col-sm-12 mob-div" data-aos="fade-up" data-aos-duration="1000" *ngFor="let detail of teamDetail">
						<div class="dialog_wrapper member-box">
							<div class="avtar">
								<img [src]='detail.image' class="img-border">
							</div>
							
							<div class="details-new">
								<div class="detail-back">
									<div class="name">{{detail.name}}</div> 
									<div class="position">{{detail.designation}}</div>

								</div>
							   <p class="describe-new">
								{{detail.description}}
							   </p>
							</div>
							</div>
					</div>
				</div>
				</div>

		</div>
</section>
</section>


	