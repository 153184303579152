<section class="page-wrapper">

    <section class="blog-page page-section">
        <div class="container">
			<div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
				<h2>Privacy Policy</h2>
            <p>
                In accordance with the Information Technology Act of 2000 and its implementing regulations, this privacy statement qualifies as an electronic record. There are neither physical nor digital signatures necessary for this
                electronic record because it is produced by a computer system. Ekostay LLP adheres on the highest standards for safe transactions and the protection of client information because we respect the confidence that website
                visitors and users have placed in us. We are dedicated to safeguarding any information you could share with us as a user. This privacy statement explains the data we may gather about you as part of our regular business
                operations and what could happen to it. You expressly agree that we may use and disclose your personal information in the ways set out in this Privacy Policy by accessing or even using this website. The terms outlined in the
                Terms and Conditions of Use are included into and applied to this Privacy Policy as well. We want to start off by letting you know that this Privacy Policy is subject to change without prior notice or warning to website
                visitors or users. You are thus recommended to often check for any updates or changes to this Privacy Policy.
            </p>
            <p>
                The privacy policy shown below is applicable.
            </p>
			</div>

            <div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
                <h2>
                    1. The information collected by EKOSTAY LLP
                </h2>
                <p>
                    Generally, you are not required to disclose any personal information in order to visit our website. However, if you want to book anything through our website, we'll need information like your name, phone number, email
                    address, billing information, bank account information, and other personal data. We always provide you the opportunity to withhold information by not using a specific service or feature on the website, and whenever
                    feasible, we clarify whether the information to be submitted is voluntary or necessary. We need exact and factual information from you. We disclaim all liability for the accuracy of any personal information or data
                    provided by users, as well as for any consequences these may create. We may automatically detect certain information about you based on how you use our services and how you behave on our website. To better understand,
                    safeguard, and serve our users, we conduct internal research using this data on their demographics, interests, and behaviour. In order to improve the service we offer, we also track website usage and client traffic
                    patterns. We guarantee that we will only gather the data that is required and pertinent for us to provide you with the services offered on the Site.
                </p>
            </div>

            <div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
                <h2>2. Personal information</h2>
                <p>
                    Personal information is any data that can be used to identify you, such as:
                </p>
                <ul class="content_list">
                    <li><i class="fa fa-check vill-detail-btn-icon"></i> Name and Gender</li>
                    <li><i class="fa fa-check vill-detail-btn-icon"></i> Phone Number</li>
                    <li><i class="fa fa-check vill-detail-btn-icon"></i> E-mail address</li>
                    <li><i class="fa fa-check vill-detail-btn-icon"></i> Contact Address</li>
                    <li><i class="fa fa-check vill-detail-btn-icon"></i> Password</li>
                    <li>
                        <i class="fa fa-check vill-detail-btn-icon"></i> financial information such as Bank Account, credit card, debit card information or other payment instrument details for the purpose of processing payments
                    </li>
                    <li><i class="fa fa-check vill-detail-btn-icon"></i> physical, physiological and mental health condition</li>
                    <li><i class="fa fa-check vill-detail-btn-icon"></i> Other details such as biometric information, Sexual orientation, medical records and history.</li>
                </ul>
            </div>

            <div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
                <h2>3. Cookies</h2>
                <p>
                    Cookies are little files that are downloaded to your computer's hard disc and help us provide our services. Cookies are used on some internet pages to assess how the page flows, monitor the impact of promotions, and
                    foster a sense of security and confidence. Cookies enable us to deliver content that is specifically tailored to your interests. Additionally, they allow the browser to remember details unique to a particular user,
                    reducing the number of times you have to input your password. The majority of cookies are session cookies, which means that when a session ends, they are promptly removed from your hard drive. If your browser allows it,
                    you can refuse to allow cookies to be used. However, you might not be able to access some services of our website in such event, and you might have to enter your login information more frequently throughout a session. On
                    some of the Site's pages, third parties may from time to time place cookies or other similar data gathering tools. We have no control over how third parties utilise cookies.
                </p>
            </div>

            <div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
                <h2>4. Utilization of the information we collect</h2>
                <p>
                    We can promise you that we only utilise the personal information you give us when necessary and appropriate for our regular business operations.We use your personal information to process reservations made through the
                    website, to deliver better services, to get in touch with you by phone and email when necessary, to help you, and, if necessary, to share it with reputable third parties. Additionally, we may use the pertinent
                    information to get in touch with you regarding your reservation and/or to tell you of other services that are advertised on this website. We may also utilize your personal information to enforce the Terms and Conditions
                    of Use, resolve disputes, troubleshoot issues, gauge consumer interest in the services we offer, notify you of offers, services, and updates both online and offline, personalise your experience, detect and protect us
                    against errors, fraud, and other criminal activity, and in other ways that were disclosed to you at the time of collection. The data may also be used to enhance our promotional and marketing initiatives, analyse website
                    usage, and enhance and personalise the website's services and content. These functions enhance the website and assist us in making it more suited to your needs so that you have a simple, quick, secure, and personalised
                    experience when using the Site.
                </p>
            </div>

            <div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
                <h2>5. Information sharing with third parties</h2>
                <p>
                    In the following situations, third parties may get your information:<br />
                    Only to the degree necessary for them to execute that specific service, we may give trusted third parties (such as our accountants, credit card processing firms, hotels, government authorities, etc.) access to the
                    information we have acquired about you.<br />
                    Any rights we have in relation to our information about you will be transferred to the new corporation, owner, or operator if we restructure our business, sell it, or lease it. We promise to take reasonable steps to
                    ensure that your privacy is maintained going forward;<br />
                    If we are required to assist in the investigation, prevention, or resolution of unlawful and illegal activities, suspected fraud, potential threats to the safety or security of any person or organisation, property or
                    assets, or rights of the Website due to unauthorised use or misuse of the Website, violations of our terms and conditions, or to establish our legal rights in connection with claims or legal proceedings; Upon the
                    occurrence of the special circumstances set forth below - 1. to respond to subpoenas, court orders, to help authorised agencies in accordance with the 2009 Information Technology (Procedure and Safeguards for
                    Interception, Monitoring, and Decryption of Information) Rules in their efforts to intercept, monitor, or decrypt information. To support and work in conjunction with the authorised agencies for the monitoring and
                    collection of traffic data or information generated, transmitted, received, or stored in accordance with the Information Technology (Procedure and Safeguard for Monitoring and Collecting Traffic Data or Information)
                    Rules, 2009.<br />
                    To facilitate or outsource one or more aspects of the business, product, and service operations we provide to you on this website, we may use third parties we refer to as internal service providers. As a result, we may
                    give some of your personal information to these internal service providers directly. Unless you have expressly agreed or given them your prior permission for additional uses, these internal service providers are subject
                    to confidentiality agreements with us and other legal restrictions that forbid their use of the information we provide them for any other purpose than to facilitate the particular outsourced website-related operation.
                </p>
            </div>

            <div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
                <h2>6. Disclosure of information</h2>
                <p>
                    Commercials: The website could include adverts from third parties, and whenever you provide information to one of these advertisers while they are shown on the website, both we and the advertiser are concurrently
                    collecting the data. The advertiser uses the information in line with their standard privacy policy, and we use the information in accordance with the provisions of our privacy statement. You should consider their
                    policies before electing to share the aforementioned information because we have no control over the privacy practises of these advertisers or third parties. We won't be held accountable for the data that you give these
                    advertisers or that they acquire from you. Links to other websites: The website can have links to other websites that track user data. We disclaim all liability for the content or privacy policies of any connected
                    websites.
                </p>
            </div>

            <div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
                <h2>7. Promotions and updates</h2>
                <p>
                    You consent to our communication with you regarding offers and updates pertaining to the website and the services provided when you give us your email address at the time of any reservation. We may keep track of the
                    website features you use the most frequently in order to assess how well you use our services. We can better serve you by using this information to show you just the most pertinent and helpful information about our
                    services and the website. If at any time you change your mind and decide that you no longer want to hear from us, you may opt out or unsubscribe yourself from the service by using the unsubscribe option on our website or
                    by clicking the link in the communication emails.
                </p>
            </div>

            <div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
                <h2>8. Security for your information</h2>
                <p>
                    We pledge to safeguard the data you disclose to us. To safeguard the information you supplied, we have implemented several administrative, technological, physical, and other security measures. As an illustration, we
                    encrypt any sensitive information you provide to us when using our system, and we restrict access to your personal data to those who need it for legal business purposes.
                </p>
            </div>

            <div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
                <h2>9. Security measures</h2>
                <p>
                    In order to uphold the confidentiality with which we handle your information, we assure you that we will use reasonable security practises and procedures, as well as modern Internet security methods and technologies, in
                    accordance with the Information Technology Act of 2000 and rules made thereunder, in order to prevent unauthorised access, maintain data accuracy, and guarantee proper use of information.<br />
                    Additionally, we only grant access to personal information to those employees, contractors, and agents who require it to run, develop, or enhance our services. These people are required to maintain confidentiality, and
                    breaching these commitments might result in punishment, including termination and criminal prosecution. Although we use industry best practises to safeguard your privacy, we are unable to guarantee that none of your
                    personal information or private conversations will ever be shared in a manner not covered by this Privacy Policy. As an illustration (and without limiting the foregoing), under certain conditions, we may be required to
                    provide personal information to the government or third parties, third parties may illegally intercept or access transmissions or private communications, or users may abuse or misuse personal information they collect
                    from the website.
                </p>
            </div>

            <div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
                <h2>10. Governing law and jurisdiction</h2>
                <p>
                    Ekostay developed and maintains this website, which is subject to Indian legislation. All terms, conditions, and disclaimers shall be subject to the exclusive jurisdiction of the courts located in Mumbai.
                </p>
            </div>

            <div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
                <h2>11. Modifications to this privacy policy</h2>
                <p>
                    If we decide to do so in the future, we may change this privacy statement. Any future modifications to our privacy policy will be disclosed on this page. You should routinely visit our privacy policy page to acquaint
                    yourself with the most recent version.
                </p>
            </div>

            <div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
                <h2>12. Grievance procedure</h2>
                <p>
                    Ekostay is adamant about being open with its customers. Through a channelled approach, evaluation, and resolution process, this policy seeks to reduce the number of customer complaints, grievances, and disappointments.
                    If you are unhappy with Ekostay's services or have any complaints, you may submit a grievance. You can report your complaints to the following email address : reservations@ekostay.com.
                </p>
            </div>

            <div class="box_wrapper" data-aos="fade-up" data-aos-duration="1000">
                <h2>13. Express consent</h2>
                <p>
                    You explicitly consent to the use and dissemination of your personal information by us in the ways described in this Privacy Statement and by Indian law by visiting this page or using this website. Note: Headings are for
                    reference purposes only and in no way define, limit, construe or describe the scope or extent of such section.
                </p>
            </div>
        </div>
    </section>
</section>
