<!-- <div class="reservations_button">
    <a routerLink="#" class="btn">Reservations <span>></span></a>
</div> -->

<section class="ourstory_banner">
  <div class="container">
    <div class="scroll_wrapper">
      <h3 class="title">SCROLL DOWN TO DISCOVER OUR STORY</h3>
      <div class="icon" (click)="scrollWins()">
        <img src="../../../assets/img/scroll-down.png" />
      </div>
    </div>
  </div>
</section>

<section class="container-fluid welcome_holder">
  <div class="row welcome_wrapper">
    <div class="col-sm-4">
      <div class="welcome_img" data-aos="fade-up" data-aos-duration="1000">
        <img src="../../../assets/img/Bali.jpg" />
      </div>
    </div>

    <div class="col-sm-8">
      <div class="welcome_content">
        <div class="content_holder">
          <h3 class="title" data-aos="fade-up" data-aos-duration="1000">
            Welcome to <span>EkoStay</span>
          </h3>
          <p data-aos="fade-up" data-aos-duration="1000">
            EKO STAY is the vanguard provider of professionally managed vacation
            homes, committed to curating exceptional homestay encounters across
            diverse destinations pan India. Founded in 2018 by a visionary cadre
            of hospitality aficionados, EKO STAY swiftly ascended as a luminary
            in the alternative accommodation domain. Boasting a portfolio of
            over 150+ meticulously curated villas spanning 12 cities, EKO STAY
            sets the gold standard for personalized hospitality. Driven by
            innovation, customer-centricity, and sustainable expansion, EKO STAY
            continues to redefine the homestay milieu, offering guests comfort,
            convenience, and indelible memories.
          </p>

          <!-- <ul class="about_lists" data-aos="fade-up" data-aos-duration="1000">
            <li>We’ll be there for you when you want to book a room.</li>
            <li>We’ll be there for you when you wish to dip in a pool.</li>
            <li>We’ll be there for you when you’re ready to party.</li>
            <li>We’ll be here for you ‘coz that’s what we do.</li>
          </ul> -->
        </div>
      </div>
    </div>
  </div>
</section>

<section class="our_journey">
  <div class="container">
    <div class="heading">
      <h2 class="title" data-aos="fade-up" data-aos-duration="1000">
        OUR JOURNEY SO FAR…
      </h2>
      <p class="sub_title" data-aos="fade-up" data-aos-duration="1000">
        Founded in 2018 by the Entrepreneurial Fantastic Four- Husain Khatumdi,
        Sohail Mirchandani, Varun Arora & Zishan Khan, with a vision to tailor
        the needs of the holiday goers on experiencing a cozy & private stay of
        a home away from home. EKOSTAY now offers guests a selection of over
        100+ villas spread across 12 cities.
      </p>
    </div>

    <div class="mdl-card mdl-shadow--2dp">
      <div class="mdl-card__supporting-text">
        <div class="mdl-stepper-horizontal-alternative">
          <div
            class="mdl-stepper-step active-step step-done before_circle"
            (click)="viewMode = '2020'"
          >
            <div class="mdl-stepper-title">2020</div>
            <div
              class="mdl-stepper-circle"
              [class.stepper_fill_dot]="viewMode == '2020'"
            >
              <span></span>
            </div>
            <div class="mdl-stepper-bar-left"></div>
            <div class="mdl-stepper-bar-right"></div>
          </div>
          <div
            class="mdl-stepper-step active-step editable-step"
            (click)="viewMode = '2021'"
          >
            <div class="mdl-stepper-title">2021</div>
            <div
              class="mdl-stepper-circle"
              [class.stepper_fill_dot]="viewMode == '2021'"
            >
              <span></span>
            </div>
            <div class="mdl-stepper-bar-left"></div>
            <div class="mdl-stepper-bar-right"></div>
          </div>
          <div class="mdl-stepper-step active-step" (click)="viewMode = '2022'">
            <div class="mdl-stepper-title">2022</div>
            <div
              class="mdl-stepper-circle"
              [class.stepper_fill_dot]="viewMode == '2022'"
            >
              <span></span>
            </div>
            <div class="mdl-stepper-bar-left"></div>
            <div class="mdl-stepper-bar-right"></div>
          </div>
          <div class="mdl-stepper-step" (click)="viewMode = '2023'">
            <div class="mdl-stepper-title">2023</div>
            <div
              class="mdl-stepper-circle"
              [class.stepper_fill_dot]="viewMode == '2023'"
            >
              <span></span>
            </div>
            <div class="mdl-stepper-bar-left"></div>
            <div class="mdl-stepper-bar-right"></div>
          </div>
        </div>
      </div>
    </div>
    <div [ngSwitch]="viewMode">
      <div *ngSwitchCase="'2020'">
        <div class="stepper_content">
          <h3 class="title" data-aos="fade-right" data-aos-duration="1000">
            2020 LAUNCH
          </h3>
          <p class="describe" data-aos="fade-right" data-aos-duration="1000">
            Founded in 2018 by the Entrepreneurial Fantastic Four- Husain
            Khatumdi, Sohail Mirchandani, Varun Arora & Zishan Khan, with a
            vision to tailor the needs of the holiday goers on experiencing a
            cozy & private stay of a home away from home. EKOSTAY now offers
            guests a selection of over 100+ villas spread across 12 cities.
          </p>

          <img
            src="../../../assets/img/lake001.png"
            data-aos="fade-right"
            data-aos-duration="1000"
          />
        </div>
      </div>
      <div *ngSwitchCase="'2021'">
        <div class="stepper_content">
          <h3 class="title" data-aos="fade-right" data-aos-duration="1000">
            2021 LAUNCH
          </h3>
          <p class="describe" data-aos="fade-right" data-aos-duration="1000">
            Founded in 2018 by the Entrepreneurial Fantastic Four- Husain
            Khatumdi, Sohail Mirchandani, Varun Arora & Zishan Khan, with a
            vision to tailor the needs of the holiday goers on experiencing a
            cozy & private stay of a home away from home. EKOSTAY now offers
            guests a selection of over 100+ villas spread across 12 cities.
          </p>

          <img
            src="../../../assets/img/lake001.png"
            data-aos="fade-right"
            data-aos-duration="1000"
          />
        </div>
      </div>
      <div *ngSwitchCase="'2022'">
        <div class="stepper_content">
          <h3 class="title" data-aos="fade-right" data-aos-duration="1000">
            2022 LAUNCH
          </h3>
          <p class="describe" data-aos="fade-right" data-aos-duration="1000">
            Founded in 2018 by the Entrepreneurial Fantastic Four- Husain
            Khatumdi, Sohail Mirchandani, Varun Arora & Zishan Khan, with a
            vision to tailor the needs of the holiday goers on experiencing a
            cozy & private stay of a home away from home. EKOSTAY now offers
            guests a selection of over 100+ villas spread across 12 cities.
          </p>

          <img
            src="../../../assets/img/lake001.png"
            data-aos="fade-right"
            data-aos-duration="1000"
          />
        </div>
      </div>
      <div *ngSwitchCase="'2023'">
        <div class="stepper_content">
          <h3 class="title" data-aos="fade-right" data-aos-duration="1000">
            2023 LAUNCH
          </h3>
          <p class="describe" data-aos="fade-right" data-aos-duration="1000">
            Founded in 2018 by the Entrepreneurial Fantastic Four- Husain
            Khatumdi, Sohail Mirchandani, Varun Arora & Zishan Khan, with a
            vision to tailor the needs of the holiday goers on experiencing a
            cozy & private stay of a home away from home. EKOSTAY now offers
            guests a selection of over 100+ villas spread across 12 cities.
          </p>

          <img
            src="../../../assets/img/lake001.png"
            data-aos="fade-right"
            data-aos-duration="1000"
          />
        </div>
      </div>
      <div *ngSwitchDefault>Otherwise</div>
    </div>
  </div>
</section>

<section class="mission_vision">
  <div class="container">
    <div class="heading" data-aos="fade-right" data-aos-duration="1000">
      <h2 class="title">OUR VISION & MISSION</h2>
      <!-- <p class="sub_title">In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual
                form of a document or a typeface.</p> -->
    </div>

    <div class="row">
      <div class="col-sm-6" data-aos="fade-up" data-aos-duration="1000">
        <div class="vision_img">
          <img src="../../../assets/img/Bali.jpg" />
        </div>
      </div>

      <div class="col-sm-6" data-aos="fade-up" data-aos-duration="1000">
        <div class="M_vision_content">
          <img src="../../../assets/img/vision.png" />
          <h3 class="title">OUR VISION</h3>
          <p class="describ">
            Our vision is to be a pioneer in the alternative accommodation space
            by providing value for money homes across nations. We thrive to be
            the recognised benchmark in providing the most beneficial services
            to all our stakeholders, homeowners, clients as well as employees.
          </p>
        </div>
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-sm-3 mt-5" data-aos="fade-up" data-aos-duration="1000">
        <div class="M_vision_content mission_ico">
          <img src="../../../assets/img/mission.png" />
        </div>
      </div>

      <div class="col-sm-9" data-aos="fade-up" data-aos-duration="1000">
        <div class="M_vision_content">
          <h3 class="title">OUR MISSION</h3>
          <p class="describ">
            To provide standardised yet personalised Homestay experience by
            redesigning properties with a customer centric approach. We
            collaborate & help homeowners to remove the hassle of hosting, to
            better manage their property and to provide them with an alternate
            source of income.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
