import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appNumberonly]'
})
export class NumberonlyDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const inputValue = this.el.nativeElement.value;
    const sanitizedValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters
    this.renderer.setProperty(this.el.nativeElement, 'value', sanitizedValue);
  }

}
