import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import * as AOS  from 'aos';
@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.css']
})
export class MembershipComponent implements OnInit {
isBrowser:boolean;

  constructor(private metaService: Meta,private title: Title,  @Inject(PLATFORM_ID) platformId: Object) { 

    this.isBrowser = isPlatformBrowser(platformId);

    this.title.setTitle('Ekostay - Membership');
     this.metaService.addTag({ name: 'description', content: 'Ekostay - Membership' });

  }

  ngOnInit(): void {
if(this.isBrowser){

  AOS.init();
  window.addEventListener('load', AOS.refresh)
}

  }

}
