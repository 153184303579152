import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, QueryList, SimpleChange, SimpleChanges, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { aboutservice } from 'src/app/services/aboutservice';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PropertyCardComponent implements OnInit {

  imageSrc1 = "https://" + environment.domain + "/public/uploads/images/";
  @Input() index:number;
  @Input() property1 :any
  @Input() customOptions:any
  @Input() location:any
  @Input() numberOfNightss:any
  @Input() customOptionsAminity:any
  @Input() imageSrc:any;
  @Input() final_price_pi:any
  // imagePropertySrc =
  // "https://" + environment.domain + "/public/property_images/";
  badgeDetails: { label: string; class: string }[] = [];
  constructor(private router:Router, private aboutService:aboutservice, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.generateBadges();
  }

  get isMobile() {
    return window.innerWidth < 768;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('numberOfNightss' in changes) {
      this.cdr.detectChanges(); // Manually trigger change detection
    }
  }

  cleanPropertyName(property_name: string): string {
    return property_name?.replace(/EKOSTAY -|EKOSTAY-/g, "");
  }
  navigationPage(property1:any){
this.router.navigateByUrl('/property/location/villas-in-' + property1.locname + '/' + property1.permalink);

  }


  generateBadges() {
    this.badgeDetails = [
      { label: 'All White', class: 'alert-lux' },
      { label: 'Outdoorsy', class: 'alert-lux' },
      { label: 'Scenic', class: 'alert-lux' },
      { label: 'Mega Structure', class: 'alert-lux' },
      { label: 'Insta Worthy', class: 'alert-lux' },
    ].filter((badge, index) => {
      // Dynamically check properties in order
      const keys = ['all_white', 'outdoorsy', 'scenic', 'mega_structure', 'youth_friendly'];
      return this.property1[keys[index]] === 'on';
    });
  }


@ViewChildren('carousel') carousel!: QueryList<ElementRef>;
visibleIndexMap: { [key: number]: number } = {}; // Map to track visible index per carousel
  scrollLeft(index: number) {
    const carousel = this.carousel.toArray()[0]?.nativeElement as HTMLElement;
  
    if (!carousel) return;
  
    const slideWidth = carousel.offsetWidth;
    const currentIndex = this.visibleIndexMap[index] || 0;
  
    if (currentIndex > 0) {
      // Update visible index and scroll to the previous image
      this.visibleIndexMap[index] = currentIndex - 1;
      carousel.scrollBy({
        left: -slideWidth,
        behavior: 'smooth',
      });
    }
  }
  
  scrollRight(index: number) {
    const carousel = this.carousel.toArray()[0]?.nativeElement as HTMLElement;
    if (!carousel) return;
  
    const slideWidth = carousel.offsetWidth;
    const currentIndex = this.visibleIndexMap[index] || 0;
  
    if (currentIndex < 4) {
      // Update visible index and scroll to the next image
      this.visibleIndexMap[index] = currentIndex + 1;
      carousel.scrollBy({
        left: slideWidth,
        behavior: 'smooth',
      });
    }
  }
  
  isViewMoreVisible(index: number): boolean {
    return this.visibleIndexMap[index] === 4; // Show "View More" only when the 4th index is visible
  }

}
