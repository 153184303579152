import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  PLATFORM_ID,
  Inject,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { headerservice } from "../../services/headerservice";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { environment } from "../../../environments/environment";
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from "@angular/router";
import { Observable, Subject, Subscription } from "rxjs";
import { AuthService } from "../../services/auth.service";
import { Validators, FormControl, FormGroup } from "@angular/forms";
import { bookingservice } from "../../services/bookingservice";
import { LoaderService } from "../loader/loader.service";
import { map, shareReplay } from "rxjs/operators";
import { loginservice } from "../../services/loginservice";
import { DataLayerService } from "src/app/services/data-layer.service";
import { formatDate, isPlatformBrowser } from "@angular/common";
import { MatDateRangePicker } from "@angular/material/datepicker";
import { VerifyPhoneDialogComponent } from "src/app/modules/checkout/checkout/verify-phone-dialog/verify-phone-dialog.component";
import { MatDialogRef } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  destroy$: Subject<any> = new Subject();

  isDarkTheme: boolean = false;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

  submitted = false;

  imageSrc = "https://" + environment.domain + "/public/uploads/images/";
  headerData: any = [];
  menuVisible: boolean = true;
  submenuVisible: boolean;
  status: boolean;
  iflogin: boolean = false;
  ifnotlogin: boolean = true;
  contactForm: FormGroup;
  public showError: boolean = false;
  public divhidetop: boolean = true;
  public showStatus: boolean = false;
  public div1: boolean = false;
  public hideForm: boolean = true;
  public showEmailInvalid: boolean = false;
  public showPhoneInvalid: boolean = false;
  public showEmail: boolean = false;
  phoneErrorMessage: string = "Please enter a valid 10-digit phone number";
  emailErrorMessage: string = "Please enter a valid email address";
  dateErrorMessage: string = "Please enter a valid date";
  locationErrorMessage: string = "Please enter prefered location";
  salesExecutiveNameErrorMessage: string = "Please enter sales executive name";

  separateDialCode = false;
  id: any = "";
  name = "";
  phone = "";
  fname = "";
  email = "";
  uid = "";
  member = "";
  totalConfirm = "";
  Bookings: any = [];
  datas: any = "";
  isGold: boolean = false;
  isLuxe: boolean = false;
  isFeatured: boolean = false;
  ekoElement: boolean = false;
  ekoElementData: any;
  isBrowser: boolean;
  panelOpenState = false;
  minDate = new Date();
  isBookingWidgetOpen = false;
  private subscription: Subscription;

  @Output() public sidenavToggle = new EventEmitter();
  @Output() sidenavClose = new EventEmitter();
  @Input() urlData: any;

  @ViewChild("picker") picker: MatDateRangePicker<any>;

  // Define a method to be called when the picker is opened
  onPickerOpened() {
    const cdkOverlayContainer = document.getElementsByClassName(
      "cdk-overlay-container"
    )[0] as HTMLDivElement;
    cdkOverlayContainer.style.zIndex = "10000000000";
    cdkOverlayContainer.classList.add("headerpanel");
  }

  // Optionally, define a method to be called when the picker is closed
  onPickerClosed() {
    const cdkOverlayContainer = document.getElementsByClassName(
      "cdk-overlay-container"
    )[0] as HTMLDivElement;
    cdkOverlayContainer.style.zIndex = ""; // Reset to default or any other value you prefer
    cdkOverlayContainer.classList.remove("headerpanel");
  }

  constructor(
    private loginservice: loginservice,
    public headerservice: headerservice,
    private router: Router,
    private authService: AuthService,
    private bookingservice: bookingservice,
    private breakpointObserver: BreakpointObserver,
    public loaderService: LoaderService,
    private dataLayerService: DataLayerService,
    @Inject(PLATFORM_ID) platformId: Object,
    private elementRef: ElementRef,
    private toastr: ToastrService
  ) {
      this.loginservice.IsLoginData.subscribe((res:any)=>{
        if(res){
          this.datas = res.name
          if (this.isBrowser) {
            if (localStorage.getItem("token")) {
              this.ifnotlogin = false;
              this.iflogin = true;
            }
          }
        }
      })
  
      this.subscription = this.headerservice.isBookingWidgetOpen$.subscribe(
        (isOpen) => {
          this.isBookingWidgetOpen = isOpen;
        }
      );

    this.isBrowser = isPlatformBrowser(platformId);
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        if (this.isBrowser) {
          if (localStorage.getItem("token")) {
            this.ifnotlogin = false;
            this.iflogin = true;
          }
        }
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        // alert('22');
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        //alert('33');
        // Present error to user
      }
    });

    this.contactForm = new FormGroup({
      name: new FormControl("", Validators.required),
      phone: new FormControl("", [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
      ]),
      email: new FormControl(
        "",
        [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]
      ),
      guest: new FormControl(),
      location: new FormControl("", Validators.required),
      customer_message: new FormControl(),
      checkin: new FormControl("", Validators.required),
      checkout: new FormControl("", Validators.required),
      sales_executive: new FormControl(),
      sales_executive_name: new FormControl(),
    });
    this.contactForm.get('sales_executive')?.valueChanges.subscribe((val:'yes' | 'no')=>{
      if(val=== 'yes'){
        this.contactForm.get('sales_executive_name')?.setValidators(Validators.required)
      } else {
        this.contactForm.get('sales_executive_name')?.setErrors(null)
      }
    })
    if (this.isBrowser) {
      if (window.screen.width < 480) {
        // 768px portrait
        this.menuVisible = false;
      } else {
        this.menuVisible = true;
        this.submenuVisible = true;
      }
    }
  }

  setDelay() {
    if (this.isBrowser) {
      if (localStorage.getItem("popState") != "hide") {
        setTimeout(() => {
          this.div1 = true;
        }, 45000);
      }
    }
  }

  showNavigationMenu(): void {
    if (this.isBrowser) {
      if (window.screen.width < 480) {
        // 768px portrait
        this.menuVisible = true;
      } else {
        this.menuVisible = true;
      }
    }
  }

  menuVisibilityIndex = 0

  state: any;
  city: any;
  hideNavigationMenu(type?: string, data?: any): void {
    this.city = ""
    this.state = ""
    if (this.isBrowser) {
      if (window.screen.width < 768) {
        // 768px portrait
        if (type === "location") this.menuVisible = false;
      } else {
        this.menuVisible = true;
        if (type === "state") {
          this.state = data;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = "reload";
          this.router.navigate(["/property/state", this.state.permalink]);
          // event.preventDefault(); // Prevent default behavior
        } 
      }
    }

    if (type === "location") {
      this.city = data;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = "reload";
      this.router.navigate(["/property/location", this.city.permalink]);
    }

    const canonicalUrl = document.location.origin + document.location.pathname;
    const datas = {
      event: "location_view",
      canonicalUrl: canonicalUrl,
      state: this.state,
      city: this.city,
    };

    // Push the data to the dataLayer
    this.dataLayerService.pushToDataLayer(datas);
  }

  showNavigationMenusub(): void {
    if (this.isBrowser) {
      if (window.screen.width < 480) {
        // 768px portrait
        this.submenuVisible = !this.submenuVisible;
      } else {
      }
    }
    const canonicalUrl = document.location.origin + document.location.pathname;
    const datas = {
      event: "location_view",
      canonicalUrl: canonicalUrl,
      state: this.state,
      city: this.city,
    };

    // Push the data to the dataLayer
    this.dataLayerService.pushToDataLayer(datas);
  }
  ngOnInit(): void {
    if (this.isBrowser) {
      this.setDelay();
      if (localStorage.getItem("token")) {
        this.ifnotlogin = false;
        this.iflogin = true;
      }

      this.headerservice.getLocationads().subscribe((response: any) => {
        this.headerData = response["data"];
      });

      let id = localStorage.getItem("token");

      if (localStorage.getItem("token")) {
        this.loginservice.getProfile(id).subscribe((response: any) => {
          this.datas = response["data"]["name"];
        });
      }
    }
  }

  get f() {
    return this.contactForm.controls;
  }

  sendContact() {
    if (this.isBrowser) {
      this.submitted = true;
      var me = this;
      if (this.contactForm.valid) {
        me.showError = false;
        const formData = { ...this.contactForm.value };

        // Format check-in and check-out dates
        formData.checkin = formatDate(formData.checkin, 'yyyy-MM-dd', 'en-US');
        formData.checkout = formatDate(formData.checkout, 'yyyy-MM-dd', 'en-US');

        formData.customer_message += ` ${formData.location}`;
        delete formData.location;
        this.bookingservice.contactForm(formData).subscribe((data:any) => {
          if(data.success){
            const canonicalUrl =
              document.location.origin + document.location.pathname;
            // this.addCanonicalLink(canonicalUrl);
            // this.updateMetaTags(this.meta_title, this.meta_desc);
            const data = {
              event: "popupFormData",
              canonicalUrl: canonicalUrl,
              popup_form: this.contactForm.value,
            };
    
            // Push the data to the dataLayer
            this.dataLayerService.pushToDataLayer(data);
    
            me.hideForm = false;
            me.showStatus = true;
            this.div1 = false;
            localStorage.setItem("popState", "hide");
            // }, 5000);
            setTimeout(() => {
              // window.location.reload();
            }, 5000);
            this.toastr.success('successfully submitted!')
          }
        },(error) => {
          // Handle errors here
          console.error('Error occurred:', error);
          this.div1 = false;
          this.setDelay()
          // You can also show a user-friendly error message here if needed
        });
      } else {
        me.showError = true;
      }
    }
  }

  pop_bg_main_hide() {
    this.div1 = false;
    if (this.isBrowser) {
      localStorage.setItem("popState", "hide");
    }
  }

  hide_top_bar() {
    this.divhidetop = false;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(["/login"]);
    this.ifnotlogin = true;
    this.iflogin = false;
  }

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  };

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  };

  dataUrl: any;
  ngOnChanges() {
    if (this.isBrowser) {
      const storedItem = localStorage.getItem("ekoElement");

      // Check if the item exists in localStorage
      if (storedItem) {
        // Parse the stored item using JSON.parse
        this.ekoElementData = JSON.parse(storedItem);

        // Now you can use the parsedItem in your component
      }

      this.dataUrl = localStorage.getItem("dataUrl");

      if (this.urlData === "/gold") {
        this.isGold = true;
        this.isLuxe = false;
        this.isFeatured = false;
        this.ekoElement = false;
      } else if (this.urlData === "/luxe") {
        this.isLuxe = true;
        this.isGold = false;
        this.isFeatured = false;
        this.ekoElement = false;
      } else if (this.urlData === "/featured-property") {
        this.isFeatured = true;
        this.isGold = false;
        this.isLuxe = false;
        this.ekoElement = false;
      } else if (this.urlData === "/" + this.dataUrl) {
        this.ekoElement = true;
        this.isFeatured = false;
        this.isGold = false;
        this.isLuxe = false;
      }
    }
  }

  telephoneClick() {
    const canonicalUrl = document.location.origin + document.location.pathname;
    const data = {
      event: "telephone_click",
      canonicalUrl: canonicalUrl,
      phoneClick: "click",
    };

    // Push the data to the dataLayer
    this.dataLayerService.pushToDataLayer(data);
  }

  mailClick() {
    const canonicalUrl = document.location.origin + document.location.pathname;
    const data = {
      event: "email_click",
      canonicalUrl: canonicalUrl,
      emailClick: "click",
    };

    // Push the data to the dataLayer
    this.dataLayerService.pushToDataLayer(data);
  }

  isLocationValid(locationName: string): boolean {
    // Logic to determine if the location is valid
    // For example, excluding certain locations:
    return !(
      locationName === "Anjuna" ||
      locationName === "Rest of North Goa" ||
      locationName === "Candolim" ||
      locationName === "Arambol" ||
      locationName === "South Goa"
    );
  }

  handleClick(headerdatastate: any) {
    // Detect double click by checking if the click occurred twice within a short time interval
    const doubleClickInterval = 300; // milliseconds
    const currentTimestamp = new Date().getTime();

    if (
      this.elementRef.nativeElement.lastClickTime &&
      currentTimestamp - this.elementRef.nativeElement.lastClickTime <
        doubleClickInterval
    ) {
      // Handle double click action here
      this.navigateLink(headerdatastate);
    } else {
      // Record timestamp of single click for comparison
      this.elementRef.nativeElement.lastClickTime = currentTimestamp;
    }
  }

  navigateLink(headerdatastate: any) {
    const url = `/property/state/${headerdatastate.permalink}`;
    window.open(url, "_blank");
    this.menuVisible = false;
  }

  navigateByLocation(headerdatamain: any) {
    const url = `/property/location/${headerdatamain.Permalink}`;
    // window.open(url, "_blank");
    window.location.href = url;
  }
  showLuxeMenu:boolean=false
  showAboutMenu:boolean=false
  showLuxe(){
    this.showAboutMenu = false
    this.showLuxeMenu = !this.showLuxeMenu
  }
  showAboutUs(){
    this.showLuxeMenu = false;
    this.showAboutMenu = !this.showAboutMenu
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
