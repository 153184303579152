import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Title,Meta, MetaDefinition } from '@angular/platform-browser';
import * as AOS  from 'aos';
@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {
  isBrowser:boolean

  constructor(private metaService: Meta,private title: Title,  @Inject(PLATFORM_ID) platformId: Object) { 
    this.isBrowser = isPlatformBrowser(platformId);
    this.title.setTitle('Ekostay - Faq');
     this.metaService.addTag({ name: 'description', content: 'Ekostay - Faq' });

  }

  ngOnInit(): void {
if(this.isBrowser){

  AOS.init();
  window.addEventListener('load', AOS.refresh)
  
}
  }

}
