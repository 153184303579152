import { NgIf, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Title,Meta } from '@angular/platform-browser';
import * as AOS  from 'aos';
import { aboutservice } from 'src/app/services/aboutservice';
@Component({
  selector: 'app-ourteam',
  templateUrl: './ourteam.component.html',
  styleUrls: ['./ourteam.component.css']
})
export class OurteamComponent implements OnInit {

  isBrowser:boolean;
  teamDetail:any

  constructor(private metaService: Meta,private title: Title, public dialog: MatDialog,  @Inject(PLATFORM_ID) platformId: Object, public aboutService: aboutservice) { 

    this.isBrowser = isPlatformBrowser(platformId);
    this.title.setTitle('Ekostay - Team');
     this.metaService.addTag({ name: 'description', content: 'Ekostay - Team' });

  }

  ngOnInit(): void {
if(this.isBrowser){

  AOS.init();
  window.addEventListener('load', AOS.refresh)
}

this.aboutService.getOurTeamDetails().subscribe((response:any)=>{
  this.teamDetail = response.data
})

  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string, dataNumber:any): void {
    this.dialog.open(ourTeamDialog, {
      width: '850px',
      enterAnimationDuration,
      exitAnimationDuration,
      panelClass: 'custom-dialog-container',
      data:dataNumber
    });
  }
}



@Component({
  selector: 'ourteam',
  templateUrl: 'ourteamDialog.html',
  styleUrls: ['./ourteam.component.css'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatIconModule, NgIf],
})
export class ourTeamDialog {
  number:any
  constructor(public dialogRef: MatDialogRef<ourTeamDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.number = data
  }

  close() {
    this.dialogRef.close();
  }
}
