import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import * as moment from "moment";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-filter-dialog",
  templateUrl: "./filter-dialog.component.html",
  styleUrls: ["./filter-dialog.component.css"],
})
export class FilterDialogComponent implements OnInit {
  @Output() dataEvent = new EventEmitter<any>();
  location_property: any;
  checkin: any;
  checkout: any;
  minDate = new Date();
  guest: any;
  LocationPropertyRes: any;
  guestoptions: string[] = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ];
  constructor(
    private DialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.LocationPropertyRes = data;
  }

  ngOnInit(): void {}

  closeDialog(shareData = false): void {
    this.DialogRef.close(
      shareData
        ? {
            location: this.location_property,
            guest: this.guest,
            checkIn: this.checkin,
            checkOut: this.checkout,
          }
        : undefined
    );
  }

  checkIn(value: any) {
    var date1 = moment(this.checkin).format("YYYY-MM-DD");
    this.checkin = date1;
    this.checkDateValidity();
  }

  checkOut(value: any) {
    var date2 = moment(this.checkout).format("YYYY-MM-DD");
    this.checkout = date2;
  }

  checkDateValidity(): void {
    if (this.checkin && this.checkout) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
    }
  }

  sendDataToParent() {
    this.dataEvent.emit({
      location: this.location_property,
      chekIn: this.checkin,
      checkOut: this.checkout,
      guest: this.guest,
    });
  }
}
