import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-video-dialog',
  template: `<div>
    <div class="close-icon"><mat-icon aria-hidden="false" aria-label="Example close icon" fontIcon="close" (click)="closeDialog()"></mat-icon>
</div>
  <video width="100%" height="50%" controls class="video_heigh">
    <source [src]="data.videoUrl" type="video/mp4" loading="lazy">
    Your browser does not support the video tag.
  </video>
  <div class="video-name" (click)=" navigateClose()">{{data.properyName.property_name}}</div>
</div>`,
styleUrls: ["./about.component.css"],
})
export class VideoDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public router:Router,  public dialogRef: MatDialogRef<VideoDialogComponent>,) {
  }
  navigateClose() {
    this.dialogRef.afterClosed().subscribe(() => {
      this.router.navigateByUrl(
        `property/location/${this.data.properyName.locpermalink}/${this.data.properyName.permalink}`
      );
    });
    this.dialogRef.close(); // Close the dialog
  }  
  closeDialog(){
    this.dialogRef.close(); 
  }
}
