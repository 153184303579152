<section class="partnership_hero">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="content">
          <h1 class="heading" data-aos="fade-up" data-aos-duration="1000">
            Do you own a beautiful home located a few hours from a major city in
            India?
          </h1>
          <p class="sub_heading" data-aos="fade-up" data-aos-duration="1000">
            You can now turn your property into a serious revenue generator at
            the hands of EKO STAY.
          </p>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
        <span class="partnership_sumit" (click)="openContactUsDialog()"
          >Contact us</span
        >
      </div>
    </div>
  </div>
</section>

<div class="counter_heading" data-aos="fade-up" data-aos-duration="1000">
  <h2>Benefits of Partnering with Ekostay</h2>
</div>
<section class="Eko_counter">
  <div class="container">
    <div class="row" #myDOMElement>
      <div
        class="col-lg-4 col-md-4 col-sm-6 col-xs-6"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div class="counter_item">
          <div class="counter">
            <span>{{ user }}</span
            >M+
          </div>
          <p class="for">Website USER</p>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-4 col-sm-6 col-xs-6"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div class="counter_item">
          <div class="counter">
            <span>{{ customer }}</span
            >K+
          </div>
          <p class="for">HAPPY CUSTOMERS</p>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-4 col-sm-6 col-xs-6"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div class="counter_item">
          <div class="counter">
            <span>{{ locationCounter }}</span
            >+
          </div>
          <p class="for">LOCATIONS</p>
        </div>
      </div>

      <!-- <div
        class="col-lg-3 col-md-3 col-sm-6 col-xs-6"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div class="counter_item">
          <div class="counter">
            <span>{{ exp }}</span
            >K+
          </div>
          <p class="for">Experiences</p>
        </div>
      </div>

      <div
        class="col-lg-3 col-md-3 col-sm-6 col-xs-6"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div class="counter_item">
          <div class="counter">
            <span>{{ states }}</span
            >K+
          </div>
          <p class="for">STATES</p>
        </div>
      </div> -->
    </div>
  </div>
</section>

<section class="partner_benefits">
  <div class="container">
    <div class="card_wapper">
      <div class="heading">
        <h2 class="title" data-aos="fade-up" data-aos-duration="1000">
          Benefits of Partnering with <b>EKOSTAY</b>
        </h2>
        <p class="subtitle" data-aos="fade-up" data-aos-duration="1000">
          We at EKOSTAY take pride in professionally managing properties as
          vacation homes and remove the owner’s hassle associated with hosting
          and client servicing.
        </p>
      </div>

      <div class="row row_vt">
        <div
          class="col-lg-4 col-md-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div class="card">
            <figure class="card__thumb">
              <img
                src="../../../assets/img/c1.jpg"
                alt="Picture by David Monje"
                class="card__image"
              />
              <figcaption class="card__caption">
                <h3 class="numberic">01</h3>
                <h2 class="card__title">Hassle Free Maintenance</h2>
                <p class="card__snippet">
                  We know how difficult it is to maintain homes especially in
                  your absence. You can now sit back & relax whileo ur team is
                  ensuring hassle free maintenance of your property.
                </p>
              </figcaption>
            </figure>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div class="card">
            <figure class="card__thumb">
              <img
                src="../../../assets/img/c2.jpg"
                alt="Picture by David Monje"
                class="card__image"
              />
              <figcaption class="card__caption">
                <h3 class="numberic">02</h3>
                <h2 class="card__title">Serious Revenue Generator</h2>
                <p class="card__snippet">
                  Turning your property into a vacation home can not only cover
                  maintenance & other fixed costs but also provide an
                  alternative source of income.
                </p>
              </figcaption>
            </figure>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div class="card">
            <figure class="card__thumb">
              <img
                src="../../../assets/img/c3.jpg"
                alt="Picture by David Monje"
                class="card__image"
              />
              <figcaption class="card__caption">
                <h3 class="numberic">03</h3>
                <h2 class="card__title">Limited Inventory</h2>
                <p class="card__snippet">
                  Unlike others, we believe in giving in our heart and soul on
                  the properties we work with. We partner with limited homes in
                  order to provide a more personalised & customised branding
                  approach, just the way your property deserves.
                </p>
              </figcaption>
            </figure>
          </div>
        </div>

        <div
          class="col-lg-4 col-md-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div class="card">
            <figure class="card__thumb">
              <img
                src="../../../assets/img/c3.jpg"
                alt="Picture by David Monje"
                class="card__image"
              />
              <figcaption class="card__caption">
                <h3 class="numberic">04</h3>
                <h2 class="card__title">Personal Touch</h2>
                <p class="card__snippet">
                  Both homeowners and clients savour the personal touch provided
                  by the EKO STAY team & its founders. We believe on relations
                  more than anything else.
                </p>
              </figcaption>
            </figure>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div class="card">
            <figure class="card__thumb">
              <img
                src="../../../assets/img/c4.jpg"
                alt="Picture by David Monje"
                class="card__image"
              />
              <figcaption class="card__caption">
                <h3 class="numberic">05</h3>
                <h2 class="card__title">Flexible Revenue Models</h2>
                <p class="card__snippet">
                  We have different revenue models for every homeowner, thereby
                  ensuring a win-win situation for both the parties.
                </p>
              </figcaption>
            </figure>
          </div>
        </div>
        <div
          class="col-lg-4 col-md-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div class="card">
            <figure class="card__thumb">
              <img
                src="../../../assets/img/c5.jpg"
                alt="Picture by David Monje"
                class="card__image"
              />
              <figcaption class="card__caption">
                <h3 class="numberic">06</h3>
                <h2 class="card__title">Total Transparency</h2>
                <p class="card__snippet">
                  You shall have real time updates about all upcoming bookings,
                  payouts and upgrades at your property.
                </p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
