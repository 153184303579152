import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiteDataHandlerService {  
  
  private checkin = new BehaviorSubject('');
  checkIn = this.checkin.asObservable();

  private checkout = new BehaviorSubject('');
  checkOut= this.checkout.asObservable();

 
  
  private location = new BehaviorSubject('');
  locationDa = this.location.asObservable();
 
  isBrowser:boolean
  constructor(@Inject(PLATFORM_ID) private platformId: Object) { 
    this.isBrowser = isPlatformBrowser(platformId);
  }
  updateCheckIn(data:any){
    this.checkin.next(data);
    if(this.isBrowser){
          
      sessionStorage.setItem("check-in",data);
    }
  }
  updateCheckOut(data:any){
    this.checkout.next(data);
    if(this.isBrowser){
          
      sessionStorage.setItem("check-out",data);
    }
  } 
  updateLocation(data:any){
    this.location.next(data);
    if(this.isBrowser){
          
      sessionStorage.setItem("location",data);
    }
  } 
 
  
}
