<span class="back_button"
><i class="fa fa-times" aria-hidden="true" (click)="closeDialog()"></i
></span> 

<div class="col-md-12 card">
    <h1>Membership Details</h1>
    <div class="col-md-12 phone-header">   
      <div>
        <!-- Membership  -->
        status :
      </div>
      
      <div class="name-user">Active</div>
    </div>        
    <div class="col-md-12 phone-header">   
      
      <div>
        <!-- Membership  -->
       Membership Id :
      </div>
      
      <div class="name-user">{{membershipData.membership_id}}</div>
      
      
    </div>
    <div class="col-md-12 phone-header">   
  
      <div>
        <!-- Membership  -->
        Type :
      </div>
      
      <div class="name-user"><span *ngIf="membershipData.membership_type === 1">Orignal</span><span *ngIf="membershipData.membership_type === 2">Plus</span><span *ngIf="membershipData.membership_type === 3">Max</span></div>
      
      
    </div>
    <div class="col-md-12 phone-header">   
  
      <div>
        <!-- Membership  -->
        Start date :
      </div>
      
      <div class="name-user">{{membershipData.membership_date}}</div>
      
      
    </div>
    <div class="col-md-12 phone-header">   
  
      <div>
        <!-- Membership  -->
        Expire date :
      </div>
      
      <div class="name-user">{{membershipData.membership_expiry_date}}</div>
      
      
    </div>
    <div class="col-md-12 phone-header">   
  
        <div>
          <!-- Member  -->
          Name :
        </div>
        
        <div class="name-user">{{membershipData.name | titlecase}}</div>
        
        
      </div>
      <div class="col-md-12 phone-header">   
  
        <div>
          <!-- Member  -->
          PhoneNo. :
        </div>
        
        <div class="name-user">{{membershipData.phone}}</div>
        
        
      </div>
      <div class="col-md-12 phone-header">   
  
        <div>
          <!-- Member  -->
          Email :
        </div>
        
        <div class="name-user">{{membershipData.email}}</div>
        
        
      </div>
      <div>
      <div class="col-md-12 phone-header">   
  
        <div>
            <span *ngIf="membershipData.nominee_name">Nominee Name :</span> <span *ngIf="membershipData.holder_name">Holder Name</span>
        </div>
        
        <div class="name-user">{{membershipData.nominee_name || membershipData.holder_name}}</div>
        
        
      </div>
      <div class="col-md-12 phone-header">   
  
        <div>
           <span *ngIf="membershipData.nominee_email">Nominee Email :</span> <span *ngIf="membershipData.holder_email">Holder Email</span>
        </div>
        
        <div class="name-user">{{membershipData.nominee_email || membershipData.holder_email}}</div>
        
        
      </div>
      <div class="col-md-12 phone-header">   
  
        <div>
            <span *ngIf="membershipData.nominee_phone">Nominee Phone :</span> <span *ngIf="membershipData.holder_phone">Holder Phone</span>
        </div>
        
        <div class="name-user">{{membershipData.nominee_phone || membershipData.holder_phone}}</div>
        
        
      </div>
    </div>
  
  </div>