
   <div class="location-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
           <ul>
               <li *ngFor="let location of locationData">
                    <a routerLink="/property/state/{{location.name}}">
                   <div class="location-box">
                       <img src="assets/img/location-shape.png" alt="" class="location-shape">
                       <img src="{{imageSrc}}/{{location.image}}" alt="" class="location-image">
                   </div>
                   <h6 class="loc-title">{{location.name}}</h6>
                   </a>
               </li>
               
           </ul>

       		
        </div>	 
      </div>
   
		<app-homeads></app-homeads>    
      
    </div>
  </div> 