import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { HomeComponent } from "./modules/home/home/home.component";
import { AboutUsComponent } from "./components/about-us/about-us.component";
import { ContactComponent } from "./components/contact/contact.component";
import { BlogComponent } from "./components/blog/blog.component";
import { PartnerWithUsComponent } from "./components/partner-with-us/partner-with-us.component";
import { FaqsComponent } from "./components/faqs/faqs.component";
import { OriginalComponent } from "./components/original/original.component";
import { GoldComponent } from "./components/gold/gold.component";
import { LuxeComponent } from "./components/luxe/luxe.component";
import { PropertylistComponent } from "./components/propertylist/propertylist.component";
import { MembershipComponent } from "./components/membership/membership.component";
import { OurteamComponent } from "./components/ourteam/ourteam.component";
import { CareersComponent } from "./components/careers/careers.component";
import { PrivacypolicyComponent } from "./components/privacypolicy/privacypolicy.component";
import { TermsComponent } from "./components/terms/terms.component";
import { ThankYouComponent } from "./components/thank-you/thank-you.component";
import { LoginComponent } from "./components/login/login.component";
import { AccountComponent } from "./components/account/account.component";
import { AuthGuard } from "./guards/auth.guard";
import { PagenotfoundComponent } from "./components/pagenotfound/pagenotfound.component";

const routes: Routes = [
  {
    path: "",
    // component: HomeComponent,
    loadChildren: ()=> import("./modules/home/home.module").then((m)=> m.HomeModule)
  },
  {
    path: "about-us",
    component: AboutUsComponent,
  },
  {
    path: "contact",
    component: ContactComponent,
  },
  {
    path: "blog",
    component: BlogComponent,
  },
  {
    path: "partner-with-us",
    component: PartnerWithUsComponent,
  },
  {
    path: "faqs",
    component: FaqsComponent,
  },
  {
    path: "propertylist",
    component: PropertylistComponent,
  },
  // {
  // 	path: 'original',
  // 	component: OriginalComponent
  // },
  {
    path: "gold",
    component: GoldComponent,
  },
  {
    path: "luxe",
    component: LuxeComponent,
  },
  {
    path: "membership",
    component: MembershipComponent,
  },
  {
    path: "featured-property",
    // component: FeaturedPropertyVillaComponent,
    loadChildren: ()=> import("./modules/collection/collection.module").then((m)=> m.CollectionModule)
  },
  {
    path: "ourteam",
    component: OurteamComponent,
  },
  {
    path: "careers",
    component: CareersComponent,
  },
  {
    path: "privacypolicy",
    component: PrivacypolicyComponent,
  },
  {
    path: "terms",
    component: TermsComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "account",
    component: AccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "property/:typename/:locationname/:id/checkout",
    // component: CheckoutComponent,
    loadChildren: ()=> import("./modules/checkout/checkout.module").then((m)=> m.CheckoutModule)
  },
  {
    path: "property/:typename/:locationname/:id/thanks",
    component: ThankYouComponent,
  },
  {
    path: "property/:typename/:locationname/:id",
    // component: LandingPageComponent,
    loadChildren: ()=> import("./modules/landing/landing.module").then((m)=> m.LandingModule)
  },
  {
    path: "property/:typename/:locationname",
    // component: AboutComponent,
    loadChildren: ()=> import("./modules/about/about.module").then((m)=> m.AboutModule)
  },
  {
    path: "property/:name",
    // component: EkoElementComponent,
    loadChildren: ()=> import("./modules/ekoelement/ekoelement.module").then((m)=> m.EkoelementModule)
  },
  {
    path: "thank-you",
    component: ThankYouComponent,
  },
  { path: "**", pathMatch: "full", component: PagenotfoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    //   initialNavigation: "enabledBlocking",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
