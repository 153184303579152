<span class="back_button"
  ><i class="fa fa-arrow-left" aria-hidden="true" (click)="closeDialog()"></i
></span> 

<form #form="ngForm">
  <h3 class="update_detail">Update Your Details</h3>
  <div class="form-group"> 
    <input type="text" class="form-control" id="fname" placeholder="First Name" name="fname" [(ngModel)]="fname">
  </div>

  <div class="form-group date-range">
    <input matInput [matDatepicker]="picker" placeholder="Date of Birth" class="form-control" name="dob" [max]="maxDate" [(ngModel)]="dob">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </div>
  
  <div class="form-group">
    <input type="email" class="form-control" id="email" name="email" placeholder="Email" [(ngModel)]="email">
  </div>

  <div class="form-group">
    <input type="tel" class="form-control" id="phone" name="phone" placeholder="Phone" [(ngModel)]="phone" readonly>
  </div>
      
  <div mat-dialog-actions>
    <!-- Add your action buttons here -->
    <!-- <button mat-button (click)="onNoClick()">No Thanks</button> -->
    <button mat-button type="submit" (click)="updateProfile()" >Update</button>
  </div>									
</form>


  <!-- <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">No Thanks</button>
    <button mat-button cdkFocusInitial>Ok</button>
  </div> -->