import { Component, OnInit } from '@angular/core';
import { testimonialservice } from '../../services/testimonialservice';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {
	imageSrc = 'https://'+environment.domain+'/public/uploads/images/';
	destroy$: Subject<any> = new Subject();
	testimonialData: any = [];
	celebrityData: any = [];
	constructor(public testimonialservice: testimonialservice) {


		testimonialservice.getCelebrity().subscribe((response:any) =>
		{
			this.celebrityData = response['data'];
		});
	}
	
	customOptions: OwlOptions = {
		loop: true,
		mouseDrag: true,
		touchDrag: true,
		autoplay: false,
		autoplayHoverPause: true,
		autoplaySpeed: 3000,
		pullDrag: true,
		dots: false,
		navSpeed: 700,
		navText: ['Previous', 'Next'],
		responsive: {
		
			0:{
				items:1
			},
			600:{
				items:1
			},
			1000:{
				items:3
			}
		},
		nav: true
	}
	
		customOptionscel: OwlOptions = {
		autoplay:true,
        autoplayTimeout:5000,
		loop: true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		navSpeed: 700,
		navText: ['‹', '›'],
		responsive: {
			0:{
				items:1
			},
			600:{
				items:1
			},
			1000:{
				items:4
			}
		},
		nav: true
	}
	
	ngOnInit(): void {
	}

}
